import React, { Component } from 'react';
import { connect } from 'react-redux';
import Album from '../components/Track/Album';
import { fetchSongsByGenre } from '../actions/album';


class GenreSongs extends Component {
    constructor(props){
        super(props);
        this.state = {
            genre: this.props.match.params.genre
        }
    }
    componentWillMount(){
        this.props.fetchSongsByGenre(this.state.genre);
    }

    render() {
        if(this.props.albumsLoading){
            return (
                    <div className="loader">
                        <div className="loading">Loading&#8230;</div>
                    </div>
                );
        }
        return (            
            <div id="content" className="flex">
                <div className="page-container">
                    <div className="padding sr" id="list" data-plugin="musicapp" data-page="50">
                        <div className="heading py-2 d-flex ">
                            <div>
                                <div className="text-muted text-sm sr-item">{ this.state.genre }</div>
                                <h5 className="text-highlight sr-item">Songs</h5>
                            </div>
                            <span className="flex"></span>
                        </div>
                        <div className="pos-rlt z-index-1">
                            <div className="d-flex py-2 sr-item">
                                
                            </div>
                        </div>
                        <div className="row sr-item">
                            {
                                
                               this.props.albums.map(album => {
                                    return <Album album={album} key={album.isrc}/>
                                })
                            }
                        </div>
                        <div className="pagination pagination-sm mt-3 hide"></div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        albums: state.album.genreSongs,
        albumsLoading: state.album.albumsLoading
    }
}

export default connect(mapStateToProps,{ fetchSongsByGenre })(GenreSongs)
