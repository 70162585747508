import React from 'react';  
import { Route, Redirect } from 'react-router-dom';
import '../../index.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import WalletHeader from '../../components/Header/WalletHeader';

const AuthLayoutRoute = ({component: Component, ...rest}) => {
  const authenticated = useSelector(state => state.auth.authenticated );
  return (  
    <Route {...rest} render={matchProps => (  
      authenticated
      ? (
      <div className="App layout-column wallet" style={{ background: '#f9f6f6'}}>
        <WalletHeader />
        <div id="main" className="layout-row flex">
    	     <Component {...matchProps} />
    	     <ToastContainer 
    	     	position="top-right"
      			autoClose={5000}
      			hideProgressBar={false}
      			newestOnTop={false}
      			closeOnClick
      			rtl={false}
      			pauseOnVisibilityChange
      			draggable
      			pauseOnHover
    	     />
        </div>
      </div> )
      : <Redirect to='/login?auth=required' />
    )}/>  
  )  
};

export default AuthLayoutRoute;