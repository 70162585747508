import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

const sliderSettings = {
    slidesToShow: 5,
    slidesToScroll: 2,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    dots: false,
    rtl: false,
    arrows: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 5
            }
        },
        {
            breakpoint: 920,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 3
            }
        }
    ]
};

const style = {
    visibility: 'visible',
    transform: 'none',
    opacity: 1,
    transition: 'none 0 s ease 0 s'
};

const TopArtists = (props) => {
    return (
            <Slider {...sliderSettings} className="slick slick-visible slick-arrow-top row media-circle" style={style}>
                { props.artists.map((artist) => {
                    return (<div className="col-12" key={artist.Album.Artist.generated_id} data-id={ artist.Album.Artist.generated_id } data-category="all" data-tag={artist.Album.Artist.artist_name}>
                                <div className="list-item r">
                                    <div className="media ">
                                        {
                                           artist.Album.Artist && artist.Album.Artist.artist_image !== '' && artist.Album.Artist.artist_image !== null? 
                                            (<Link to={`/artist/${artist.Album.Artist.generated_id}`} className="media-content " style={{backgroundImage: 'url('+artist.Album.Artist.artist_image+')'}}>
                                            </Link>)
                                           : (<Link to={`/artist/${artist.Album.Artist.generated_id}`} className="media-content " style={{backgroundImage: 'url('+artist.Album.artwork_file_path+')'}}>
                                            </Link>)
                                        }
                                        
                                        <div className="media-action">
                                        </div>
                                    </div>
                                    <div className="list-content text-center">
                                        <div className="list-body ">
                                            <Link to={`/artist/${artist.Album.Artist.generated_id}`} className="list-subtitle d-block text-muted  subtitle h-1x">
                                                { artist.Album.Artist.artist_name }
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                })}
            </Slider>
    )
}

export default TopArtists;