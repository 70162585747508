import React, {useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { User } from 'react-feather';

const Artist = (props) => {

    const [src, setImageSRC] = useState(null);

    useEffect(() => {
        const src = props.artist.artist_image && props.artist.artist_image !== ""? props.artist.artist_image: props.artist.Albums[0].artwork_file_path;

        const imageLoader = new Image();
        imageLoader.src = src;

        imageLoader.onload = () => {
            setImageSRC(src);
        }
    });

    return (
        <div className="col-4 col-sm-3 col-md-3 col-lg-3 col-xl-2" data-id={ props.artist.generated_id } data-category="all" data-tag={props.artist.country}>
            <div className="list-item r">
                <div className="media ">
                    <Link to={`/artist/${props.artist.generated_id}`} className="media-content " style={{backgroundImage: 'url('+src+')'}}>
                        {
                            !src? (<User size={45} className="text-white"/>): ('')
                        }
                    </Link>
                    
                    <div className="media-action">
                    </div>
                </div>
                <div className="list-content text-center">
                    <div className="list-body ">
                        <Link to={`/artist/${ (props.artist.generated_id)}`} className="list-subtitle d-block text-muted  subtitle h-1x">
                            { props.artist.artist_name }
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Artist;