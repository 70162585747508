import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import pluralize from 'pluralize';

class Stream extends Component {
	constructor(props){
		super(props);
		this.state = {
			count: 0
		}
	}
	componentDidMount(){
		// console.log(this.props.id);
		if(this.props.type === 'album'){
			axios.post('/api/albums/getstreamcount',{
				id: this.props.id
			})
			.then(response => {
				this.setState({
					count: response.data.data
				});
			})
			.catch((error) => {
				toast.error(error);
			});
		}
		if(this.props.type === 'single'){
			axios.post('/api/single/getstreamcount',{
				id: this.props.id
			})
			.then(response => {
				this.setState({
					count: response.data.data
				});
			})
			.catch((error) => {
				toast.error(error);
			});
		}

		if(this.props.type === 'playlist'){
			axios.post('/api/playlists/getstreamcount',{
				id: this.props.id
			})
			.then(response => {
				this.setState({
					count: response.data.data
				});
			})
			.catch((error) => {
				toast.error(error);
			});
		}
		
	}

	componentDidUpdate(prevProps){
		// console.log(this.props.id);
		// console.log(prevProps.id);
		if(this.props.id !== prevProps.id){
			if(this.props.type === 'album'){
				axios.post('/api/albums/getstreamcount',{
					id: this.props.id
				})
				.then(response => {
					this.setState({
						count: response.data.data
					});
				})
				.catch((error) => {
					toast.error(error);
				});
			}else{
				axios.post('/api/playlists/getstreamcount',{
					id: this.props.id
				})
				.then(response => {
					this.setState({
						count: response.data.data
					});
				})
				.catch((error) => {
					toast.error(error);
				});
			}
		}
	}

	render() {
		return (
                <span className="text-muted">{this.state.count} { pluralize('stream',this.state.count) }</span>
		);
	}
}

export default Stream;
