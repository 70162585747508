import React from 'react';

const TrackDuration = ({ track, isAlbum }) => {
    let duration = track.Album?.duration || track.duration;
    let hours, minutes, seconds = '';
    //process this very well PT4M12S
    hours = duration.includes('H')? duration.match('\T(.*?)\H')[1]: ''
    minutes = duration.includes('H')? duration.match('\H(.*?)\M')[1]: duration.match('\T(.*?)\M')[1];
    seconds = duration.includes('M')? duration.match('\M(.*?)\S')[1] : duration.match('\T(.*?)\S')[1];

    return (
        <div className="px-3 text-sm d-none d-md-block">
        { 
            track.Album !== undefined && !isAlbum? 
            
            `${hours !== ''? hours+':': ''}${minutes.length === 1? '0':''}${minutes}:${seconds.length === 1? '0':''}${minutes}`
            :!isAlbum?
            `${hours !== ''? hours+':': ''}${minutes.length === 1? '0':''}${minutes}:${seconds.length === 1? '0':''}${seconds}`:('')
        } 
        </div>   
    )
}

export default TrackDuration;