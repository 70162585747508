import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Image from '../assets/images/loginImage.jpeg';
import logo from '../assets/images/meplaylist_color_white_text.fw.png';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sendResetCode } from '../actions/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import axios from 'axios';


class Forgot extends Component {
	constructor(props){
		super(props);
		this.state = {
			email: '',
            password: '',
            loading: false,
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}
    

	handleSubmit(e){
        e.preventDefault();
        this.setState({
            loading: true
        })
        axios.post('/api/auth/checkemail',{
            email: this.state.email
        })
        .then(async response => {
            this.setState({
                loading: false
            });
            if(response.data.data.isExists){
                await this.props.sendResetCode(this.state.email);
                this.props.history.push('/resetpassword');
			    toast.success('Reset code sent to your email address!');
                // this.setState({
                //     email: ''
                // });            
            }else{                
                toast.error(<span className="h5">Email does not exists in our records!</span>);
            }
        })
        .catch(error => {
            console.log(error);
        });
	}

	render() {
		return (
			<div className="row no-gutters vh-100">
                <div className="col-md-6" style={{ backgroundImage: 'url('+Image+')',backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="p-3 p-md-5 d-flex flex-column h-100">
                        {/* <h4 className="mb-3 text-white font-weight-bolder">MePlaylist</h4> */}
                        <Link to="/" className="navbar-brand">
                            <img src={logo} alt="logo"/>
                        </Link>                       
                        <div className="d-flex flex align-items-center justify-content-center">
                        </div>
                        <div className="d-flex text-sm text-fade">
                            <a href="https://www.meplaylist.com" className="text-white font-weight-bolder h5" target="_blank">About</a>
                            <span className="flex"></span>
                            <a href="https://www.meplaylist.com/legal" className="text-white mx-1 font-weight-bolder h5" target="_blank" rel="noopener noreferrer">Terms</a>
                            <a href="https://www.meplaylist.com/privacy-policy" className="text-white mx-1 font-weight-bolder h5" target="_blank" rel="noopener noreferrer">Policy</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div id="content-body">
                        <div className="p-3 p-md-5 mx-md-5 text-center text-md-left">
                            <h3 className="pb-3 font-weight-bolder text-dark" style={{fontSize: '50px'}}>Forgot Password?
                            </h3>
                            <p class="h6 text-secondary">
                                Provide your email address, we would send you a reset code
                            </p>
                            <div className="mb-3">
                            	{/* <SocialLogin /> */}
                            </div>
                            <hr/>
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <input type="email" className="form-control border-0 login-input" placeholder="Email" value={this.state.email} onChange={(e) => this.setState({email: e.target.value}) } required/>
                                </div>
                                
                                <button type="submit" className="btn btn-dark mb-4 px-3 py-2 btn-block submit mt-3">
                                {
                                    this.props.loading || this.state.loading? 
                                    (<FontAwesomeIcon icon={faSpinner} size="lg" spin/>):(<span className="h5 mb-0 py-2" style={{color: '#FFF'}}>Submit</span>)
                                }
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

Forgot.propTypes = {
	handleLogin: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading
    }
}

export default withRouter(connect(mapStateToProps,{ sendResetCode })(Forgot));
