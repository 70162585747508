import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserCurrentSubscription } from '../../actions/wallet';

class Banner extends Component {
	componentDidMount(){
		if(this.props.authenticated){
			this.props.getUserCurrentSubscription();
		}
	}

	render() {
		if((this.props.authenticated && this.props.subscriptionDetail.subscription_plan ==='free') || !this.props.authenticated){
			return (
				<div className="text-center google__banner">
					<ins className="adsbygoogle"
						style={{ display: 'block', textAlign: 'center'}}
						data-ad-client="ca-pub-3944298880368135"
						data-ad-slot="4390910929"
						data-ad-format="fluid"
						data-full-width-responsive="true"></ins>
					{/* <a href="https://play.google.com/store/apps/details?id=app.meplaylist.com.meplay" target="_blank">
		            	<img src={Image} className="bannerAd" alt="Banner Ad"/>
		            </a>		             */}
		        </div>
			);
		}else{
			return ('')
		}
		
	}
}

const mapStateToProps = (state) => {
	return {
		subscriptionDetail: state.wallet.subscription,
		authenticated: state.auth.authenticated
	}
}
export default connect(mapStateToProps,{ getUserCurrentSubscription })(Banner);
