import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import Playlist from './SinglePlaylist';

const sliderSettings = {
    slidesToShow: 8,
    slidesToScroll: 2,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    dots: false,
    rtl: false,
    arrows: true,
    centerMode: false,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 6
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 920,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2.2,
                slidesToScroll: 1,
                speed: 300
            }
        }
    ]
};

const style = {
    visibility: 'visible',
    transform: 'none',
    opacity: 1,
    transition: 'none 0 s ease 0 s',
};


const TopPlaylist = (props) => {
    if(props.type && props.type === 'mood'){
        return (
            <Slider {...sliderSettings} className="slick slick-visible slick-arrow-top col pl-0 sr-item mb-3 left-align-slick" style={style}>
            { 
                props.playlists.filter(playlist => playlist.PlaylistSongs.length > 0).map((playlist) => {
                return (
                    <div key={playlist.generated_id} className="col-12" data-id={ playlist.generated_id } data-category={'playlist'} data-tag={ playlist.country }>
                        <div className="list-item slick-item r mb-3">
                            <div className="media ">
                                <Link to={`/playlist/${playlist.generated_id}`}                    
                                    className="media-content" style={{ backgroundImage: "url(" + playlist.PlaylistSongs[0].Album.artwork_file_path + ")" }}>
                                </Link>
                                
                            </div>
                            <div className="list-content text-center">
                                <div className="list-body ">
                                    <Link to={`/playlist/${(playlist.generated_id)}`} className="list-title title h-1x">
                                       <b className="text-white"> { playlist.title } </b>
                                    </Link>                     
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </Slider>
    
        )
    }
    return (
        <Slider {...sliderSettings} className="slick slick-visible slick-arrow-top col pl-0 sr-item mb-3 left-align-slick" style={style}>
            { 
                props.playlists.filter((playlist) => {
                    // filter out null playlist songs
                    return (playlist.artwork_path && playlist.artwork_path !=="") || (playlist.Playlist !== undefined && playlist.Playlist.PlaylistSongs !== undefined && playlist.Playlist.PlaylistSongs.length >0 && playlist.Playlist.PlaylistSongs[0].Album !== null) || (playlist.Playlist && playlist.Playlist.artwork_path !=="")
                }).map((playlist) => {
                return (
                    <Playlist playlist={playlist} />
                )
            })}
            {/* 
                <div className="col-12">
                        <div className="list-item slick-item r mb-3">
                            <div className="media ">
                                <Link to={`/playlists`} className="media-content text-light font-weight-bold">
                                    VIEW ALL
                                </Link>                                
                            </div>
                            <div className="list-content text-center">
                                <div className="list-body ">
                                </div>
                            </div>
                        </div>
                    </div> */}
        </Slider>
    );
}

export default TopPlaylist;
