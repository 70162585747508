import React, { Component } from 'react';
import '../Track/CurrentTrack';
import CurrentTrack from '../Track/CurrentTrack';
import { connect } from 'react-redux';
import Banner from '../../components/Banner';
import { messaging } from '../../init-fcm';
import axios from 'axios';
import { saveNotificationToken } from '../../actions/auth';

class Footer extends Component {
    async componentDidMount(){
        // check if playcount is available in local storage -- if not initialize it
        if(!localStorage.getItem('playCount')){
            localStorage.setItem('playCount',0);
        }
    }
    
    render() {
        return (
            <div id="footer" className="page-footer sticky sticky-bottom" data-work={this.props.authenticated?btoa('authenticated'):btoa('notauthenticated')} data-type={ btoa(this.props.subscription.subscription_plan)} data-gdpr={this.props.gdpr}>
                <Banner />
                <div data-plugin="plyr" className="plyrist plyrist_audio plyr-list-popup bg-white b-t" id="plyrist">
                    <audio controls hidden></audio>
                    <div className="plyr-list">
                        { this.props.queueTracks.map((track) => {
                            return <CurrentTrack key={track.id} track={track} />
                        })}
                        {/*
                        <div className="plyr-item" data-id="234782921" data-type="audio" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/Music69/v4/02/4c/98/024c9802-ce83-e0b4-3cd3-11c5c6284cdb/mzaf_9006921880389738307.plus.aac.p.m4a" data-poster="../assets/img/c2.jpg">
                            <div className="plyr-item-poster"></div>
                            <div className="flex">
                                <div className="plyr-item-title h-1x">Lean On</div>
                                <div className="plyr-item-author text-sm text-fade">Major Lazer Feat. MØ & DJ Snake</div>
                            </div>
                            <button className="plyr-item-close close text">&times;</button>
                        </div>  
                        */}                     
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    queueTracks: state.queue.queue,
    authenticated: state.auth.authenticated,
    subscription: state.wallet.subscription,
    gdpr: state.user.gdpr,
    user: state.auth.user
});

export default connect(mapStateToProps,{ saveNotificationToken })(Footer);
