import React, { Component, useState, useEffect } from 'react';
import Single from '../components/Track/Single';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { search } from '../actions';
import {User as Usser, List, Music, ChevronRight } from 'react-feather';

const Artist = (props)=>{
    const [src, setImageSRC] = useState(null);

    useEffect(() => {
        const src = props.artist.artist_image;

        const imageLoader = new Image();
        imageLoader.src = src;

        imageLoader.onload = () => {
            setImageSRC(src);
        }
    });

    return (
        <div className="col-4 col-sm-3 col-md-3 col-lg-3 col-xl-2" data-id={ props.artist.generated_id } data-category="all" data-tag={props.artist.country}>
            <div className="list-item r">
                <div className="media">
                    <Link to={`/artist/${props.artist.generated_id}`} className="media-content " style={{backgroundImage: 'url('+src+')'}}>
                        {
                            !src? (<Usser size={45} className="text-white"/>): ('')
                        }
                    </Link>
                </div>
                <div className="list-content text-center">
                    <div className="list-body ">
                        <Link to={`/artist/${ (props.artist.generated_id)}`} className="list-subtitle d-block text-muted  subtitle h-1x">
                            { props.artist.artist_name }
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Album = (props)=>{
    const [src, setImageSRC] = useState(null);

    useEffect(() => {
        const src = props.album.artwork_file_path;

        const imageLoader = new Image();
        imageLoader.src = src;

        imageLoader.onload = () => {
            setImageSRC(src);
        }
    });

    return (
        <div className="col-md-3 col-xl-2 col-sm-4 col-4" data-id={ props.album.id } data-category={ props.album.genre } data-tag={ props.album.territory_code }>
            <div className="slick-item r mb-3">
                <div className="media">
                    <Link to={`/album/${props.album.album_id}`} className="media-content " style={{backgroundImage: 'url('+src+')'}}>
                        {
                            !src? (<Music size={45} className="text-white"/>): ('')
                        }
                    </Link>
                </div>
                <div className="list-content text-center">
                    <div className="list-body ">
                        <Link to={`/album/${props.album.album_id }`} className="list-title title ajax h-1x" data-location={`/album/${props.album.album_id}`} title={props.album.release_title}>
                            { props.album.release_title }
                        </Link>
                        <Link to={props.album.Artist? `/artist/${ props.album.Artist.generated_id }`: '#'} className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                            { props.album.display_artist_name }
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Playlist = (props)=>{
    const [src, setImageSRC] = useState(null);

    useEffect(() => {
        const src = props.playlist !== undefined && props.playlist.artwork_path && props.playlist.artwork_path !== "" ?
            props.playlist.artwork_path
            :(props.playlist !== undefined && !props.playlist.artwork_path && props.playlist.artwork_path === "" && props.playlist.PlaylistSongs !== undefined && props.playlist.PlaylistSongs.length >0 ?
            props.playlist.PlaylistSongs[0].Album.artwork_file_path
            :props.playlist.artwork_path)

        const imageLoader = new Image();
        imageLoader.src = src;

        imageLoader.onload = () => {
            setImageSRC(src);
        }
    });

    return(
        <div className="col-md-3 col-xl-2 col-sm-4 col-4" data-id={ props.playlist.Playlist !== undefined? props.playlist.Playlist.generated_id :props.playlist.generated_id } data-category="Pop" data-tag={ props.playlist.Playlist !== undefined? props.playlist.Playlist.country :props.playlist.country }>
            <div className="list-item slick-item r mb-3">
                <div className="media">
                    <Link to={props.playlist.Playlist !== undefined?`/playlist/${(props.playlist.Playlist.generated_id)}`:`/playlist/${(props.playlist.generated_id)}`} className="media-content " style={{ backgroundImage: "url(" + src + ")" }}>
                        {
                            !src?(<List size={45} className="text-white"/>): ('')
                        }
                    </Link>         
                </div>
                <div className="list-content text-center">
                    <div className="list-body ">
                        {/* <a href="music.detail.html#92570808" className="list-title title ajax h-1x"> */}
                            <Link to={props.playlist.Playlist !== undefined?`/playlist/${(props.playlist.Playlist.generated_id)}`:`/playlist/${(props.playlist.generated_id)}`} className="list-title title h-1x">
                                <b className="text-white"> { props.playlist.Playlist !== undefined?props.playlist.Playlist.title:props.playlist.title } </b>
                            </Link>
                        {/* </a> */}
                                    
                    </div>
                </div>
            </div>
        </div>
    )
}

const User = (props)=>{
    const [src, setImageSRC] = useState(null);

    useEffect(() => {
        const src = props.user.avatar;

        const imageLoader = new Image();
        imageLoader.src = src;

        imageLoader.onload = () => {
            setImageSRC(src);
        }
    });

    return (
            <div className="col-4 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                <div className="list-item r">
                    <div className="media">
                        <Link to={`/user/${props.user.generated_id}`} className="media-content " style={{backgroundImage: 'url('+src+')'}}>
                            {
                                !src? (<Usser size={45} className="text-white"/>): ('')
                            }
                        </Link>
                    </div>
                    <div className="list-content text-center">
                        <div className="list-body ">
                            <Link to={`/user/${ (props.user.generated_id)}`} className="list-subtitle d-block text-muted  subtitle h-1x">
                                { props.user.username !== '' && props.user.username !== null ? props.user.username : `User-${props.user.generated_id}` }
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
    
    )
}

class SearchView extends Component {
    componentWillMount(){
        this.props.search(this.props.match.params.query)
    }

    componentDidUpdate(prevProps){
        if(this.props.location !== prevProps.location){
            this.props.search(this.props.match.params.query)
        }
    }

    render() {
        if(this.props.loading){
            return (
                <div id="content" className="flex ">
                    <div className="page-container">
                        <div className="bg-light">
                            <div className="page-container padding">
                                <div className="loader">
                                    <div className="loading">Loading&#8230;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if(this.props.isEmpty){
            return (
                <div id="content" className="flex ">
                    <div className="page-container">
                        <div className="bg-light">
                            <div className="page-container padding">
                                <div className="flex text-center container justify-content-center align-items-center my-5">
                                    <img src="https://res.cloudinary.com/dycyotaya/image/upload/c_fit,h_500,q_auto:low/v1601369948/nosearchresult_n4ggyq.png" alt="no search result found"/>
                                    {/* <Search size={300} className="text-white"/> */}
                                    <h1 className="text-white font-weight-bold">No search result found</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div id="content" className="flex ">
                <div className="page-container">
                    <div className="padding sr" id="list">
                        {/* getting singles */}
                        {
                             this.props.tracks.length > 0 ?
                             (<div className="row list-row list-index my-5">
                                <div className="col-12 heading py-2 d-flex">
                                    <div>
                                        <h3 className="text-highlight sr-item font-weight-bold">Tracks <ChevronRight strokeWidth={3}/> </h3>
                                    </div>
                                    <span className="flex"></span>
                                </div>
                                    
                                {
                                    this.props.tracks.map((track) => {
                                        return <Single track={track} key={track.release_type} tracks={this.props.tracks}/>
                                    })
                                }
                            </div>)
                            : ('')
                        }

                         {/* getting artists */}
                         {
                                this.props.artists.length > 0?
                                (<div className="row list media-circle my-5">
                                    <div className="col-12 heading py-2 d-flex ">
                                        <div>
                                            <h3 className="text-highlight sr-item font-weight-bold">Artists <ChevronRight strokeWidth={3}/></h3>
                                        </div>
                                        <span className="flex"></span>
                                    </div>
                                    {
                                        this.props.artists.map(artist => {
                                            return (<Artist artist={artist} key={artist.generated_id}/>)
                                        })
                                    }
                                </div>)
                                : ('')
                        }
    

                        {/* getting albums */}
                        {
                            this.props.albums.length > 0 ?
                                (<div className="row list-row my-5">
                                    <div className="col-12 heading py-2 d-flex">
                                        <div>
                                            <h3 className="text-highlight sr-item font-weight-bold">Albums <ChevronRight strokeWidth={3}/></h3>
                                        </div>
                                        <span className="flex"></span>
                                    </div>
                                        
                                    {
                                        this.props.albums.map((album) => {
                                            return <Album album={album} key={album.isrc}/>
                                        })
                                    }
                                </div>)
                                : ('')
                        }
        
                        {/* getting playlist  */}
                        {
                                this.props.playlists.length > 0?
                                (<div className="row sr-item my-5">
                                    
                                    <div className="col-12 heading py-2 d-flex ">
                                        <div>
                                            <h3 className="text-highlight sr-item font-weight-bold">Playlist <ChevronRight strokeWidth={3}/></h3>
                                        </div>
                                        <span className="flex"></span>
                                    </div>
                                        
                                    {
                                        this.props.playlists.map((playlist) => {
                                            return <Playlist playlist={playlist} key={playlist.generated_id}/>
                                        })
                                    }
                                </div>)
                                : ('')
                        }

                        {/* getting users 2*/}
                        {
                                this.props.users.length > 0?           
                                <div className="row list media-circle my-5" key={this.props.users.generated_id} data-id={ this.props.users.generated_id } data-category="all">                            
                                <div className="col-12 heading py-2 d-flex ">
                                    <div>
                                        <h3 className="text-highlight sr-item font-weight-bold">Users <ChevronRight strokeWidth={3}/></h3>
                                    </div>
                                    <span className="flex"></span>
                                </div>
                                
                                {
                                    this.props.users.map((user) => {
                                        return <User user={user} key={user.generated_id}/>
                                    })
                                }
                                </div>
                                : ('')
                        }
                    </div>
                </div>
            </div>
        
        )
    }
}

SearchView.propTypes = {
    search: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
    return {
        loading: state.search.loading,
        albums:state.search.albums,
        artists: state.search.artists,
        playlists:state.search.playlists,
        users:state.search.users,
        tracks: state.search.tracks,
        isEmpty:state.search.isEmpty
    }
}

export default connect(mapStateToProps,{ search })(SearchView);