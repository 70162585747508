import React, { Component } from 'react';
import postOne  from '../../assets/images/postOne.png';
import postTwo from '../../assets/images/postTwo.png';
import userOne from '../../assets/images/userOne.png';
import userTwo from '../../assets/images/userTwo.png';
import './Feeds.css';
import {Link} from 'react-router-dom';


class Feeds extends Component {
    render() {
        return (         
            <div className="feed-container">    
                <div className="feeds-box">
                    <div className="header">
                        <h2>Feeds</h2>
                    </div>
                    <div className="feeds-post">
                        <div className="feeds-img">
                            <img src={ postOne } alt="post-1" />
                        </div>
                        <div className="feeds-info">
                            <h3 className="feeds-title">Asa Live in Lagos Concert</h3>
                            <p>Tuesday, 2nd, September, 2020 &nbsp; • &nbsp; 7pm-3am</p>
                            <h4>
                                The Asa Live in Lagos concerts will be taking place at the Eko convention centre, Lagos. 
                                She will be gracing her fans with a beautiful performance.
                            </h4>
                            <Link to="" className="more-info">Read more...</Link>
                        </div>
                    </div>
                    <div className="feeds-post">
                        <div className="feeds-img">
                            <img src={ postTwo } alt="post-2" />
                        </div>
                        <div className="feeds-info">
                            <h3 className="feeds-title">Afro Nation</h3>
                            <p>SATURDAY, 6th, September, 2020 &nbsp; • &nbsp; 10pm-5am</p>
                            <h4>
                                Afro Nation will once again bring its huge lineup of 
                                Afrobeats, hip-hop, dancehall, bashment and R&B to the sunny Portuguese coast.
                            </h4>
                        </div>
                    </div>
                </div>
            
                <div className="notification-container">
                    <div className="header">
                        <h2>Notifications</h2>
                    </div>
                    <div className="notification">
                        <div className="user-image">
                            <img src={ userOne } alt="user" />
                        </div>
                        <div className="notification-info">
                            <h4>@d.okeke started following you.</h4>
                        </div>
                        <div className="cta-btn">
                            <button>Follow</button>
                        </div>
                    </div>
                    <div className="notification">
                        <div className="user-image">
                            <img src={ userOne } alt="user" />
                        </div>
                        <div className="notification-info">
                            <h4>@d.okeke started following you.</h4>
                        </div>
                        <div className="cta-btn">
                            <button>Accept</button>
                        </div>
                    </div>
                    <div className="notification">
                        <div className="user-image">
                            <img src={ userOne } alt="user" />
                        </div>
                        <div className="notification-info">
                            <h4>@d.okeke started following you.</h4>
                        </div>
                        <div className="cta-btn">
                            <button>Decline</button>
                        </div>
                    </div>
                    <div className="notification">
                        <div className="user-image">
                            <img src={ userTwo } alt="user" />
                        </div>
                        <div className="notification-info">
                            <h4>@waleb accepted your follow request</h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Feeds;