import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { updateLike } from '../../actions/user';
import { Heart } from 'react-feather';
import $ from 'jquery';
import Swal from 'sweetalert2'

class LikeBtn extends Component {
	constructor(props){
		super(props);
		this.state = {
			liked: false,
		}
		this.handleLike = this.handleLike.bind(this);
		this.unauthenticatedUser = this.unauthenticatedUser.bind(this);
	}

	componentWillMount(){
		//check if album was previously liked by user
		let isLiked = this.props.likes.filter(like => like.liked_album_fk === this.props.album.id);
		if(isLiked.length >0){
			this.setState({
				liked: true
			});
		}
	}

	handleLike(){
		$('#likeBtn').attr('aria-pressed',true)
		$('#likedBtn').attr('disabled','true');
		this.setState({
			liked: true
		});
		axios.post('/api/album/like',{
	        userID: this.props.user.id,
	        albumID: this.props.album.id
	    })
	    .then(response => {
	       	this.setState({
	       		liked: true
	       	});
	    })
	    .catch(error => {
	    	this.setState({
	    		liked: false
	    	});
	    	//update store (state) of user's likes to add this new liked entity
	    	this.props.updateLike({
	    		liker_user_fk: this.props.user.id,
	    		liked_album_fk: this.props.album.id,
	    		liked_playlist_fk: null
	    	});
	    	toast.error('Network error, try again later!');
	    });
	}
	
	handleUnlike = () => {
		// $('#likeBtn').attr('aria-pressed',true)
		// $('#likedBtn').attr('disabled','true');
		this.setState({
			liked: false
		});
		axios.post('/api/v1/album/unlike',{
	        userID: this.props.user.generated_id,
	        albumID: this.props.album.id
	    })
	    .then(response => {
	       	this.setState({
	       		liked: false
	       	});
	    })
	    .catch(error => {
	    	this.setState({
	    		liked: false
	    	});
	    	//update store (state) of user's likes to add this new liked entity
	    	this.props.updateLike({
	    		liker_user_fk: this.props.user.id,
	    		liked_album_fk: this.props.album.id,
	    		liked_playlist_fk: null
	    	});
	    	toast.error('Network error, try again later!');
	    });
	}

	unauthenticatedUser(){
		Swal.fire({
			title: 'Oops!',
			html: "<h3>You must be Logged In to perform this action!</h3>",
			icon: 'warning',
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Login'
		  }).then((result) => {
			if (result.value) {
			  window.location.replace('/login');
			}
		})
	}

	render() {
		if(this.props.authenticated === false){
			return (
				<button className={`btn btn-icon no-bg no-shadow`} data-toggle-class onClick={this.unauthenticatedUser}>
                	<Heart size={16} className=""/>
           		</button>
			);
		}

		if(this.state.liked)
			return (
				<button className={`btn btn-icon no-bg no-shadow btn-like-${this.props.album.isrc}`} data-toggle-class data-liked="true" onClick={this.handleUnlike}>
					<Heart size={16} className="icon--pressed"/>
           		</button>
           	);


		return (
			<button className={`btn btn-icon no-bg no-shadow btn-like-${this.props.album.isrc}`} data-toggle-class onClick={this.handleLike} data-liked="false" data-id={ this.props.album.id }>			
                <Heart size={16} className=""/>
           	</button>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		likes: state.auth.likes,
		authenticated: state.auth.authenticated
	}
}
export default connect(mapStateToProps,{ updateLike })(LikeBtn);
