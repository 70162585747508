import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUserPlaylists } from '../../actions/playlist';
import AddPlaylist from '../../components/TopPlaylists/AddPlaylist';

class AddToPlaylist extends Component {
	constructor(props){
		super(props);
		this.state = {
			playlist: null
		}
	}
	componentWillMount(){
		//check if user playlists is not loaded yet
		if(this.props.authenticated && this.props.userPlaylists.length ===0){
			this.props.fetchUserPlaylists();
		}
	}

	
	render() {
		return (
				<div id="addToPlaylist" className="modal fade" data-backdrop="true" data-class="modal-open-aside" aria-modal="true">
                    <div className="modal-dialog modal-right w-xl">
                        <div className="modal-content h-100 no-radius"  style={{overflowY:'auto'}}>
                            <div className="modal-header ">
               	                <div className="modal-title text-md text-dark">Choose Playlist</div>
                                <button className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <div className="p-4 row text-center">
                                {
                                	this.props.userPlaylists.map((playlist) => {
                                		return (
                                			<AddPlaylist key={playlist.id} playlist={playlist}/>
                                		)
                                	})
                                }
                                	
                                </div>
                            </div>
                            <div className="modal-footer">
                                {/* <button type="button" className="btn btn-light" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal">Save Changes</button> */}
                            </div>
                        </div>
                    </div>
                </div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userPlaylists: state.playlist.userPlaylists,
        authenticated: state.auth.authenticated
	}
}

export default connect(mapStateToProps,{ fetchUserPlaylists })(AddToPlaylist);