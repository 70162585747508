import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addSongToPlaylist } from '../../actions/playlist';

class AddPlaylist extends Component {
	constructor(props){
		super(props);

		this.addToPlaylist = this.addToPlaylist.bind(this);
	}

	addToPlaylist(){
		this.props.addSongToPlaylist(this.props.addToPlaylistSong,this.props.playlist.id);
	}

	render() {
		return (
			<div key={ this.props.playlist.id } className="col-md-12" data-id={ this.props.playlist.generated_id } data-category="Pop" data-tag={this.props.playlist.country }>
				<div className="list-item slick-item r mb-3">
					<div className="media" style={{ cursor: 'pointer'}} onClick={() => {
						        return this.addToPlaylist();
						  	}}>
						{
						    this.props.playlist.PlaylistSongs && this.props.playlist.PlaylistSongs.length >0 && this.props.playlist.PlaylistSongs[0].Album?
						    (<span className="ajax media-content " style={{ backgroundImage: "url(" +this.props.playlist.PlaylistSongs[0].Album.artwork_file_path + ")" }}>
						    </span>)
						    :(this.props.playlist.artwork_path !==""& this.props.playlist.artwork_path !== null?(<span className="ajax media-content " style={{ backgroundImage: "url(" +this.props.playlist.artwork_path + ")" }}></span>): (<span className="ajax media-content " style={{backgroundImage:"url('http://www.meplaylist.com/assets/landing/images/artist_img3.jpg')"}}></span>))
						}
						                                                             
					</div>
					<div className="list-content text-center">
						<div className="list-body ">
						    <span className="list-title title h-1x">
						        <b className="text-dark font-weight-bold"> {this.props.playlist.title } </b>
						    </span>	                               
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		addToPlaylistSong: state.playlist.addToPlaylistSong,
	}
}

export default connect(mapStateToProps,{ addSongToPlaylist })(AddPlaylist);