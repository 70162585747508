import React from 'react';  
import { Route } from 'react-router-dom';
import '../../index.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
const AuthLayoutRoute = ({component: Component, ...rest}) => {  
  return (  
    <Route {...rest} render={matchProps => (  
      <div className="App layout-row bg-login">
        <div className="d-flex flex-column flex">
  	     <Component {...matchProps} />
  	     <ToastContainer 
  	     	position="top-right"
    		autoClose={5000}
    		hideProgressBar={false}
    		newestOnTop={false}
    		closeOnClick
    		rtl={false}
    		pauseOnVisibilityChange
    		draggable
    		pauseOnHover
  	     />
        </div>
      </div>
    )}/>  
  )  
};

export default AuthLayoutRoute;