import React, { Component } from 'react';
import { Search, Menu } from 'react-feather';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SearchComponent from '../Search/SearchComponent';
import { logout, fetchUserCountry } from '../../actions/auth';
import logo from '../../assets/images/meplaylist_color_white_text.fw.png';
import logoDark from '../../assets/images/meplaylist_color_black_text.png';
import AppleStore from '../../assets/images/apple-store-download.png';
import PlayStore from '../../assets/images/google-play-store-download.png';
import './header.css';

const DownloadApp = () => {
    return (
        <div className="modal fade" id="downloadApp" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 d-flex align-items-center justify-content-center">
                                <a href="https://www.apple.com/app-store/" target="_blank">
                                    <img src={AppleStore} className="download_image apple"/>
                                </a>
                            </div>
                            <div className="col-md-6 d-flex align-items-center justify-content-center">
                                <a href="https://play.google.com/store/apps/details?id=app.meplaylist.com.meplay" target="_blank">
                                    <img src={PlayStore} className="download_image android"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


class Header extends Component {
    constructor(props){
        super(props);
        this.state = {
            query: ''
        }

        this.handleSearch =  this.handleSearch.bind(this);
        this.downloadApp =  this.downloadApp.bind(this);
    }

    handleSearch(e){
        this.setState({query: e.target.value});
    }

    downloadApp(){
        
    }

    componentWillMount(){
        if(this.props.authenticated && Object.keys(this.props.userCountry).length === 0 && this.props.userCountry.constructor === Object){
            this.props.fetchUserCountry(this.props.user.Country.alpha_2);
        }
    }

    render() {
        return (
            <div id="header" className="page-header bg-body">
                <div className="navbar navbar-expand-lg">
                    <Link to="/" className="navbar-brand d-lg-none">
                        {/* <span className="hidden-folded d-inline l-s-n-1x d-lg-none">MePlaylist</span> */}
                        {
                            this.props.userThemeMode === 'dark'?
                            (<img src={logo} alt="logo"/>) : (<img src={logoDark} alt="logo"/>)
                        }
                        
                    </Link>
                    <div className="collapse navbar-collapse order-2 order-lg-1" id="navbarToggler">
                        <SearchComponent/>
                    </div>
                    <ul className="nav navbar-menu order-1 order-lg-2">
                        { /*<li className="nav-item d-none d-sm-block">
                            <Link className="nav-link px-2" data-toggle="fullscreen" data-plugin="fullscreen">
                                <Maximize size={16} />
                            </Link>
                        </li> */ }
                        <li className="nav-item d-sm-block">
                            <button   onClick={() => this.downloadApp } target="_blank" className="font-weight-bolder btn btn-outline-primary" data-toggle="modal" data-target="#downloadApp">
                                {/* <Download size={20} /> */}
                                Get App
                            </button>
                        </li>
                        {
                            this.props.authenticated
                            ?
                            (
                               <li className="nav-item dropdown">
                                    <Link to="#" data-toggle="dropdown" className="nav-link d-flex align-items-center px-2 text-color">
                                        <span className="dropdown-toggle  mx-2 d-none l-h-1x d-lg-block text-right">{ this.props.user.username }</span>
                                        <span className="avatar w-24" style={{margin: '-2px'}}><img src={ this.props.user.avatar !== '' && !this.props.user.avatar.includes('https://meplaylist.s3.amazonaws.com/profilepics/?')?this.props.user.avatar:'http://developer.meplaylist.com/assets/landing/images/favicon.ico' } alt="..." height="25"/></span>
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-right w mt-3 animate fadeIn">
                                        <Link className="dropdown-item" to={`/user/${this.props.user.generated_id}`}>
                                            <span>Profile</span>
                                        </Link>
                                        <Link to="/settings" className="dropdown-item">
                                            <span>Account Settings</span>
                                        </Link>
                                        <Link to="/wallet" className="dropdown-item d-flex" target="_blank" rel="noopener noreferrer">
                                            <span className="flex">Wallet</span>
                                        </Link>
                                        <Link to="#" className="dropdown-item d-flex text-danger" onClick={this.props.logout}>
                                            <span className="flex">Sign out</span>
                                        </Link>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="https://meplaylist.freshdesk.com/support/solutions" target="_blank" rel="noopener noreferrer">Help</a>
                                        <Link className="dropdown-item" to="/plans">Plan</Link>
                                        <a className="dropdown-item" href="https://www.meplaylist.com" target="_blank" rel="noopener noreferrer">About</a> 
                                        <a className="dropdown-item" href="https://www.meplaylist.com/legal" target="_blank" rel="noopener noreferrer">Legal</a> 
                                    </div>
                                </li> 
                            )
                            :
                            (
                                <li className="nav-item d-sm-block">
                                    <Link to="/login" className="btn btn-raised btn-primary text-white mx-md-3 mx-1 px-md-3 font-weight-bolder">Login</Link>
                                </li>
                            )

                        }
                        
                        <li className="nav-item d-lg-none">
                            <Link to="#" className="nav-link px-2" data-toggle="collapse" data-toggle-class data-target="#navbarToggler">
                                <Search size={16} />
                            </Link>
                        </li>
                        <li className="nav-item d-lg-none">
                            <Link to="#" className="nav-link px-1" data-toggle="modal" data-target="#aside">
                                <i data-feather="menu"></i>
                                <Menu size={16} />
                            </Link>
                        </li>
                    </ul>
                </div>
                <DownloadApp/>
            </div>
        );
    }
}

const mapStateToProps = (state)=>{
    return {
        authenticated: state.auth.authenticated,
        user: state.auth.user,
        userCountry: state.auth.userCountry,
        userThemeMode: state.user.userThemeMode
    } 
}

export default withRouter(connect(mapStateToProps,{ logout, fetchUserCountry })(Header));