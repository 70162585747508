import React, { Component } from 'react';
import { Check } from 'react-feather';
import { connect } from 'react-redux';
import { follow, unfollow } from '../../actions/artist';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { checkIfUserFollowsArtist } from '../../actions/artist';

class FollowBtn extends Component {
	constructor(props){
		super(props);

		this.state = {
			following: false,
			followingText : "Following"
		}

		this.followArtist = this.followArtist.bind(this);
		this.unfollowArtist = this.unfollowArtist.bind(this);
		this.unauthenticatedUser = this.unauthenticatedUser.bind(this);
	}

	componentDidMount(){
		this.props.checkIfUserFollowsArtist(this.props.artistID, this.props.user.id);
	}

	followArtist(){
		this.setState({
			following: true,
		});
		this.props.follow(this.props.user.id,this.props.artistID);
	}

	unfollowArtist(){
		this.setState({
			following: false
		});
		this.props.unfollow(this.props.user.id,this.props.artistID);
	}
	unfollowText = (followingText)=>{
		this.setState({followingText})
	}

	unfolowPopUpBox=()=>{
		Swal.fire({
			html: `<h3>Are you sure you want to unfollow ${this.props.artist.artist_name} ? </h3>`,
			icon: 'warning',
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Unfollow',
			showCancelButton: true
		  }).then((result) => {
			if (result.value) {
				this.unfollowArtist()
			}
		})
	}

	unauthenticatedUser(){
		Swal.fire({
			title: 'Oops!',
			html: "<h3>You must be Logged In to perform this action!</h3>",
			icon: 'warning',
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Login'
		  }).then((result) => {
			if (result.value) {
			  window.location.replace('/login');
			}
		})
	}
	render() {
		if(this.props.authenticated === false){
			return (
				<button className="btn btn-raised btn-primary" onClick={this.unauthenticatedUser}>Follow</button>
			);
		}

		return (
			<div>
				{
					// this.state.following || this.props.followers.filter((follower) => this.props.user.id === follower.following_user_fk ).length > 0?(<button className="btn btn-raised btn-rounded btn-primary btn-following" onClick={this.unfolowPopUpBox} onMouseOver={this.unfollowText}> Following <Check size={18}/></button>):
					
					this.state.following || this.props.isFollowing?
					(<button className="btn btn-raised btn-rounded btn-primary" id="unfollow-btn" 
						onClick={this.unfolowPopUpBox} 
						onMouseOver={()=>{this.unfollowText("Unfollow")}} 
						onMouseLeave={()=>{this.unfollowText("Following")}}> 
						{
							
							this.state.followingText
						
						} 
						<Check size={18}/>
					</button>):
					(<button className="btn btn-raised btn-primary" onClick={this.followArtist}>Follow </button>)
				}
			</div>
		);
	}
}

FollowBtn.propTypes = {
	user: PropTypes.object.isRequired,
	followers: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => {
	return {
		followers: state.artist.followers,
		user: state.auth.user,
		artist: state.artist.artist,
		authenticated: state.auth.authenticated,
		isFollowing: state.artist.isFollowing,
	}
}
export default connect(mapStateToProps,{ follow, unfollow, checkIfUserFollowsArtist })(FollowBtn);