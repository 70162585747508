import * as actionTypes from '../actions/types';


const initialState = {
	user: {},
	following: [],
	followers: [],
	playlists: [],
	loading: true,
	suggestedUsers: [],
	updating:false,
	favourites: [],
	location: localStorage.getItem('location')?localStorage.getItem('location'): null,
	userThemeMode: localStorage.getItem('theme')?localStorage.getItem('theme'): 'dark',
	gdpr: false
}


export default function(state = initialState,action){
	switch(action.type){
		case actionTypes.FETCH_USER: 
			return {
				...state,
				user: action.payload,
				following: action.payload.Following.filter(following => following.Followed !==null),
				followers: action.payload.Followed.filter(follower => follower.Following !==null),
				playlists: action.payload.Playlists,
				loading: false
			}
		case actionTypes.FETCHING_USER:
			return {
				...state,
				loading: true
			}
		case actionTypes.FETCH_SUGGESTED_USERS:
			return {
				...state,
				suggestedUsers: action.payload
			}
		case actionTypes.FOLLOW_USER:
			let updatedFollowers = state.followers.concat([action.payload]);
			return {
				...state,
				followers: updatedFollowers
			}
		case actionTypes.UNFOLLOW_USER:
			let newFollowers = state.followers.filter(follower => follower.Following !==null)
			let newFollowing = state.following.filter(following => following.Followed !==null)
			return {
				...state,
				followers: newFollowers,
				following: newFollowing
			}
		case actionTypes.UPDATE_USER_INFO:
			return {
				...state,
				user: action.payload,
				updating: false
			}
		case actionTypes.UPDATING_USER_INFO:
			return {
				...state,
				updating: true
			}
		case actionTypes.FETCH_USER_FAVOURITES:
			return {
				...state,
				favourites: action.payload
			}
		case actionTypes.FETCH_LOCATION_FROM_IP_ADDRESS:
			return {
				...state,
				location: action.payload.country
			}
		case actionTypes.UPDATE_USER_THEME_MODE:
			return {
				...state,
				userThemeMode: action.payload
			}
		case actionTypes.UPDATE_GDPR:
			return {
				...state,
				gdpr: action.payload
			}
		default:
			return state;
	}
}