import React from 'react';
import { Heart, Headphones } from 'react-feather';
import { Link } from 'react-router-dom';
import { cipher } from '../../utils/util';
import PlayerActions from '../Player/PlayerActions';
import LikeBtn from '../Album/LikeBtn';
import { useDispatch } from 'react-redux';
import { UPDATE_QUEUE } from '../../actions/types';
import TrackDuration from './TrackDuration';

export default function Single(props) {
    const encrypt = cipher('78sji3w9-2djadw2');
    const dispatch =  useDispatch();

    return (
       <div className="col-12" data-id={ props.track.isrc} data-category={props.track.genre} data-tag="" data-source={ encrypt(props.track.audio_file_path_192) }>
            <div className="list-item r">
                <div className="media ">
                    <Link to={`/single/${ props.track.id }`} className="media-content text-light">
                        <Headphones size={24} />
                    </Link>
                    <div className="media-action media-action-overlay">
                        <button className="btn btn-icon no-bg no-shadow hide-row" data-toggle-class>
                        <Heart size={16} className="active-danger"/>
                        </button>
                        <button className="btn btn-raised btn-icon btn-rounded bg--white btn-play" onClick={() => dispatch({ type: UPDATE_QUEUE, payload: props.tracks})}></button>
                    </div>
                </div>
                <div className="list-content text-center">
                    <div className="list-body ">
                        <Link to={`/single/${ props.track.id }`} className="list-title title ajax h-1x" data-location={ `/single/${props.track.id}` }>
                            { props.track.resource_title} 
                            {
                                props.track.release_type === 'TrackRelease'? (<span class="badge badge-light ml-1" title="Track Release">TR</span>): ('')
                            }
                        </Link>
                        <span className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                            { props.track.parental_warning_type === 'Explicit'?
                                (
                                    <span className="badge badge-light text-dark mr-3">E</span>
                                ):''
                            }
                            <Link to={props.track.Artist? `/artist/${ props.track.Artist.generated_id }`: '#'} className="list-title title ajax h-1x">
                                { props.track.display_artist_name }
                            </Link>
                        </span>
                    </div>
                </div>
                <div className="list-action  show-row">
                    <div className="d-flex align-items-center">
                        <TrackDuration track={props.track} />
                        <LikeBtn album={props.track}/>
                        <PlayerActions songID={ props.track.id }/>
                    </div>
                </div>
            </div>
        </div>                                        
    )
}
