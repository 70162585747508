import { 
    FETCH_ARTISTS, 
    FETCH_ARTIST, 
    FOLLOW_ARTIST, 
    UNFOLLOW_ARTIST,
    FETCH_TOP_ARTISTS,
    FETCHING_ARTIST,
    FETCH_SIMILAR_ARTISTS,
    FETCH_ARTIST_TOP_TRACKS,
    FETCH_ARTIST_PLAYLISTS,
    FETCH_ARTIST_FOLLOWERS,
    FETCH_ARTIST_USER_IS_FOLLOWING
} from './types';
import axios from 'axios';
import { toast } from 'react-toastify';
import store from '../store';

const user = store.getState().auth.user;
const subscription = store.getState().wallet.subscription;

export const fetchArtists = () => dispatch => {
    // make axios call 
    axios.get('/api/artists')
    .then(response => {
        dispatch({
            type: FETCH_ARTISTS,
            payload: response.data.data
        })
    })
    .catch(error => {
        // console.log(error);
        toast.error(error);
    })
}

export const fetchArtist = (id, artist_name) => dispatch => {
    dispatch({
        type: FETCHING_ARTIST
    })
    axios.post('/api/artist',{
        artistID: id,
        artist_name: artist_name,
        territory_code:  user?.Country?.alpha_2? user.Country.alpha_2: store.getState().user.location,
        subscription: subscription.subscription_plan
    })
    .then(response => {
        dispatch({
            type: FETCH_ARTIST,
            payload: response.data.data
        });
        // dispatch({
        //     type: UPDATE_QUEUE,
        //     payload: response.data.data.artist.Albums
        // });
    })
    .catch(error => {
        toast.error('Network error, please try again!');
    })
}

export const follow = (userID, artistID) => dispatch => {
    axios.post('/api/artist/follow',{
        artistID: artistID,
        userID: userID
    })
    .then(response => {
        dispatch({
            type: FOLLOW_ARTIST,
            payload: response.data.data
        });
    })
    .catch(error => {
        toast.error('Network error, please try again!');
    })
}

export const unfollow = (userID, artistID) => dispatch => {
    axios.post('/api/artist/unfollow',{
        artistID: artistID,
        userID: userID
    })
    .then(response => {
        dispatch({
            type: UNFOLLOW_ARTIST,
            payload: response.data.data
        });
    })
    .catch(error => {
        toast.error('Network error, please try again!');
    })
}


export const getTopArtists = () => dispatch => {
    axios.get('/api/artist/topartists')
    .then(response => {
        dispatch({
            type: FETCH_TOP_ARTISTS,
            payload: response.data.data
        });
    })
    .catch(error => {
        console.log('Network error, please try again!');
    });
}

export const fetchSimilarArtists = ({sub_genre, artistID, location}) => dispatch => {
    axios.post('/api/similar/artists',{
        sub_genre: sub_genre.length >0 ? sub_genre: ['Afrobeats','Afrobeat','afropop'],
        artistID,
        location: location? location : (user?.Country?.alpha_2? user.Country.alpha_2: store.getState().user.location)
    })
    .then(response => {
        dispatch({
            type: FETCH_SIMILAR_ARTISTS,
            payload: response.data.data
        });
    })
    .catch(error => {
        console.log('Network error, please try again!');
    });
}

export const fetchTopTracks = (artist) => dispatch => {
    axios.post('/api/artist/toptracks',{
        artistID: artist,
        location: user?.Country?.alpha_2? user.Country.alpha_2: store.getState().user.location
    })
    .then(response => {
        dispatch({
            type: FETCH_ARTIST_TOP_TRACKS,
            payload: response.data.data
        });
    })
    .catch(error => {
        console.log('Network error, please try again!');
    });
}

export const fetchArtistPlaylists = (songs) => dispatch => {
    axios.post('/api/v1/artist/playlists',{
        songs,
        location: user?.Country?.alpha_2? user.Country.alpha_2: store.getState().user.location
    })
    .then(response => {
        dispatch({
            type: FETCH_ARTIST_PLAYLISTS,
            payload: response.data.data
        });
    })
    .catch(error => {
        console.log('Network error, please try again!');
    });
}

export const fetchArtistFollowers = (id) => dispatch => {
    axios
    .post('/api/v1/artist/followers',{
        artistID: id
    })
    .then(response => {
        dispatch({
            type: FETCH_ARTIST_FOLLOWERS,
            payload: response.data.data
        });
    })
    .catch(error => {
        console.log('Oops something went wrong!');
    })
}

export const checkIfUserFollowsArtist = (id,userID) => dispatch => {
    axios
    .post('/api/v1/artist/user/following_check',{
        artistID: id,
        userID
    })
    .then(response => {
        dispatch({
            type: FETCH_ARTIST_USER_IS_FOLLOWING,
            payload: response.data.data
        });
    })
    .catch(error => {
        console.log('Oops something went wrong!');
    })
}