import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchArtists } from '../actions/artist';
import Artist from '../components/Artist';

class Artists extends Component {
    componentWillMount(){
        if(this.props.artists.length === 0)
            this.props.fetchArtists();
    }
    render() {
        if(this.props.loading){
            return (
                    <div id="content" className="flex">
                        <div className="page-container">
                            <div className="bg-light">
                                <div className="page-container padding">
                                    <div className="loader">
                                        <div className="loading">Loading&#8230;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    );
        }

        return (
            <div id="content" className="flex ">
                <div className="page-container">
                    <div className="padding sr" id="list" data-plugin="musicapp">
                        <div className="heading py-2 d-flex ">
                            <div>
                                {/* <div className="text-muted text-sm sr-item">Featured</div> */}
                                <h2 className="text-highlight sr-item font-weight-bold pl-1">Artists</h2>
                            </div>
                            <span className="flex"></span>
                        </div>
                        <div className="row list media-circle">
                            {
                                this.props.artists.map(artist => {
                                    return <Artist artist={artist} key={artist.generated_id} />
                                })
                            }
                        </div>
                        <div className="pagination pagination-sm mt-3"></div>
                    </div>
                </div>
            </div>
        )
    }
}

Artists.propTypes = {
    fetchArtists: PropTypes.func.isRequired,
    artists: PropTypes.array.isRequired
}

const mapStateToProps = state => {
    return {
        artists: state.artist.artists,
        loading: state.artist.loading
    }
}
export default connect(mapStateToProps,{ fetchArtists })(Artists);
