import React,{ Component } from 'react'
// import { Genre } from '../components/Genres'
import { Link } from 'react-router-dom';
const genres = [
    {color: 'primary', name: 'Hip Hop'},
    {color: 'info', name: 'Worldwide'},
    {color: 'warning', name: 'Pop'},
    {color: 'success', name: 'Afro'},
    {color: 'danger', name: 'Rock'},
    {color: 'dark', name: 'Workout'},
    {color: 'light', name: 'SoundTrack'},
    {color: 'dark', name: 'Spoken Word'}
];
export const Genre = (props) => {
    return <div className="col-4 pb-5">
                <div className={props.genre !== undefined ? "list-item list-overlay r mb-3 pt-2 gd-"+props.genre.color : ''}>
                    <div className="media media-4x3">
                        <span  className="media-content ">
                        </span>
                        <div className="media-action ">
                        </div>
                    </div>
                    <div className="list-content p-4">
                        <div className="list-body text-center">
                            <Link to={`/albums/genre/${props.genre.name}`} className="list-title title ajax h5 font-weight-bold">
                                {props.genre !== undefined ? props.genre.name : '' }
                            </Link>
                        </div>
                        <div className="list-footer ">
                            {/* <div className="text-muted text-sm">Feb 6, 3:50 am</div> */}
                        </div>
                    </div>
                </div>
            </div>;
}

class Genres extends Component {
    render() {
        return (
             <div id="content" className="flex">
            <div className="page-container">
              <div className="padding sr">
                <div>
                  <h4 className="text-highlight pb-5">Explore Genres</h4>
                </div>
                <div className="row">
                    { genres.map((genre,index) => {
                        return <Genre key={index} genre={genre}/>
                    })}
                </div>
                {/* <Genre/> */}
              </div>
            </div>
          </div>
        ) 
    }
}

export default Genres;
