import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SocialLogin from '../../components/SocialLogin';
import logo from '../../assets/images/meplaylist_color_white_text.fw.png';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { handleLogin, googleAuth, facebookAuth, updateDetails } from '../../actions/auth';
import './login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import axios from 'axios';

const search = window.location.search;
const params = new URLSearchParams(search);

class Login extends Component {
	constructor(props){
		super(props);
		this.state = {
			email: '',
			password: '',
            recaptchaToken: ''
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}

    componentWillMount(){
        if(params.get('token') && params.get('type')==='google' && params.get('session')){
            //user authentication token
			localStorage.setItem('__ekaeae',window.btoa(params.get('session')));
            if(params.get('signup')){
                axios
                .get('https://api.ipify.org?format=json')
                .then(response => {
                    this.props.updateDetails(params.get('token'),'google',response.data.ip);
                })
                .catch(error => {
                    toast.error('An Error Occurred!'+ error);
                });
            }else{
                this.props.googleAuth(params.get('token'));
            }
        }

        if(params.get('token') && params.get('type')==='facebook' && params.get('session')){
            //user authentication token
			localStorage.setItem('__ekaeae',window.btoa(params.get('session')));
            if(params.get('signup')){
                axios
                .get('https://api.ipify.org?format=json')
                .then(response => {
                    this.props.updateDetails(params.get('token'),'facebook',response.data.ip);
                })
                .catch(error => {
                    toast.error('An Error Occurred!'+ error);
                });
            }else{
                this.props.facebookAuth(params.get('token'));
            }            
        }

        if(params.get('auth') && params.get('auth') === 'required'){
            toast.error('You must login to view page');
        }

        if(params.get('auth') && params.get('auth') === 'failed'){
            toast.error('Sign In failed!');
        }
    }
    
    componentDidMount(){
        if(params.get('from') && params.get('from') === 'registration'){
            toast.success('Thank you for registering, you can login with your details');
        }
    }

	handleSubmit(e){
        e.preventDefault();
        this.props.handleLogin(this.state);
	}

	render() {
		return (
			<div className="row no-gutters vh-100 login-page">
                <div className="col-md-6" id="login-img">
                    <div className="p-3 p-md-5 d-flex flex-column h-100">
                        {/* <h4 className="mb-3 text-white font-weight-bolder">MePlaylist</h4> */}
                        <Link to="/" className="navbar-brand">
                            <img src={logo} alt="logo"/>
                        </Link>                       
                        <div className="d-flex flex align-items-center justify-content-center">
                        </div>
                        <div className="d-flex text-sm text-fade">
                            <a href="https://www.meplaylist.com" className="text-white font-weight-bolder h5" target="_blank" rel="noopener noreferrer">About</a>
                            <span className="flex"></span>
                            <a href="https://www.meplaylist.com/legal" className="text-white mx-1 font-weight-bolder h5" target="_blank" rel="noopener noreferrer">Terms</a>
                            <a href="https://www.meplaylist.com/privacy-policy" className="text-white mx-1 font-weight-bolder h5" target="_blank" rel="noopener noreferrer">Policy</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div id="content-body">
                        <div className="p-3 p-md-5 mx-md-5 text-center text-md-left">
                            <h3 className="pb-3 font-weight-bolder text-dark" style={{fontSize: '50px'}}>Login
                            </h3>
                            <p>
                                {/* <small className="text-muted">Login to manage your account</small> */}
                            </p>
                            <div className="mb-3">
                            	<SocialLogin />
                            </div>
                            <hr/>
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <input type="text" className="form-control border-0 login-input" placeholder="Email or Username" value={this.state.email} onChange={(e) => this.setState({email: e.target.value}) } required/>
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control border-0 login-input" placeholder="Password" onChange={(e)=> this.setState({password: e.target.value})}/>
                                </div>
                                {/* <div className="checkbox mb-3">
                                    <label className="ui-check">
                                        <input type="checkbox" required/>
                                        <i></i> I accept meplaylist <a href="https://www.meplaylist.com/legal" target="_blank">Terms & Condition</a> 
                                    </label>
                                </div> */}
                                
                                <button type="submit" className="btn btn-dark mb-4 px-3 py-2 btn-block submit mt-3">
                                {
                                    this.props.loading? 
                                    (<FontAwesomeIcon icon={faSpinner} size="lg" spin/>):(<span className="h5 my-1 font-weight-bold" style={{ color: '#FFF' }}>Login</span>)
                                }
                                </button>
                                <div className="text-center">
                                    <div className="h5 text-center">
                                        <span>Do not have an account?
                                        <Link to="/register" className="text-primary"> Sign up</Link></span>
                                    </div>
                                    <Link to="/forgotpassword" className="text-primary h5">Forgot password?</Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

Login.propTypes = {
	handleLogin: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading
    }
}

export default withRouter(connect(mapStateToProps,{ handleLogin, googleAuth, facebookAuth, updateDetails })(Login));
