import * as action from './types';
import store from '../store';
import axios from 'axios';
import { toast } from 'react-toastify';

const user = store.getState().auth.user;

export const activateFreeTrial = ({amount,id,plan_name}) => dispatch =>{
	dispatch({
		type: action.ACTIVATE_FREE_TRIAL_LOADING
	});
	// make axios call to api to activate free trial
	axios.post('/api/wallet/activatefreetrial',{
		user_fk: user.id,
		amount,
		planID: id
	})
	.then(response => {
		dispatch({
			type: action.ACTIVATE_FREE_TRIAL
		});
		window.location.replace('/wallet');
		toast.success('Free Trial Activated');
	})	
	.catch(error => {
		toast.error(`An Error Occurred ${error}`);
		dispatch({
			type: action.ACTIVATE_FREE_TRIAL_ERROR
		});
	});
}

export const getWalletHistory = () => dispatch => {
	axios.post('/api/wallet/history',{
		userID: user.id
	})
	.then(response => {
		dispatch({
			type: action.FETCH_WALLET_HISTORY,
			payload: response.data.data.history
		});
	})
	.catch(error => {
		toast.error(`An Error Occurred ${error}`);
	});
}

export const getWalletBalance = () => dispatch => {
	axios.post('/api/wallet/balance',{
		userID: user.id
	})
	.then(response => {
		dispatch({
			type: action.FETCH_WALLET,
			payload: response.data.data.wallet
		});
	})
	.catch(error => {
		toast.error(`An Error Occurred ${error}`);
	});
}

export const getUserFreeTrial = () => dispatch => {
	axios.post('/api/wallet/user/freetrial',{
		userID: user.id
	})
	.then(response => {
		dispatch({
			type: action.FETCH_USER_FREE_TRIAL,
			payload: response.data.data
		});
	})
	.catch(error => {
		toast.error(`An Error Occurred ${error}`);
	});
}

export const getUserCurrentSubscription = () => dispatch => {
	axios.post('/api/wallet/user/subscription',{
		detailsID: user.subscription_detail_id
	})
	.then(response => {
		dispatch({
			type: action.FETCH_USER_CURRENT_SUBSCRIPTION,
			payload: response.data.data
		});
	})
	.catch(error => {
		toast.error(`An Error Occurred ${error}`);
	});
}

export const addMoneyToWallet = ({ txRef, amount },type) => async (dispatch) => {
	await axios
		.post('/api/wallet/savePayment',{
			userID: user.id,
			reference: txRef,
			amount: amount
		})
		.then(response => {
			if(response.data.success){
				dispatch({
					type: action.WALLET_PAYMENT_SUCCESS
				});
				if(type === 'wallet')
					window.location.replace('/wallet');
			}

			if(response.data.success === false){
				if(response.data.message === 'Invalid Amount Paid'){
					dispatch({
						type: action.WALLET_PAYMENT_INVALID,
						payload: response.data.data
					});
					toast.error(response.data.message + ' contact our support center');
				}

				if(response.data.message === 'Payment Not successful'){
					dispatch({
						type: action.WALLET_PAYMENT_ERROR,
						payload: response.data.data
					});
					toast.error('We could not verify your payment. Please contact our support, thanks');
				}
			}
		
		})
		.catch(error => {
			toast.error(`An Error Occurred ${error}`);
		});
}

export const fetchPlans = (countryCode) => dispatch => {
	axios
	.get('/api/wallet/plans/'+countryCode)
	.then(response => {
		dispatch({
			type: action.FETCH_PLANS,
			payload: response.data.data.plans
		})
	})
	.catch(error => {
		return toast.error(`An Error Occurred ${error}`);
	})
}

export const subscribe = (planID) => dispatch => {
	toast.success('subscription processing...');
	axios
	.post('/api/wallet/subscribe',{
		userID: user.id,
		planID,
	})
	.then(response => {
		dispatch({
			type: action.SUBSCRIBE,
			payload: response.data.data
		});
		toast.success('Subscription was successful');
		window.location.replace('/wallet?subscription=successful');
	})
	.catch(error => {
		return toast.error(`${error}`);
	})
}
