import React, { Component } from 'react';
import { connect } from 'react-redux';
import Plan from '../components/Plan/Plan';
import { fetchPlans, getWalletBalance } from '../actions/wallet';

class Plans extends Component {
	constructor(props){
		super(props);
		this.state = {
			plans: [],
			amount: 0,
			currency: '',
		}
	}
	componentWillMount(){
		const countryCode = Object.entries(this.props.user).length > 0 && this.props.user.constructor === Object ?this.props.user.Country.alpha_2:this.props.location;
		
		if(this.props.plans.length === 0){
			this.props.fetchPlans(countryCode);
		}
		if(this.props.isAuthenticated){
			this.props.getWalletBalance();
		}
	}

	render() {
		return (
			<div id="content" className="flex ">
                <div>
                    <div className="page-content page-container" id="page-content">
                        <div className="padding">
                            <div className="text-center p-5">
                                <h2 className="text-highlight">Our Plans</h2>
                                <h4>Choose from any of our premium plans</h4>
                            </div>
                            <div className="text-center">
                                <div className="block d-md-inline-flex">
                                {
                                	this.props.plans.map((plan) => {
                                		return <Plan plan={plan} key={plan.id} />
                                	})
                                }
                                </div>
                            </div>
                            <div className="p-5 text-center">
                                Enjoy all the premium benefits on any plan you choose
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.authenticated,
		user: state.auth.user,
		plans: state.wallet.plans,
		location: state.user.location
	}
}
export default connect(mapStateToProps,{ fetchPlans, getWalletBalance })(Plans);
