import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { cipher } from '../../utils/util';
import { connect } from 'react-redux';
import { UPDATE_QUEUE } from '../../actions/types.js';
import PlayerActions from '../Player/PlayerActions';
import LikeBtn from '../Album/LikeBtn';
import { Music } from 'react-feather';
import PlaceholderImage from '../../assets/images/A3.png';

class SingleTrack extends Component {
    state = {
        loaded: false,
        src: null
    }

    componentDidMount(){
        const src = this.props.track.Album !== undefined ? this.props.track.Album.artwork_file_path : this.props.track.artwork_file_path;
        const imageLoader = new Image();
        imageLoader.src = src;

        imageLoader.onload = () => {
            this.setState({
                loaded: true,
                src: src
            });
        }
    }

    render() {
        const encrypt = cipher('78sji3w9-2djadw2');
        return (
            <div className="col-12" data-id={ this.props.track.Album? this.props.track.Album.isrc : this.props.track.isrc } data-category="Pop" data-tag="Canada" data-source={ this.props.track.Album?encrypt(this.props.track.Album.audio_file_path_192):encrypt(this.props.track.audio_file_path_192) }>
                <div className="list-item slick-item r mb-3">
                    <div className="media ">
                       <Link to={this.props.track.Album ?`/single/${ this.props.track.Album.id }`:`/single/${this.props.track.id}`} className="media-content " style={{ backgroundImage: "url(" + this.state.src + ")" }}>
                            {/* {
                               !this.state.loaded?
                                (<Music size={45} className="text-white"/>): ('')
                            } */}
                            {
                               !this.state.loaded?
                                (<img src={PlaceholderImage} alt="placeholder image" style={{width: '90px'}}/>): ('')
                            }
                        </Link>
                        <div className="media-action media-action-overlay">
                            <LikeBtn album={ this.props.track.Album !== undefined ? this.props.track.Album : this.props.track }/>
                            <button className="btn btn-raised btn-icon btn-rounded bg--white btn-play" onClick={() => this.props.updateQueue(this.props.tracks)}></button>
                            <PlayerActions songID={this.props.track.Album !== undefined ? this.props.track.Album.id : this.props.track.id } link={this.props.track.Album !== undefined ?`https://app.meplaylist.com/album/${this.props.track.Album.album_id}`:`https://app.meplaylist.com/album/${this.props.track.album_id}`} song={this.props.track.Album !== undefined ? this.props.track.Album : this.props.track } type="album"/>
                        </div>
                    </div>
                    <div className="list-content text-center">
                        <div className="list-body ">
                            <Link to={this.props.track.Album?`/single/${ this.props.track.Album.id }`:`/single/${ this.props.track.id }`} className="list-title title h-1x" data-location={ this.props.track.Album !== undefined ? `/single/${this.props.track.Album.id}` : `/single/${this.props.track.id}` }>
                               <b className="text-white"> { this.props.track.Album? this.props.track.Album.resource_title : this.props.track.resource_title } </b>
                            </Link>
                            {/* </a> */}
                            <Link to={ this.props.track.Album !== undefined && this.props.track.Album.Artist !== undefined && this.props.track.Album.Artist !== null && this.props.track.Album.Artist.generated_id !== null ? `/artist/${ this.props.track.Album.Artist.generated_id }` :
                                (this.props.track.Artist !== undefined && this.props.track.Artist !== null && this.props.track.Artist.generated_id !== null ? `/artist/${ this.props.track.Artist.generated_id }`:'#')} className="list-subtitle d-block text-muted  subtitle h-1x">
                                { this.props.track.Album ? this.props.track.Album.display_artist_name:this.props.track.display_artist_name }
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    // dispatching plain actions
    updateQueue: () => {
        return dispatch({ type: UPDATE_QUEUE,payload:ownProps.tracks })
    }
  }
}

export default connect(null,mapDispatchToProps)(SingleTrack);