import React, { Component, Fragment, useEffect, useState } from 'react';
import $ from 'jquery';
import { NavLink, Link } from 'react-router-dom';
import CreatePlaylist from '../TopPlaylists/CreatePlaylist';
import pluralize from 'pluralize';
import logo from '../../assets/images/meplaylist_color_white_text.fw.png';
import logoDark from '../../assets/images/meplaylist_color_black_text.png';
import { connect } from 'react-redux';
import { fetchUserPlaylists } from '../../actions/playlist';
import { getUserIp } from '../../actions/user';
import { fetchFollows, fetchLikes } from '../../actions/auth';
import { 
    Disc, 
    User, 
    Plus,
    Home,
    Bell,
    List,
    Heart,
    TrendingUp,
    BarChart2
 } from 'react-feather';
import ThemeMode from '../Util/ThemeMode';
import axios from 'axios';
import moment from 'moment';
import './sidebar.css';

const ArtistDashboard = (props) => {
    const [artist, setArtist] = useState(null);
    //check if check has been run
    const [isChecked, setIsChecked] =  useState(false);

    useEffect(() => {
        if(!isChecked){
            setIsChecked(true);
            //make axios call to get
            axios
            .post('/api/artist/user/verified',{
                id: props.user.id
            })
            .then(response => {
                setArtist(response.data.data);
            });
        }
    });

    if(artist){
        return (
            <li>
                <a href={`https://artist.meplaylist.com/resolve/${artist.generated_id}?session=${btoa('!q@JBU7M&ndU#Mu;'+moment().format())}`} rel="tooltip" title="View your artist dashboard" target="_blank">
                    <span className="nav-icon"> 
                        <BarChart2 size={16}/>
                    </span>
                    <span className="nav-text">My Dashboard</span>
                </a>
            </li>
        )
    }
    return ('');
}

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false
        };
    }
    componentWillMount(){
        if(!this.props.authenticated && !localStorage.getItem('location')){
            this.props.getUserIp();
        }
        if(this.props.authenticated){
            if(this.props.likes.length ===0)
                this.props.fetchLikes(this.props.user.id);
        }
    }
    componentDidMount() {
        $('[rel="tooltip"]').tooltip();
        if(this.props.authenticated){
            this.props.fetchUserPlaylists();
            if(this.props.following.length === 0)
                this.props.fetchFollows(this.props.user.generated_id); //Basically fetchs the user info (followers, following and playlists)
        }
    }

    render () {
        return (
            <div id="aside" className="page-sidenav no-shrink bg-light nav-dropdown fade sticky">
                <div className="sidenav h-100 modal-dialog bg-light">
                    <div className="navbar">
                        <Link to="/" className="navbar-brand mt-3">
                            {
                                this.props.userThemeMode === 'dark'?
                                (<img src={logo} alt={""}/>): (<img src={logoDark} alt={""} />)
                            }
                            
                        </Link>
                    </div>
                    <div className="flex scrollable hover">
                        <div className="nav-active-text-primary" data-nav>
                            <ul className="nav bg">
                                <li className="nav-header hidden-folded pt-4">
                                </li>
                                <li>
                                    <div className="p-3">
                                        <Link to="#" className="btn btn-white btn-block text-align-auto">
                                            <Bell size={16} />
                                            <span className="mx-1 hidden-folded">Feeds</span>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <NavLink to="/" activeClassName="active" exact>
                                        <span className="nav-icon">
                                            <Home size={16}/>
                                        </span>
                                        <span className="nav-text">Home</span>
                                    </NavLink>
                                </li>
                                <li className="">
                                    <NavLink to='/playlists' activeClassName="active">
                                        <span className="nav-icon">
                                            <List size={16}/>
                                        </span>
                                        <span className="nav-text">Playlists</span>
                                    </NavLink>
                                    {/* <ul className="nav-sub">
                                        <li>
                                            <a href="#">
                                                <span className="nav-text">Recently Played</span>
                                            </a>
                                        </li>
                                    </ul> */}
                                </li>  
                                {/* <li>
                                    <Link to="">
                                        <span className="nav-icon">
                                            <PauseCircle size={16}/>
                                        </span>
                                        <span className="nav-text">Recently Played</span>
                                    </Link>
                                </li> */}                            
                                <li>
                                    <NavLink to="/albums" activeClassName="active">
                                        <span className="nav-icon">
                                            <Disc size={16}/>
                                        </span>
                                        <span className="nav-text">Albums</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/artists" activeClassName="active">
                                        <span className="nav-icon">
                                            <User size={16}/>
                                        </span>
                                        <span className="nav-text">Artists</span>
                                    </NavLink>
                                </li>
                                {/* top chart route */}
                                <li>
                                    <NavLink to="/topchart" activeClassName="active">
                                        <span className="nav-icon">
                                            <TrendingUp size={16}/>
                                        </span>
                                        <span className="nav-text">Trending</span>
                                    </NavLink>
                                </li>
                                {
                                    this.props.authenticated?
                                    (
                                    <Fragment>
                                        <li>
                                            <NavLink to="/favourites" activeClassName="active">
                                                <span className="nav-icon">
                                                    <Heart size={16}/>
                                                </span>
                                                <span className="nav-text">Favourites</span>
                                            </NavLink>
                                        </li>
                                        <ArtistDashboard user={this.props.user}/>
                                    </Fragment>
                                    ):('')
                                }
                                {
                                    this.props.authenticated?
                                    (
                                        <div>
                                            <li className="nav-header d-flex nav-fold">
                                                <span className="py-2 text-muted hidden-folded">Your { pluralize('Playlist', this.props.userPlaylists.length )}</span>
                                                <span className="flex hidden-folded"></span>
                                                {/* <span className="text-md" data-toggle="modal" rel="tooltip" data-target="#createPlaylist" data-placement="right" title="Create playlist" onClick={this.openModal} style={{cursor: 'pointer'}}>
                                                    <Plus size={12}/>
                                                </span> */}
                                            </li>
                                            <div className="container cursor d-flex align-items-center mb-3" data-toggle="modal" data-target="#createPlaylist" onClick={this.openModal}>
                                                <span className="button-container">+</span>
                                                <span className="button-text"> Create Playlist</span>
                                            </div>
                                        </div>
                                    ): ('')
                                }
                                {
                                    this.props.authenticated?
                                    (
                                        <li className="open">
                                            <ul className="nav nav-sub">
                                                {
                                                    this.props.userPlaylists.map((playlist) => {
                                                        return (<li key={playlist.generated_id}>
                                                            <Link to={`/playlist/${(playlist.generated_id)}`} className="px-2">
                                                                <span className="nav-text">{ playlist.title }</span>
                                                            </Link>
                                                        </li> )
                                                    })
                                                }
                                            </ul>
                                        </li>
                                    ): ('')
                                }
                                
                                
                            </ul>
                        </div>
                    </div>
                    {/* <div className="nav-fold py-1">
                        <a data-toggle-className="folded" data-target="#aside" className="p-3">
                            <i data-feather="menu"></i>
                            <Menu size={16} />
                        </a>
                    </div> */}
                    <div className="nav-fold py-1">
                        <div className="nav-fold py-1 p-3">
                            <ThemeMode />
                        </div>
                    </div>
                    
                </div>
                <CreatePlaylist isOpen={this.state.modalIsOpen}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userPlaylists: state.playlist.userPlaylists,
        authenticated: state.auth.authenticated,
        user: state.auth.user,
        following: state.auth.following,
        likes: state.auth.likes,
        userThemeMode: state.user.userThemeMode
    }
}

export default connect(mapStateToProps,{ fetchUserPlaylists, fetchFollows, fetchLikes, getUserIp })(Sidebar);