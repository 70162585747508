import React, { useEffect, useState } from  'react';
import { connect } from 'react-redux';
import { search } from '../../actions';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import PropTypes from 'prop-types';
import { Link, useHistory  } from 'react-router-dom';
import useDebounce from '../Util/debounce';

const SearchMenuItem = ({result},text) => {

	//conditonal result rendering because we expect API to send results of playlist, artists, albums and users

	//checks if this result is a playlist
	if(result.title){
		return (
			<Link to={`/search/${result.title}`} className="my-2" replace>
			    <span className="font-weight-bold">{result.title}</span>
		   </Link>
	   );
	}

	//checks if this result is an artist
	if(result.artist_name){
		return (
			<Link to={`/search/${result.artist_name}`} className="my-2" replace>
			   	<span className="font-weight-bold">{result.artist_name}</span>
		   	</Link>
	   );
	}

	//check if this result is a user
	if(result.username || result.firstname || result.lastname){
		return (
			<Link to={`/search/${result.username !==""?result.username:(result.firstname !==""?result.firstname: result.lastname)}`} className="my-2" replace>
			   <span className="font-weight-bold">{result.username !==""?result.username:(result.firstname !==""?result.firstname: result.lastname)}</span>
		   </Link>
	   );
	}

	// default result - track/album/song
	return (
		<Link to={`/search/${result.release_title}`} className="my-2" replace>
		   <span className="font-weight-bold">{result.release_title}</span>
		   <span className="text-muted ml-2">{result.display_artist_name}</span> 
	   </Link>
   );
}

// class SearchComponent extends Component{
// 	state = {
// 		allowNew: false,
//     	isLoading: false,
//     	multiple: false,
// 		options: [],
// 		query: ""
// 	}
	
// 	constructor(props){
// 		super(props);		
// 		this.handleSearch = this.handleSearch.bind(this);
// 	}

// 	async handleSearch(query){
// 		this.setState({isLoading: true});
// 		await axios.post(`/api/search/${query}`,{
// 			territory_code: Object.entries(this.props.user).length > 0 && this.props.user.constructor === Object?this.props.user.Country.alpha_2: this.props.location
// 		})
// 		.then(response => {
// 			const results = response.data.data.albums.concat(response.data.data.artists,response.data.data.playlists,response.data.data.users);
// 			console.log(results);
// 			this.setState({
// 				isLoading: false,
// 				options: results,
// 			});
// 		})
// 		.catch((error)=>{
// 			console.log(error)
// 		});
// 	}

// 	handleRedirect = (e) => {
// 		let history = useHistory();
// 		history.push(`/search/${e.target.value}`);
// 		// window.history.pushState({query: e.target.value},'',`/search/${e.target.value}`);
// 	}

// 	render(){
// 		return (
// 			<form className="input-group m-2 my-lg-0">
//                	{/* <div className="input-group-prepend">
//                     <button type="button" className="btn no-shadow no-bg px-0">
//                         <Search size={16} />
//                     </button>
//                 </div> */}
//                 <input type="text" className="form-control no-border no-shadow no-bg" placeholder="Search..." onChange={this.handleRedirect}/>
//                 {/* <AsyncTypeahead
//                  	{...this.state}
// 					 id="search"
					
//                  	labelKey={(option) => {
// 						if(option.title){
// 							return option.title
// 						}

// 						if(option.username || option.firstname || option.lastname){
// 							if(option.firstname){
// 								return option.firstname
// 							}

// 							if(option.lastname){
// 								return option.lastname
// 							}

// 							return option.username
// 						}

// 						if(option.artist_name){
// 							return option.artist_name
// 						}

// 						return option.release_title
// 					 }}
//                  	filterBy={['release_title','title','artist_name','username','firstname','lastname']}
// 			        minLength={3}
// 			        options={this.state.options}
// 			        onSearch={this.handleSearch}
// 			        placeholder="Search..."
// 			        renderMenuItemChildren={(option, props)=>{
// 			        	return (
// 			        		<SearchMenuItem key={option.id} result={option} text={props.text}/>
// 			        	)
// 			        }}
// 			        className="form-control no-border no-shadow no-bg text-white"
//                 /> */}
//             </form>
// 		);
// 	}
// }

const SearchComponent = () => {
	const [query, setQuery] = useState("");
	const history  = useHistory();

	const debouncedSearchTerm = useDebounce(query, 500);
	useEffect(
		() => {
		  // Make sure we have a value (user has entered something in input)
		  if (debouncedSearchTerm) {
			// Set isSearching state
			history.push(`/search/${query}`);			
		  }
		},
		// This is the useEffect input array
		// Our useEffect function will only execute if this value changes ...
		// ... and thanks to our hook it will only change if the original ...
		// value (searchTerm) hasn't changed for more than 500ms.
		[debouncedSearchTerm]
	  );

	return (
		<form className="input-group m-2 my-lg-0">
			<input type="text" className="form-control border border-white search__input" placeholder="Search..." onChange={e => setQuery(e.target.value)} onClick={(e) => query !=="" && query === e.target.value?'':history.push('/search')}/>
		</form>
	)
}


const mapStateToProps = (state) => {
	return {
		albums: state.search.albums,
		artists: state.search.artists,
		loading: state.search.loading,
		user: state.auth.user,
		location: state.user.location
	}
}

SearchMenuItem.propTypes = {
	result: PropTypes.object.isRequired
}

export default (connect(mapStateToProps,{ search })(SearchComponent));