import React, { Component } from 'react';
import { toggleThemeMode } from "../../actions/user";
import { connect } from 'react-redux';

const style = {
    pointerBtn: {
        cursor: 'pointer'
    }
};

class ThemeMode extends Component {

    constructor(props){
        super(props);

        this.toggleMode = this.toggleMode.bind(this);
    }

    toggleMode(mode){
        this.props.toggleThemeMode(mode);
    }

    render(){
        return (
            <div className="btn-group btn-group-toggle mb-3" data-toggle="buttons">
                <label className={`btn ${this.props.userThemeMode === 'dark'? 'active': ''}`} style={style.pointerBtn}>
                    {
                        this.props.userThemeMode === 'dark' ? 
                        (<input type="radio" name="bg" defaultChecked  onClick={() => this.toggleMode('dark') }/>) :
                        (<input type="radio" name="bg" onClick={() => this.toggleMode('dark') }/>)
                    }
                     Dark
                </label>
                <label className={`btn ${this.props.userThemeMode === 'light'? 'active': ''}`} style={style.pointerBtn}>
                    {
                        this.props.userThemeMode === 'light' ? 
                        (<input type="radio" name="bg" checked onClick={() => this.toggleMode('light')}/>)
                        :
                        (<input type="radio" name="bg" onClick={() => this.toggleMode('light')}/>)
                    }
                    Light
                </label>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userThemeMode: state.user.userThemeMode
    }
}
export default connect(mapStateToProps,{ toggleThemeMode })(ThemeMode);