import * as actionTypes from './types';
import axios from 'axios';
import {toast} from 'react-toastify';

export const fetchUser = (userID) => dispatch => {
	dispatch({
		type: actionTypes.FETCHING_USER
	})
	axios
	.post('/api/user',{
		generatedID: userID
	})
	.then(response => {
		dispatch({
			type: actionTypes.FETCH_USER,
			payload: response.data.data
		});
	})
	.catch((error)=>{
		console.log('something went wrong!');
	});
}
export const fetchSuggestedUsers = (userID) => dispatch => {
	axios
	.post('/api/user/suggestedusers/playlist',{
		generatedID: userID
	})
	.then(response => {
		dispatch({
			type: actionTypes.FETCH_SUGGESTED_USERS,
			payload: response.data.data
		})
	})
	.catch((error) =>  console.log(error));
}

export const followUser = (follower, followed) => dispatch => {
	axios
	.post('/api/user/follow',{
		followerID: follower,
		followedID: followed,
	})
	.then(response => {
		dispatch({
			type: actionTypes.FOLLOW_USER,
			payload: response.data.data
		});
	})
	.catch(error => console.log(error));
}

//unfollow user
export const unfollowUser = (follower, unfollowed) => dispatch => {
	axios
	.post('/api/user/unfollow',{
		followerID: follower,
		followedID: unfollowed,
	})
	.then(response => {
		dispatch({
			type: actionTypes.UNFOLLOW_USER,
			payload: response.data.data
		});
	})
	.catch(error => console.log(error));
}

export  const updateUserInfo = (data) => dispatch => {
	toast('updating info...');
	dispatch({
		type: actionTypes.UPDATING_USER_INFO
	});
	axios
	.post('/api/user/update',data)
	.then(response => {
		localStorage.setItem('user',window.btoa(JSON.stringify(response.data.data)));
		dispatch({
			type: actionTypes.UPDATE_USER_INFO,
			payload: response.data.data
		});

		toast.success('User info updated!');
	})
}

export const updateLike = (data) => dispatch => {
	dispatch({
		type: actionTypes.UPDATE_USER_LIKES,
		payload: data
	});
}

export const favourites = (id) => dispatch => {
	axios
	.post('/api/user/favourites',{
		userID: id
	})
	.then(response => {
		dispatch({
			type: actionTypes.FETCH_USER_FAVOURITES,
			payload: response.data.data
		});
	})
	.catch(error => {
		toast.error('Network Error! please try again later!!');
	});
}


export const getUserIp = () => dispatch => {
	axios
	.post('/api/user/ip',{
		ip: localStorage.getItem('clientIP')
	})
	.then(response => {
		dispatch({
			type: actionTypes.FETCH_LOCATION_FROM_IP_ADDRESS,
			payload: response.data.location
		});
		
		localStorage.setItem('location',response.data.location.country);

		if(response.data.restrictIp){
			return window.location.replace('/notavailable');
		}
	})
	.catch(error => console.log(error));
}

//toggle from dark to light theme mode or vice-versal
export const toggleThemeMode = (mode) => dispatch => {
	// save user theme settings in local storage
	localStorage.setItem('theme',mode);
	
	dispatch({
		type: actionTypes.UPDATE_USER_THEME_MODE,
		payload: mode
	})
}

//gdpr settings toggle
export const changeGDPRSettings = (setting) => dispatch => {
	dispatch({
		type: actionTypes.UPDATE_GDPR,
		payload: setting
	});

	//
}