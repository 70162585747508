import React, { Component } from 'react';
import Track from '../components/Track/Track';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchMostStreamedSongs } from '../actions/topCharts';

class TopChart extends Component {
    componentDidMount(){
        this.props.fetchMostStreamedSongs()
    }
    render(){
        if(this.props.isLoading){
            return (
                    <div id="content" className="flex ">
                        <div className="page-container">
                            <div className="bg-light">
                                <div className="page-container padding">
                                    <div className="loader">
                                        <div className="loading">Loading&#8230;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    );
        }
        return(
            <div id="content" className="flex">
                <div className="page-container">
                    <div className="padding sr" id="list">
                        <div className="heading py-2 d-flex ">
                            <div>
                                <div className="text-muted text-sm sr-item">Top</div>
                                <h5 className="text-highlight sr-item">Trending</h5>
                            </div>
                            <span className="flex"></span>
                        </div>
                        <div className="row list list-row list-index">
                            {
                                this.props.mostStreamedSongs.length >0?
                                this.props.mostStreamedSongs.map(mostStreamed=>{
                                    return <Track track={mostStreamed} 
                                    key={mostStreamed.isrc} tracks={this.props.mostStreamedSongs} isAlbum={true}/>
                            
                                }):
                                (<div className="no-result">
                                    <div className="p-5 text-center">
                                        <h5>Nothing Found</h5>
                                        <small>It seems we can’t find what you’re looking for.</small>
                                    </div>
                                </div>)
                            }
                        </div>
                        
                        <div className="pagination pagination-sm mt-3 hide"></div>
                    </div>
                </div>
            </div>
        )
    }
}

TopChart.propTypes = {
    fetchMostStreamedSongs: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
    return {
        mostStreamedSongs: state.topChart.mostStreamedSongs,
        isLoading: state.topChart.isLoading
    }
}

export default connect(mapStateToProps,{ fetchMostStreamedSongs })(TopChart);