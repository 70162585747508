import React, { Component, Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchArtist, fetchTopTracks, fetchSimilarArtists, fetchArtistPlaylists, fetchArtistFollowers } from '../actions/artist';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Track from '../components/Track/Track';
import Single from '../components/Track/Single';
import FollowBtn from '../components/Artist/FollowBtn';
import { CheckCircle, Heart, MoreHorizontal } from 'react-feather';
import { cipher } from '../utils/util';
import playlist from '../reducers/playlist';
import axios from 'axios';

const style = {
    verifiedBadge:{
        top: '0px',
        strokeWidth: '3px',
        marginLeft: '.2rem'
    }
};

//playlists that have artist songs
const Playlists = (props) =>{
    const [playlists, setPlaylists] = useState([]);

    useEffect(() => {
        if(playlist.length ===0){
            //make an axios call to the backend
            axios
            .post('/api/v1/artist/playlists',{
                songs: props.songs.map(song => song.id)
            })
            .then(response => {
                setPlaylists(response.data.data);
            })
            .catch(error => {
                console.log(error);
            })
        }
    })

    return (
        <div className="row list mb-4">
            {
                playlists.map(playlist => {
                    return (
                        <div className="col-6 col-sm-3" key={playlist.generated_id}>
                            <div className="list-item r">
                                <div className="media ">
                                    {
                                        playlist.artwork_path !==''?
                                        (<Link to={`/playlist/${(playlist.generated_id)}`} className="media-content " style={{backgroundImage:"url('"+playlist.artwork_path+"')"}}>
                                        </Link>)
                                        :
                                        (<Link to={`/playlist/${(playlist.generated_id)}`} className="media-content" style={{backgroundImage:"url('"+playlist.Album.artwork_file_path+"')"}}>
                                        </Link>)
                                    }
                                </div>
                                <div className="list-content text-center">
                                    <div className="list-body ">
                                        <Link to={`/playlist/${(playlist.generated_id)}`} className="list-title title ajax h-1x">
                                            { playlist.title }
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    })
                }
            </div>
                                    
    )
}
//similar artists
const SimilarArtist = (props) => {
    return (
        <div className="col-4 col-sm-3 col-md-2-4 col-lg-3 col-xl-2" data-id={ props.artist.generated_id } data-category="all" data-tag={props.artist.country}>

            {/* <div className="sub-loader">
                <div className="loading">Loading&#8230;</div>
            </div> */}
            <div className="list-item r sub-comp">
                
                <div className="media ">
                    {
                        (<Link to={`/artist/${ (props.artist.generated_id) }`} className="media-content " style={{backgroundImage: 'url('+props.artist.artist_image+')'}}>
                        </Link>)
                    }
                    
                    <div className="media-action">
                    </div>
                </div>
                <div className="list-content text-center">
                    <div className="list-body ">
                        <Link to={`/artist/${(props.artist.generated_id)}`} className="list-subtitle d-block text-muted  subtitle h-1x">
                            { props.artist.artist_name }
                        </Link>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

const TopTrack = (props) => {
    const encrypt = cipher('78sji3w9-2djadw2');
    return (
        <div className="col-12" data-id={ props.track.Album.isrc } data-category={ props.track.Album.genre } data-tag={ props.track.Album.sub_genre } data-source={ encrypt(props.track.Album.audio_file_path_192) }>
            <div className="list-item r">
                <div className="media">
                    <Link to={`/single/${props.track.Album.id}`} className="ajax media-content" style={{backgroundImage:`url('${props.track.Album.artwork_file_path}')`}} data-pjax-state=""></Link>
                    <div className="media-action">
                        <button className="btn btn-icon no-bg no-shadow hide-row">
                            <Heart size={16}/>
                        </button>
                        <button className="btn btn-raised btn-icon btn-rounded bg--white btn-play"></button>
                        <button className="btn btn-icon no-bg no-shadow hide-row btn-more" data-toggle="dropdown">
                            <MoreHorizontal size={16} />
                        </button>
                        <div className="dropdown-menu dropdown-menu-right"></div>
                    </div>
                </div>
                <div className="list-content">
                    <div className="list-body d-flex justify-content-between">
                        <div>
                            <Link to={`/single/${props.track.Album.id}`} className="list-title title ajax h-1x" data-pjax-state="" title={props.track.Album.release_title}>{ props.track.Album.release_title }</Link>
                            <Link to="#" className="list-subtitle d-block text-muted subtitle ajax h-1x" data-pjax-state="" title={props.track.Album.display_artist_name}>{ props.track.Album.display_artist_name }</Link>
                        </div>
                        <span>{props.track.streamCount}</span>
                    </div>
                </div>
            </div>
        </div>)
}

class Artist extends Component {
    componentDidMount(){
        this.props.fetchArtist(this.props.match.params.id);
        this.props.fetchTopTracks(this.props.match.params.id);
    }

    componentDidUpdate(prevProps){
        if(this.props.location !== prevProps.location){
            this.props.fetchArtist(this.props.match.params.id);
            this.props.fetchTopTracks(this.props.match.params.id);
        }
        
        // wait for artist to fetch data of artist
        if (this.props.artist !== prevProps.artist) {
            let sub_genre =  this.props.albums.length > 0? this.props.albums.map(album => album.sub_genre) : this.props.singles.map(single => single.sub_genre);
            // remove duplicate
            sub_genre = [...new Set(sub_genre)];
            sub_genre = sub_genre.filter(s  => s !== "");

            this.props.fetchSimilarArtists({
                sub_genre,
                artistID: this.props.artist.generated_id,
                location: this.props.artist.country
            });

            this.props.fetchArtistFollowers(this.props.artist.id);

            //check if artist has a song
            if(this.props.songs.length >0){
                this.props.fetchArtistPlaylists(this.props.songs.map(song => song.id));
            }
        }
        
    }

    render() {  
        if(this.props.loading){
            return (
                    <div id="content" className="flex ">
                        <div className="page-container">
                            <div className="bg-light">
                                <div className="page-container padding">
                                    <div className="loader">
                                        <div className="loading">Loading&#8230;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    );
        }
        
        return (
            <div id="content" className="flex">
                <div className="page-container">
                    <div className="bg-light">
                        <div className="page-container padding">
                            <div className="d-sm-flex page-heading">
                                <div className="media circle w w-auto-xs mb-4">
                                    {
                                        this.props.artist.artist_image !==''?
                                        (<div className="media-content" style={{ backgroundImage: "url('"+this.props.artist.artist_image+"')" }}></div>)
                                            :this.props.albums.length >0?
                                        (<div className="media-content" style={{ backgroundImage: "url('"+this.props.albums[0].artwork_file_path+"')" }}></div>):this.props.singles.length >0? (<div className="media-content" style={{ backgroundImage: "url('"+this.props.singles[0].artwork_file_path+"')" }}></div>) : ('')
                                    }
                                     
                                    {/* 
                                        http://www.meplaylist.com/assets/landing/images/artist_img3.jpg 
                                    */}
                                </div>
                                <div className="d-md-flex flex">
                                    <div className="mx-sm-4 flex text-light">
                                        <h1 className="h4 font-weight-bold mb-0">
                                            { this.props.artist.artist_name}
                                            {
                                                this.props.artist.approval_status?
                                                (<CheckCircle className="text-primary" style={style.verifiedBadge} size={18}/>)
                                                : ('')
                                            }
                                        </h1>
                                        <div className="py-4 toolbar align-items-center">
                                            <FollowBtn artistID={this.props.artist.id}/>
                                            {
                                                this.props.followers > 0?
                                                (
                                                    <Fragment>
                                                        <span className="d-inline">Followers:</span>
                                                        <span className="badge badge-light text-dark">{ this.props.followers }</span>
                                                    </Fragment>
                                                ): ('')
                                            }
                                        </div>
                                        <div className="mt-2">
                                            {
                                                this.props.artist.artist_bio && this.props.artist.artist_bio !== ''?
                                                (<div><h4 className="title text-white">Bio</h4>
                                                <p>{ this.props.artist.artist_bio }</p></div>) : ('')
                                            }  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-container padding">
                        <div className="d-md-flex">
                            <div className="flex">
                                <div className="d-flex">
                                    <ul className="nav nav-sm text-sm nav-pills py-4">
                                        {
                                            (this.props.albums.length >0)?

                                                (
                                                    <li className="nav-item">
                                                        <a className="nav-link active" href="#albums" data-toggle="tab">Albums <span className="badge badge-pill badge-light rounded-circle">{ this.props.albums.length }</span></a>
                                                    </li>
                                                ):('')
                                        }
                                        {
                                            this.props.singles.length > 0?
                                            
                                            (
                                                <li className="nav-item">
                                                    <a className={ this.props.albums.length ===0?'nav-link active':'nav-link'} href="#singles" data-toggle="tab">Singles <span className="badge badge-pill badge-light">{ this.props.singles.length }</span></a>
                                                </li> 
                                            ):
                                            ('')
                                        }
                                        
                                        {
                                            this.props.playlists.length >0 ?
                                            (
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#playlist" data-toggle="tab">Playlist <span className="badge badge-pill badge-light">{ this.props.playlists.length }</span>
                                                    </a>
                                                </li>  
                                            ) : ('')
                                        }
                                    </ul>
                                </div>
                                <div className="tab-content">
                                    {
                                        this.props.albums.length >0? 
                                        (
                                            <div className="tab-pane fade show active" id="albums">
                                                <div className="heading py-2 d-flex">
                                                    <div>
                                                        {/* <div className="text-muted text-sm sr-item">Popular</div> */}
                                                        <h5 className="text-highlight sr-item">Albums</h5>
                                                    </div>
                                                    <span className="flex"></span>
                                                </div>
                                                <div className="row list-row list-index">
                                                    {
                                                        this.props.albums.map(album => {
                                                            return <Track track={album} key={album.isrc} isAlbum={true} tracks={this.props.albums}/>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        ):('')
                                    }
                                    
                                    <div className={ this.props.albums.length ===0 ? 'tab-pane fade show active' : 'tab-pane fade' } id="singles">
                                        <div className="row list-row">
                                            {
                                                this.props.singles.map(track => {
                                                    return <Single track={track} key={track.isrc} tracks={this.props.singles}/>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="playlist">
                                        <div className="row list mb-4">
                                            {
                                                this.props.playlists.map(playlist => {
                                                    return (
                                                        <div className="col-6 col-sm-3 col-xl-2" key={playlist.Playlist.generated_id}>
                                                            <div className="list-item r">
                                                                <div className="media ">
                                                                    {
                                                                        playlist.Playlist.artwork_path !==''?
                                                                        (<Link to={`/playlist/${(playlist.Playlist.generated_id)}`} className="media-content " style={{backgroundImage:"url('"+playlist.Playlist.artwork_path+"')"}}>
                                                                        </Link>)
                                                                        :
                                                                        (<Link to={`/playlist/${(playlist.Playlist.generated_id)}`} className="media-content" style={{backgroundImage:"url('"+playlist.Playlist.PlaylistSongs[0].Album.artwork_file_path+"')"}}>
                                                                        </Link>)
                                                                    }
                                                                </div>
                                                                <div className="list-content text-center">
                                                                    <div className="list-body ">
                                                                        <Link to={`/playlist/${(playlist.Playlist.generated_id)}`} className="list-title title ajax h-1x">
                                                                            { playlist.Playlist.title }
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="followers">
                                        <div className="row list media-circle">
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="83196096" data-category="all" data-tag="Germany" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/Music/v4/96/2f/d0/962fd0e2-5010-1a2c-5822-c6637e22b1df/mzaf_827319468004061581.plus.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#83196096" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a17.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#83196096" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                Milky Chance
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="89551205" data-category="Rap" data-tag="USA" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/Music/v4/fb/6f/53/fb6f536e-540e-7572-bcd8-70413277f8e5/mzaf_4420416069866314124.plus.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#89551205" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a20.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#89551205" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                Pharrell Williams
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                        
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="53754394" data-category="all" data-tag="USA" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/Music/04/e1/7b/mzi.gpwvrrex.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#53754394" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a9.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#53754394" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                Macklemore &amp; Ryan Lewis Feat. Ray Dalton
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="324443962" data-category="all" data-tag="USA" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/AudioPreview62/v4/bd/6b/34/bd6b3443-d4c3-26fd-91c8-d0b31ab47ee3/mzaf_8046559398209773051.plus.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#324443962" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a5.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#324443962" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                The Chainsmokers Feat. Halsey
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="82924078" data-category="France" data-tag="Electronic" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/Music/v4/fa/37/1c/fa371cea-559d-f418-506a-5fdf64bed3fe/mzaf_1505180730434746810.plus.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#82924078" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a15.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#82924078" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                Daft Punk Feat. Pharrell Williams
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="234782921" data-category="Electronic" data-tag="USA" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/Music69/v4/02/4c/98/024c9802-ce83-e0b4-3cd3-11c5c6284cdb/mzaf_9006921880389738307.plus.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#234782921" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a6.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#234782921" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                Major Lazer Feat. MØ &amp; DJ Snake
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="following">
                                        <div className="row list media-circle">
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="83196096" data-category="all" data-tag="Germany" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/Music/v4/96/2f/d0/962fd0e2-5010-1a2c-5822-c6637e22b1df/mzaf_827319468004061581.plus.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#83196096" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a17.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#83196096" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                Milky Chance
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="89551205" data-category="Rap" data-tag="USA" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/Music/v4/fb/6f/53/fb6f536e-540e-7572-bcd8-70413277f8e5/mzaf_4420416069866314124.plus.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#89551205" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a20.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#89551205" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                Pharrell Williams
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="338965882" data-category="Pop" data-tag="United Kingdom" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/AudioPreview111/v4/65/ca/83/65ca8336-2e09-a0bb-a810-2a6b8864e770/mzaf_3545919152242528717.plus.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#338965882" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a11.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#338965882" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                Ed Sheeran
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="163144443" data-category="Electronic" data-tag="Norway" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/Music2/v4/5e/53/70/5e5370c5-3da1-7a76-587e-7e8827ee534c/mzaf_807237753747484753.plus.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#163144443" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a19.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#163144443" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                Kygo Feat. Conrad Sewell
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="53754394" data-category="all" data-tag="USA" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/Music/04/e1/7b/mzi.gpwvrrex.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#53754394" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a9.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#53754394" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                Macklemore &amp; Ryan Lewis Feat. Ray Dalton
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="324443962" data-category="all" data-tag="USA" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/AudioPreview62/v4/bd/6b/34/bd6b3443-d4c3-26fd-91c8-d0b31ab47ee3/mzaf_8046559398209773051.plus.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#324443962" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a5.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#324443962" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                The Chainsmokers Feat. Halsey
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="82924078" data-category="France" data-tag="Electronic" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/Music/v4/fa/37/1c/fa371cea-559d-f418-506a-5fdf64bed3fe/mzaf_1505180730434746810.plus.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#82924078" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a15.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#82924078" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                Daft Punk Feat. Pharrell Williams
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id="234782921" data-category="Electronic" data-tag="USA" data-source="https://audio-ssl.itunes.apple.com/apple-assets-us-std-000001/Music69/v4/02/4c/98/024c9802-ce83-e0b4-3cd3-11c5c6284cdb/mzaf_9006921880389738307.plus.aac.p.m4a">
                                                <div className="list-item r">
                                                    <div className="media ">
                                                        <a href="music.artist.html#234782921" className="ajax media-content " style={{backgroundImage:"url('../assets/img/a6.jpg')"}}>
                                                        </a>
                                                        <div className="media-action ">
                                                        </div>
                                                    </div>
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <a href="music.artist.html#234782921" className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                Major Lazer Feat. MØ &amp; DJ Snake
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-xl w-auto-sm no-shrink">
                                <div className="padding sticky">
                                    {
                                        this.props.topTracks.length > 0?
                                        (
                                        <Fragment>
                                        <h6 className="text text-muted">Top tracks</h6>
                                        <div className="row list-row">
                                            { 
                                                this.props.topTracks.map(track => {
                                                    return (<TopTrack track={track} key={track.Album.isrc} />)
                                                })
                                            }
                                        </div>
                                        </Fragment>):('')
                                    }
                                    
                                    <div className="b-b my-3"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row list media-circle">
                            {
                                this.props.similarArtists.length > 0?
                                (<div className="col-12 heading py-2 d-flex mt-5">
                                    <div>
                                        <h1 className="text-highlight sr-item font-weight-bold">Similar Artists</h1>
                                    </div>
                                    <span className="flex"></span>
                                </div>) 
                                : 
                                ('')
                            }
                            
                            {
                                this.props.similarArtistLoading
                                ?
                                (
                                    <div className="col-12 col-sm-3 col-md-2-4 col-lg-3 col-xl-1-8 sub-loader">
                                        <div className="loading">Loading&#8230;</div> 
                                    </div>  
                                ) 
                                : 
                                this.props.similarArtists.map(artist => {
                                    return (<SimilarArtist artist={artist.Artist} key={artist.Artist.generated_id}/>)
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Artist.propTypes = {
    fetchArtist: PropTypes.func.isRequired,
    artist: PropTypes.object.isRequired,
    albums: PropTypes.array.isRequired,
    singles: PropTypes.array.isRequired,
    playlists: PropTypes.array.isRequired
}
const mapStateToProps = (state) => {
    return {
        artist: state.artist.artist,
        albums: state.artist.albums,
        singles: state.artist.singles,
        playlists: state.artist.playlists,
        followers: state.artist.followers,
        loading: state.artist.loading,
        topTracks: state.artist.topTracks,
        similarArtists: state.artist.similarArtists,
        songs: state.artist.songs,
        isFollowing: state.artist.isFollowing
    }
}

export default connect(mapStateToProps,{ fetchArtist, fetchTopTracks, fetchSimilarArtists, fetchArtistPlaylists, fetchArtistFollowers })(Artist);