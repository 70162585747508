import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ADD_TO_PLAYLIST_SONG, DELETE_PLAYLIST_SONG, UPDATE_SHARE_PROPERTIES} from '../../actions/types';
import { MoreHorizontal } from 'react-feather';
import Swal from 'sweetalert2';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';

class PlayerActions extends Component {
	constructor(props){
		super(props);
		this.state = {
			link: this.props.link,
			song: this.props.song,
			type: this.props.type
		}
	}

    render() {
        return (
            <div>
                <button className="btn btn-icon no-bg no-shadow btn-more" data-toggle="dropdown">
                    <MoreHorizontal size={16}/>
                </button>
                <div className="dropdown-menu dropdown-menu-right" role="menu" style={{zIndex: '10000'}}>
	                <span className="dropdown-item btn-play">Play</span>
	      			{/* <span className="dropdown-item" href="#">Next to play</span> */}
	      			<span className="dropdown-item add-to-queue" style={{ cursor: 'pointer'}}>Add to queue</span>
	                <span className="dropdown-item" data-toggle="modal" data-target="#addToPlaylist" onClick={() => this.props.addSongID(this.props.songID)} style={{ cursor: 'pointer'}}>Add to playlist</span>
					{
						this.props.playlistID?
						(<span className="dropdown-item" onClick={() => this.props.deleteSongFromPlaylist(this.props.songID)} style={{ cursor: 'pointer'}}>Remove from playlist</span>) : ('')
					}
					<hr/>
					<span className="dropdown-item share" style={{ cursor: 'pointer'}} onClick={() => this.props.share() }>Share</span>            
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch,ownProps) => {
	return {
		addSongID: () => {
			return dispatch({
				type: ADD_TO_PLAYLIST_SONG,
				payload: ownProps.songID
			})
		},
		deleteSongFromPlaylist: () => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			  }).then((result) => {
				if (result.value) {
					axios
					.delete('/api/playlistsong/',{
						data:{
							songID: ownProps.songID,
							playlistID: ownProps.playlistID
						}
					})
					.then((response) => {
						dispatch({
							type: DELETE_PLAYLIST_SONG,
							payload: ownProps.songID
						});
						toast.success('Playlist Deleted!');
					})
					.catch(error => {
						toast.error('Playlist Could not be updated')
					});	
				}
			})			
		},
		share(){
			// dispatch here
			dispatch({
				type: UPDATE_SHARE_PROPERTIES,
				payload: {
					link: ownProps.link,
					song: ownProps.song,
					type: ownProps.type
				}
			});
			
			// show modal here
			$('#share').modal('toggle');
		}
	}
}

export default connect(null,mapDispatchToProps)(PlayerActions);
