import React, { Component } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
// import Image from '../../assets/images/beautiful-curly-curly-hair-2121063.jpg';
import Image from '../assets/images/loginImage.jpeg';
import logo from '../assets/images/meplaylist_color_white_text.fw.png';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sendResetCode } from '../actions/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import axios from 'axios';
import { sha256 } from 'js-sha256';
import base64js from 'base64-js';

class Forgot extends Component {
	constructor(props){
		super(props);
		this.state = {
			email: '',
            password: '',
            code: '',
            loading: false,
            codeVerified: false,
            confirmPassword: '',
            confirmPasswordCheck: '',
            passwordChanged: false
		}
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handlePasswordReset = this.handlePasswordReset.bind(this);
	}
    

	handleSubmit(e){
        e.preventDefault();
        this.setState({
            loading: true
        });
        axios.post('/api/auth/user/verifyresetcode',{
            email: this.state.email,
            code: this.state.code
        })
        .then(response => {
            this.setState({
                loading: false
            });
            if(response.data.success){
                this.setState({
                    codeVerified: true
                });
            }else{
                if(response.data.message === 'expired')
                    toast.error('Reset Code Expired!');
                else
                toast.error('Reset Code Invalid!');
            }
            
        })
        .catch(error => {
            console.log(error);
        });
    }
    
    handlePasswordReset(e){
        e.preventDefault();
        this.setState({
            loading: true
        });
        if(this.state.password === this.state.confirmPassword){
            this.setState({
                confirmPasswordCheck: ''
            });
            var salt = '330-0@03-2-@((#@#_@--2rfninv9mf ot--4ii8';
            var passwordHash = sha256.digest(salt+''+this.state.password);
            var newPasswordHash = sha256.digest(salt+''+this.state.confirmPassword);
            var passwordEncode = base64js.fromByteArray(passwordHash);
            var confirmPasswordEncode = base64js.fromByteArray(newPasswordHash);
            axios
            .post('/api/auth/user/resetpassword',{
                password: passwordEncode,
                confirmPassword: confirmPasswordEncode,
                email: this.state.email,
                code: this.state.code
            })
            .then(response => {
                if(response.data.success){
                    toast.success('Password Changed!');
                    this.setState({
                        passwordChanged: true
                    });
                }
            })
        }else{
            this.setState({
                confirmPasswordCheck: 'failed'
            })
        }
    }

	render() {
        // console.log(this.props);
        if(this.state.passwordChanged){
            return (
                <Redirect to="/login" />
            )
        }

		return (
			<div className="row no-gutters vh-100">
                <div className="col-md-6" style={{ backgroundImage: 'url('+Image+')',backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="p-3 p-md-5 d-flex flex-column h-100">
                        {/* <h4 className="mb-3 text-white font-weight-bolder">MePlaylist</h4> */}
                        <Link to="/" className="navbar-brand">
                            <img src={logo} alt="logo"/>
                        </Link>                       
                        <div className="d-flex flex align-items-center justify-content-center">
                        </div>
                        <div className="d-flex text-sm text-fade">
                            <a href="https://www.meplaylist.com" className="text-white font-weight-bolder h5" target="_blank" rel="noopener noreferrer">About</a>
                            <span className="flex"></span>
                            <a href="https://www.meplaylist.com/legal" className="text-white mx-1 font-weight-bolder h5" target="_blank" rel="noopener noreferrer">Terms</a>
                            <a href="https://www.meplaylist.com/privacy-policy" className="text-white mx-1 font-weight-bolder h5" target="_blank" rel="noopener noreferrer">Policy</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div id="content-body">
                        <div className="p-3 p-md-5 mx-md-5 text-center text-md-left">
                            <h3 className="pb-3 font-weight-bolder text-dark" style={{fontSize: '50px'}}>Password Reset
                            </h3>
                            {
                                this.state.codeVerified ? 
                                (
                                    <p class="h6 text-secondary">
                                        Enter your new password
                                    </p>
                                )
                                :
                                (
                                    <p class="h6 text-secondary">
                                        Provide your email address and the reset code we sent to your email
                                    </p>
                                )
                            }
                            
                            <div className="mb-3">
                            	{/* <SocialLogin /> */}
                            </div>
                            <hr/>
                            {
                                this.state.codeVerified?
                                (
                                    <form onSubmit={this.handlePasswordReset}>
                                        <div className="form-group">
                                            <input type="password" className="form-control border-0 login-input" placeholder="Password" value={this.state.password} onChange={(e) => this.setState({password: e.target.value}) } required/>
                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control border-0 login-input" placeholder="Re-enter Password" value={this.state.confirmPassword} onChange={(e) => this.setState({confirmPassword: e.target.value}) } required/>
                                            {
                                                this.state.confirmPasswordCheck === 'failed'?
                                                    (
                                                        <span className="text-danger">password do not match!</span>
                                                    )
                                                    :
                                                    ('')
                                            }
                                            
                                        </div>
                                        
                                        <button type="submit" className="btn btn-dark mb-4 px-3 py-2 btn-block submit mt-3">
                                        {
                                            this.props.loading || this.state.loading? 
                                            (<FontAwesomeIcon icon={faSpinner} size="lg" spin/>):(<span className="h5 text-light">Submit</span>)
                                        }
                                        </button>
                                    </form>
                                )
                                :
                                (
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <input type="email" className="form-control border-0 login-input" placeholder="Email" value={this.state.email} onChange={(e) => this.setState({email: e.target.value}) } required/>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control border-0 login-input" placeholder="Reset Code" value={this.state.code} onChange={(e) => this.setState({code: e.target.value}) } required/>
                                        </div>
                                        
                                        <button type="submit" className="btn btn-dark mb-4 px-3 py-2 btn-block submit mt-3">
                                        {
                                            this.props.loading || this.state.loading? 
                                            (<FontAwesomeIcon icon={faSpinner} size="lg" spin/>):(<span className="h5" style={{color: '#FFF'}}>Submit</span>)
                                        }
                                        </button>
                                    </form>
                                )
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

Forgot.propTypes = {
	handleLogin: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading
    }
}

export default withRouter(connect(mapStateToProps,{ sendResetCode })(Forgot));
