import React, { Component } from 'react';
import Track from "../Track/Track";

class TracksList extends Component {
    componentWillMount () {
    }
    render() {
        if(this.props.playlistID !== undefined){
            return (
                <div className="row list-row list-index">
                    { this.props.tracks.map((track) => {
                        return <Track key={track.id} track={track} isPlaylist={true} playlistID={this.props.playlistID} tracks={this.props.tracks.map(track => track.Album)}/>
                    }) }
                </div>
            );
        }

        return (
            <div className="row list-row list-index">
                { this.props.tracks.map((track) => {
                    return <Track key={track.id} track={track} isPlaylist={true}  tracks={this.props.tracks.map(track => track.Album)}/>
                }) }
            </div>
        );
    }
}

export default TracksList
