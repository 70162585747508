import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
// import Image from '../assets/images/loginImage.jpeg';
import Image from '../assets/images/registerImage.jpg';
import SocialRegister from '../components/SocialLogin/SocialRegister';
import logo from '../assets/images/meplaylist_color_white_text.fw.png';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { handleSignUp, googleAuth, fetchCountries } from '../actions/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import { toast } from 'react-toastify';
// import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google';

class Register extends Component {
	constructor(props){
		super(props);
		this.state = {
            firstname: '',
            lastname: '',
            username: '',
			email: '',
            password: '',
            country: '',
            gender: '',
            deviceSerialNo: window.navigator.userAgent,
			genders: [
                {
                    name: 'Male'
                },
                {
                    name: 'Female'
                }
            ],
            options: [],
            loading: false,
            recaptchaToken: '',
            usernameValid: true,           
		}
		this.handleSubmit = this.handleSubmit.bind(this);        
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.checkIfUsernameAvailable = this.checkIfUsernameAvailable.bind(this);
	}

    componentWillMount(){
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        if(token){
          this.props.googleAuth(token);
        }

        // make call to get all the countries
        if(this.props.countries.length === 0)
            this.props.fetchCountries();

        if (this.captchaDemo) {
            this.captchaDemo.reset();
            this.captchaDemo.execute();
        }
    }

    componentDidMount(){
        // toast.error('🦄 Wow so easy!');
        // loadReCaptcha();
    }

    checkIfUsernameAvailable(username){
        this.setState({
            usernameValid: true,
        });

        axios
        .post('/api/auth/checkusername',{
            username: username
        })
        .then(response => {
            if(response.data.data){ //username exists
                this.setState({
                    usernameValid: false,
                });
            }else{
                this.setState({
                    usernameValid: true,
                });
            }
        })
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
          this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!  
        // console.log(recaptchaToken, "<= your recaptcha token");
        this.setState({
            recaptchaToken
        });
    }

	handleSubmit(e){
        e.preventDefault();
        this.setState({
            loading:true
        })
        //check if email already exists
        axios.post('/api/auth/checkemail',{
            email: this.state.email
        })
        .then(response => {
            if(response.data.data.isExists){
                this.setState({
                    loading: false
                });
                toast.error(<span className="h5">Email Already Exists!</span>);
            }else{
                this.setState({
                    loading: false
                });
                this.props.handleSignUp(this.state);
            }
        })
        .catch(error => {
            console.log(error);
        });
		// dispatch login action Here
        
	}

	render() {
        // console.log(this.state.country);
		return (
			<div className="row no-gutters vh-100">
                <div className="col-md-6 bg-dark" style={{ backgroundImage: 'url('+Image+')',backgroundSize: 'cover', backgroundPosition: 'right' }}>
                    <div className="p-3 p-md-5 d-flex flex-column h-100">
                        {/* <h4 className="mb-3 text-white font-weight-bolder">MePlaylist</h4> */}
                        <Link to="/" className="navbar-brand">
                            <img src={logo} alt="..."/>
                        </Link>                       
                        <div className="d-flex flex align-items-center justify-content-center">
                        </div>
                        <div className="d-flex text-sm text-fade">
                            <Link to="https://www.meplaylist.com" className="text-white font-weight-bolder">About</Link>
                            <span className="flex"></span>
                            <Link to="https://www.meplaylist.com/legal" className="text-white mx-1 font-weight-bolder">Terms</Link>
                            <Link to="https://www.meplaylist.com/privacy-policy" className="text-white mx-1 font-weight-bolder">Policy</Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div id="content-body">
                        <div className="p-3 p-md-5 mx-md-5 text-center text-md-left">
                            <h3 className="pb-3 font-weight-bolder text-dark" style={{fontSize: '50px'}}>Get Started</h3>
                            <p>
                                {/* <small className="text-muted">Login to manage your account</small> */}
                            </p>
                            <div className="mb-3">
                            	<SocialRegister />
                            </div>
                            <hr/>
                            <form onSubmit={this.handleSubmit}>
                                <div className="">
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control border-0 login-input" placeholder="First Name" value={this.state.firstname} onChange={(e) => this.setState({firstname: e.target.value}) } required/>
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control border-0 login-input" placeholder="Last Name" value={this.state.lastname} onChange={(e) => this.setState({lastname: e.target.value}) } required/>
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control border-0 login-input" placeholder="Username" value={this.state.username} onChange={(e) => {
                                        this.setState({username: e.target.value})
                                        this.checkIfUsernameAvailable(e.target.value)
                                    } } required/>
                                    {
                                        !this.state.usernameValid?
                                        (
                                            <span className="text-danger pt-3">
                                                username already taken
                                            </span>
                                        ): ('')
                                    }
                                </div>
                                <div className="form-group login">
                                    <Typeahead
                                        {...this.state}
                                        id="country"
                                        labelKey="name"
                                        options={this.props.countries}
                                        placeholder="Select Your Country"
                                        onChange={(country)=>this.setState({country:country})}
                                    />
                                </div>
                                <div className="form-group login">
                                    <Typeahead
                                        {...this.state}
                                        id="gender"
                                        labelKey="name"
                                        options={this.state.genders}
                                        placeholder="What's your gender"
                                        onChange={(gender)=>this.setState({gender:gender})}
                                    />
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control border-0 login-input" placeholder="Email" value={this.state.email} onChange={(e) => this.setState({email: e.target.value}) } required/>
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control border-0 login-input" placeholder="Password" onChange={(e)=> this.setState({password: e.target.value})}/>
                                </div>
                                <div className="checkbox mb-3 text-left">
                                    <label className="ui-check">
                                        <input type="checkbox" required/>
                                        <i></i> I accept meplaylist <a href="https://www.meplaylist.com/legal" target="_blank" rel="noopener noreferrer">Terms & Condition</a> 
                                    </label>
                                </div>
                                {/*<ReCaptcha
                                    ref={(el) => {this.captchaDemo = el;}}
                                    size="normal"
                                    data-theme="white"            
                                    render="explicit"
                                    sitekey="6LetybsUAAAAAEw3OfhyfmS1ubTSEu5anekl43PU"
                                    onloadCallback={this.onLoadRecaptcha}
                                    verifyCallback={this.verifyCallback}
                                /> */}
                                {
                                    this.state.usernameValid?
                                    (
                                    <button type="submit" className="btn btn-dark mb-4 px-3 btn-block submit mt-3 py-3">
                                    {
                                        this.props.loading || this.state.loading? 
                                        (<FontAwesomeIcon icon={faSpinner} size="lg" spin/>):(<span className="h5 font-weight-bolder mb-0" style={{ color: '#FFF' }}>Sign up </span>)
                                    }
                                    </button>)
                                    : (<button type="button" className="btn btn-dark mb-4 px-3 btn-block submit mt-3 py-3" disabled>
                                        <span className="h5 font-weight-bolder mb-0" style={{ color: '#FFF' }}>Sign up </span>
                                    </button>)
                                }
                                
                                <div className="d-flex justify-content-between">
                                    <span className="h5">Already have an account?
                                    <Link to="/login" className="text-primary"> Login</Link></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

Register.propTypes = {
	handleSignUp: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        countries: state.auth.countries,
    }
}

export default withRouter(connect(mapStateToProps,{ handleSignUp, googleAuth, fetchCountries })(Register));