import React, {Component} from 'react';
import { User, Headphones, Share2 } from 'react-feather';
import Track from '../components/Track/Track';
import Stream from '../components/Stream';
import { connect } from 'react-redux';
import { fetchAlbum, shareAlbum } from '../actions/album';
import { setQueue } from '../actions/playlist';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import pluralize from 'pluralize';
import { cipher } from '../utils/util';
import $ from 'jquery';
import moment from 'moment';
import DisplayArtists from '../components/Artist/DisplayArtists';

class Album extends Component {
    constructor(props){
        super(props);

        this.shareAlbum = this.shareAlbum.bind(this);
    }

    componentDidMount(){
        this.props.fetchAlbum(this.props.match.params.albumID);
    }
    
    componentDidUpdate(prevProps){
        // if the location params change -- usually when a user search on the top bar
        if(this.props.location !== prevProps.location){
            this.props.fetchAlbum(this.props.match.params.albumID);
        }
    }

    shareAlbum(){
        this.props.shareAlbum({
            link: `https://app.meplaylist.com/album/${this.props.album[0].album_id}`,
            song: this.props.album[0],
            type: 'album'
        });

        $('#share').modal('toggle');
    }

    render(){
        if(this.props.albumLoading){
            return (
                    <div id="content" className="flex ">
                        <div className="page-container">
                            <div className="bg-light">
                                <div className="page-container padding">
                                    <div className="loader">
                                        <div className="loading">Loading&#8230;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    );
        }
        const encrypt = cipher('78sji3w9-2djadw2');

        return (
            <div id="content" className="flex ">
                    <div className="page-container">
                        <div className="bg-light">
                            <div className="page-container padding">
                                <div className="d-sm-flex page-heading" data-id={this.props.album.length >0 ?
                                        this.props.album[0].isrc :''
                                        } data-source={
                                        this.props.album.length >0?
                                        encrypt(this.props.album[0].audio_file_path_192) :''
                                        } data-album-id={ this.props.album.length >1?this.props.album[0].album_id:null}>
                                    <div className="media r w mb-4 text-center">
                                        <div className="media-content" style={
                                        this.props.album.length >0?
                                        {  backgroundImage: "url(" + this.props.album[0].artwork_file_path + ")" }:{}
                                        }></div>
                                    </div>
                                    <div className="d-md-flex flex">
                                        <div className="mx-sm-4 flex">
                                            <h1 className="h4 font-weight-bold mb-0 text-highlight title">{ this.props.album.length >0? this.props.album[0].release_title:'' }</h1>
                                            <div className="py-4 toolbar align-items-center">
                                                <button className="btn btn-play btn-icon btn-md btn-rounded btn-primary"onClick={() => this.props.setQueue(this.props.album)}></button>
                                                <Headphones size={20}/>
                                                { this.props.album.length >0?(this.props.album.length ===1?<Stream id={ this.props.album[0].id } type="single"/>:<Stream id={ this.props.album[0].album_id } type="album"/>) :<span className="text-muted">0</span>
                                                    }
                                            </div>
                                            <div className="mb-4">
                                                <span className="text-muted">Genre: </span>
                                                <Link to={ this.props.album.length >0 && this.props.album[0].Artist ? `/artist/${ this.props.album[0].Artist.id }` : '' } className="text-color ajax">
                                                <span className="badge badge-light">{ this.props.album.length >0 ? this.props.album[0].genre : '' }</span>
                                                </Link>
                                                <button className="btn btn-raised mb-2 btn-light font-weight-bold ml-3" onClick={() => this.shareAlbum()}>
                                                    <Share2 size={16} className="mr-2" /> Share Album
                                                </button>
                                            </div>
                                            <div className="mb-2">
                                                <span className="text-muted">Released: <span className="text-light font-weight-bold">{ this.props.album && this.props.album.length >0 ? moment(this.props.album[0].ReleaseDeals[0].start_date).format('ll') : '' }</span>
                                                </span>
                                                <span className="text-muted ml-3">Record Label: <span className="text-light font-weight-bold">{ this.props.album && this.props.album.length >0 ? this.props.album[0].label : '' }</span></span>
                                            </div>
                                            <div className="mb-2">
                                            
                                            </div>
                                        </div>
                                        <div className="py-3 py-sm-4 mx-sm-4">
                                            <div className="d-flex align-items-center">
                                                <div className="media w-56 circle">
                                                    <Link to={ this.props.album && this.props.album.length >0 && this.props.album[0].Artist && this.props.album[0].Artist.generated_id? `/artist/${ this.props.album[0].Artist.generated_id }`:''} className="media-content text-secondary" style={this.props.album && this.props.album.length >0 && this.props.album[0].Artist && this.props.album[0].Artist.artist_image !== ''? { backgroundImage: "url(" + this.props.album[0].Artist.artist_image + ")"}:{}}>
                                                        {
                                                            this.props.album.length >0 && this.props.album[0].Artist && this.props.album[0].Artist.artist_image === ''? 
                                                            (<User size={24}/>)
                                                            :
                                                            ('') 
                                                        }
                                                    </Link>
                                                </div>
                                                <div className="px-3">  
                                                    <div className="text-sm text-muted">Artist</div>
                                                    {/* <Link to={ this.props.album.length >0 && this.props.album[0].Artist && this.props.album[0].Artist.generated_id? `/artist/${ this.props.album[0].Artist.generated_id }`:''} className="text-color ajax">{ this.props.album.length >0? this.props.album[0].display_artist_name:''}</Link> */}
                                                    {
                                                       this.props.album && this.props.album.length >0 && this.props.album[0].Artist?
                                                       <Link to={ this.props.album.length >0 && this.props.album[0].Artist && this.props.album[0].Artist.generated_id? `/artist/${ this.props.album[0].Artist.generated_id }`:''} className="text-color ajax">{ this.props.album.length >0? this.props.album[0].display_artist_name:''}</Link>
                                                       : this.props.album.length > 0 && this.props.album[0].Artist ?  this.props.album[0].Artist.artist_name: this.props.album.length > 0? (<DisplayArtists artist={ this.props.album[0].display_artist_name}/>): "#"
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="page-container padding">
                            <div className="">
                                <div className="py-4">
                                    <div className="heading py-2 d-flex ">
                                        <div>
                                            <div className="text-muted text-sm sr-item">Album</div>
                                            <h5 className="text-highlight sr-item">{ pluralize('Track',this.props.album.length) }</h5>
                                        </div>
                                        <span className="flex"></span>
                                    </div>
                                    <div className="row list-row list-index">
                                    {
                                        this.props.album.map((album) => {
                                        return <Track track={album} key={album.isrc} tracks={this.props.album} />
                                        })
                                    }
                                    </div>                               
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

Album.propTypes = {
    fetchAlbum: PropTypes.func.isRequired,
    album: PropTypes.array.isRequired
}
const mapStateToProps = state => {
    return {
      album: state.album.album,
      albumLoading: state.album.albumLoading,
    }
}

export default withRouter(connect(mapStateToProps, { fetchAlbum, setQueue, shareAlbum })(Album))
