import React, { Component } from 'react';
import Playlist from '../components/TopPlaylists/Playlist';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPlaylists, fetchLayerPlaylists, editorPlaylists, moodsPlaylists } from '../actions/playlist';
import TopPlaylist from '../components/TopPlaylists';

class Playlists extends Component {
    componentWillMount(){
        if(this.props.playlists.length === 0){
            if(!this.props.authenticated){
                this.props.fetchPlaylists();
            }
        }

        if(this.props.authenticated){
            // this.props.fetchLayerPlaylists();
            this.props.editorPlaylists();
            this.props.moodsPlaylists();
        }
    }
    render() {      
        return (
            <div id="content" className="flex">
                <div className="page-container">
                    <div className="padding sr" id="list" data-plugin="musicapp" data-page="50">
                        <div className="heading py-2 d-flex ">
                            <div>
                                <h1 className="text-highlight sr-item font-weight-bold">Playlists</h1>
                            </div>
                            <span className="flex"></span>
                        </div>
                        <div className="pos-rlt z-index-1">
                            <div className="d-flex py-2 sr-item">
                                <div className="flex"></div>
                            </div>
                        </div>
                        {
                            this.props.editor.constructor === Object && Object.entries(this.props.editor).length >0?
                            (
                                <div>
                                    <div className="heading py-2 d-flex">
                                        <div>
                                            <h2 className="text-highlight font-weight-bold">Editor's pick for you</h2>
                                        </div>
                                        <span className="flex"></span>
                                    </div>
                                    {/* <div className="row sr-item">
                                        {
                                            this.props.editor.LayerContents.map((playlist) => {
                                                return <TopPlaylist playlist={playlist} key={playlist.id}/>
                                            })
                                        }
                                    </div> */}
                                    <TopPlaylist playlists={this.props.editor.LayerContents} />
                                </div>
                            ):
                            ('')
                        }
                        {
                            this.props.moods.length >0?
                            (
                                <div className="heading py-2 mb-2 d-flex mt-5">
                                    <div>
                                        <h2 className="text-highlight font-weight-bold">Moods curated for you</h2>
                                    </div>
                                    <span className="flex"></span>
                                </div>
                            ):('')
                        }
                        
                        {
                            this.props.moods.map((mood) => {
                                return (
                                    <div>
                                        <div className="heading py-2 mb-2 d-flex">
                                            <div>
                                                <h4 className="text-highlight">{mood.mood}</h4>
                                            </div>
                                            <span className="flex"></span>
                                        </div>
                                        <TopPlaylist playlists={mood.playlists} type="mood"/>
                                    </div>
                                )
                            })
                        }

                        <div className="row sr-item">
                            {
                                !this.props.authenticated && this.props.playlists.length >0?
                                this.props.playlists.map(playlist => {
                                    return <Playlist playlist={playlist} key={playlist.generated_id}/>
                                }):('')
                            }
                        </div>
                        <div className="no-result hide">
                            <div className="p-5 text-center">
                                <h5>Nothing Found</h5>
                                <small>It seems we can’t find what you’re looking for.</small>
                            </div>
                        </div>
                        <div className="pagination pagination-sm mt-3 hide"></div>
                    </div>
                </div>
            </div>
        )
    }
}

Playlists.propTypes = {
    fetchPlaylists: PropTypes.func.isRequired,
    // songs: PropTypes.array.isRequired
}
const mapStateToProps = state => {
    return {
      playlists: state.playlist.playlists,
      authenticated: state.auth.authenticated,
      editor: state.playlist.editorPlaylists,
      moods: state.playlist.moodsPlaylists
    //   songs: state.playlist.songs
    }
}

export default connect(mapStateToProps,{ fetchPlaylists, fetchLayerPlaylists, editorPlaylists, moodsPlaylists })(Playlists)