import { SEARCH, SEARCHING } from "./types";
import axios from 'axios';
import store from '../store';

const user = store.getState().auth.user;


export const search = (query) => dispatch => {
	dispatch({
		type: SEARCHING
	});
	
	if(query){
		// make axios call to search api
		axios.post(`/api/search/${query}`,{
			territory_code: user?.Country?.alpha_2? user.Country.alpha_2: store.getState().user.location,
			subscription: store.getState().wallet.subscription
		})
		.then(response => {
			dispatch({
				type:SEARCH,
				payload: response.data.data
			});
		})
		.catch((error)=>{
			console.log(error)
		});
	}else{
		dispatch({
			type:SEARCH,
			payload: []
		});
	}
	
}