import React from 'react';  
import { Route } from 'react-router-dom'; 
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import AddToPlaylist from '../../components/TopPlaylists/AddToPlaylist';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ShareModal from '../../components/Player/ShareModal';

const MainLayoutRoute = ({component: Component, ...rest}) => {
  const authenticated = useSelector(state => state.auth.authenticated);
  const themeMode = useSelector(state => state.user.userThemeMode);
  return (  
    <Route {...rest} render={matchProps => (  
      <div className={`App layout-row ${themeMode === 'dark'?'bg-dark':''}`}>
        <Sidebar />
        <div id="main" className="layout-column flex bg-white">
	        <Header />
          {
            authenticated===false?
            (''
              // <div className="alert alert-secondary font-weight-bold" role="alert">
              //   <AlertCircle size={20} />
              //   <span className="mx-2">Songs restricted to 30 seconds, <Link to="/login">login</Link> or <Link to="/register">register</Link> to experience full Entertainment</span>
              // </div>
            )
            :
            (
              ''
            )
          }
	         
	        <Component {...matchProps} />
	        <Footer />
          <ToastContainer 
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <ShareModal />
          <AddToPlaylist />
        </div>
      </div>
    )} />  
  )  
};

export default MainLayoutRoute;