import React, { Component } from 'react';
import TopDiscover from '../components/TopDiscover';
import TopTracks from '../components/TopTracks';
import SuggestedUsers from '../components/User/SuggestedUsers';
import TopArtists from '../components/Artist/TopArtists';
import TopPlaylists from '../components/TopPlaylists';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchLatestAlbums, fetchLatestSingles, fetchRandomAfro, fetchRecentlyPlayed, fetchLayers, fetchPodcasts, fetchAllLayers } from '../actions/album';
import { fetchTopPlaylists, fetchLayerPlaylists } from '../actions/playlist';
import { fetchSuggestedUsers } from '../actions/user';
import { getTopArtists } from '../actions/artist';

class Home extends Component {
  componentWillMount(){
    if(this.props.latestAlbums.length === 0)
      this.props.fetchLatestAlbums();    
    if(this.props.latestSingles.length === 0)
      this.props.fetchLatestSingles();
    if(this.props.randomAfro.length === 0)
      this.props.fetchRandomAfro();
    if(this.props.podcasts.length === 0)
      this.props.fetchPodcasts();
      
    // if user is authenticated
    if(this.props.authenticated){
      if(this.props.recentlyPlayed.length === 0)
        this.props.fetchRecentlyPlayed();
      if(this.props.layers.length === 0)
        this.props.fetchLayers();
      if(this.props.topPlaylists.length === 0)
        this.props.fetchLayerPlaylists();
      if(this.props.suggestedUsers.length === 0)
        this.props.fetchSuggestedUsers(this.props.user.generated_id);

        // this.props.fetchAllLayers();
      // if(this.props.topArtists.length === 0)
      //   this.props.getTopArtists();
    }else{
      if(this.props.topPlaylists.length === 0)
        this.props.fetchTopPlaylists();
    }
  }

  render() {
    return (
            <div id="content" className="flex">
              <div className="page-container">
                <div className="padding sr">  
                  {/* <TopDiscover /> */}
                  {/* <div className="mt-3">
                    <h3 className="text-highlight font-weight-bold"><Link to="/genres" className="text-decoration-none text-white">Explore Genres</Link></h3>
                  </div>
                  <Genres/> */}
                  {
                    this.props.recentlyPlayed.length >0?
                    (<React.Fragment>
                      <div className="heading py-2 d-flex ">
                        <div>
                          {/* <div className="text-muted text-sm"></div> */}
                          <h3 className="text-highlight font-weight-bold">Recently Played</h3>
                        </div>
                        <span className="flex"></span>
                      </div>
                      <TopTracks track={this.props.recentlyPlayed}/>
                    </React.Fragment>):''
                  }
                  
                  {
                    this.props.latestSingles.length >0?
                    (
                      <React.Fragment>
                        <div className="heading py-2 d-flex mt-5">
                          <div>
                            <h3 className="text-highlight font-weight-bold">Recently Released Singles</h3>
                          </div>
                          <span className="flex"></span>
                        </div>             
                        <TopTracks track={this.props.latestSingles} type="single"/>
                      </React.Fragment>
                    ):('')
                  }
                  
                  {
                    this.props.latestAlbums.length > 0?
                    (
                      <React.Fragment>
                        <div className="heading py-2 d-flex mt-5">
                          <div>
                            <h3 className="text-highlight font-weight-bold">Recently Released Albums</h3>
                          </div>
                          <span className="flex"></span>
                        </div>             
                        <TopTracks track={this.props.latestAlbums}/>
                      </React.Fragment>
                    ): ('')
                  }

                  {
                    this.props.authenticated && this.props.suggestedUsers.length >0?
                    ( <React.Fragment>
                        <div className="heading py-2 d-flex mt-5">
                          <div>
                            <h3 className="text-highlight font-weight-bold">Suggested Users</h3>
                          </div>
                          <span className="flex"></span>
                        </div>
                        <SuggestedUsers users={this.props.suggestedUsers}/>
                      </React.Fragment>
                    ): ('')
                  }


                  {
                    this.props.authenticated && this.props.topPlaylists.length > 0?
                      this.props.topPlaylists.map((layer) => { 
                        return (
                          <div key={layer.id}>
                            <div className="heading py-2 d-flex mt-5">
                              <div>
                                <h3 className="text-highlight font-weight-bold">{layer.title}</h3>
                              </div>
                              <span className="flex"></span>
                            </div>             
                            <TopPlaylists  playlists={ layer.LayerContents }/>
                          </div>)}):
                          (<div>
                            <div className="heading py-2 d-flex ">
                              <div>
                                <h3 className="text-highlight font-weight-bold">Top Playlists</h3>
                              </div>
                              <span className="flex"></span>
                            </div>             
                            <TopPlaylists  playlists={ this.props.topPlaylists }/>
                          </div>)
                  }    
                  
                  <div className="heading py-2 d-flex mt-5">
                    <div>
                      <h3 className="text-highlight font-weight-bold">Random AFRO</h3>
                    </div>
                    <span className="flex"></span>
                  </div>             
                  <TopTracks track={this.props.randomAfro}/>

                  {
                    this.props.authenticated && this.props.topArtists.length >0?
                    ( <React.Fragment>
                        <div className="heading py-2 d-flex mt-5">
                          <div>
                            <h3 className="text-highlight font-weight-bold">Top Artists</h3>
                          </div>
                          <span className="flex"></span>
                        </div>
                        <TopArtists artists={this.props.topArtists}/>
                      </React.Fragment>
                    ): ('')
                  }

                  <div className="heading py-2 d-flex mt-5">
                    <div>
                      <h3 className="text-highlight font-weight-bold">SoundTracks & Spoken Words</h3>
                    </div>
                    <span className="flex"></span>
                  </div>             
                  <TopTracks track={this.props.podcasts}/>
                  {
                    this.props.layers.length > 0 ?this.props.layers.map((layer) => {
                      return (
                      <div key={layer.id}>
                        <div className="heading py-2 d-flex mt-3">
                          <div>
                            <h3 className="text-highlight font-weight-bold">{ layer.title }</h3>
                          </div>
                          <span className="flex"></span>
                        </div>             
                        <TopTracks track={ layer.LayerContents }/>
                      </div>
                      )
                    }) : ''
                  }
                </div>
              </div>
            </div>
    );
  }
}

Home.propTypes = {
  fetchLatestAlbums: PropTypes.func.isRequired,
  fetchTopPlaylists: PropTypes.func.isRequired,
  fetchLatestSingles: PropTypes.func.isRequired,
  fetchRandomAfro: PropTypes.func.isRequired,
  latestAlbums: PropTypes.array.isRequired,
  latestSingles: PropTypes.array.isRequired,
  topPlaylists: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    latestAlbums: state.album.albums,
    latestSingles: state.album.singles,
    topPlaylists: state.playlist.playlists,
    randomAfro: state.album.randomAfro,
    recentlyPlayed: state.album.recentlyPlayed,
    layers: state.album.layers,
    podcasts: state.album.podcasts,
    authenticated: state.auth.authenticated,
    suggestedUsers: state.user.suggestedUsers,
    user: state.auth.user,
    topArtists: state.artist.topArtists
  }
}

export default connect(mapStateToProps,{ fetchLatestAlbums, fetchTopPlaylists, fetchLatestSingles, fetchRandomAfro, fetchRecentlyPlayed, fetchLayers, fetchPodcasts, fetchLayerPlaylists, fetchSuggestedUsers, getTopArtists, fetchAllLayers })(Home);
