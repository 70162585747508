import React from 'react';
import { Link } from 'react-router-dom';

export default function Album(props) {
    return (
       <div className="col-md-2 col-6" data-id={ props.album.id } data-category={ props.album.genre } data-tag={ props.album.territory_code }>
            <div className="list-item slick-item r mb-3">
                <div className="media">
                    <Link to={`/album/${props.album.album_id}`} className="media-content" style = {
                            {
                                backgroundImage: "url(" + props.album.artwork_file_path + ")"
                            }
                        }>
                    </Link>
                </div>
                <div className="list-content text-center">
                    <div className="list-body ">
                        <Link to={`/album/${props.album.album_id }`} className="list-title title ajax h-1x" data-location={`/album/${props.album.album_id}`}>
                            { props.album.release_title }
                        </Link>
                        <Link to={props.album.Artist? `/artist/${ props.album.Artist.generated_id }`: '#'} className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                            { props.album.display_artist_name }
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
