import React, { Component } from 'react';
import RavePaymentModal from 'react-ravepayment';
import { connect } from 'react-redux';
import  { Redirect } from 'react-router-dom';
import { getReference, generate } from '../../utils/util';
import { subscribe, addMoneyToWallet } from '../../actions/wallet';
import { toast } from 'react-toastify';
import axios from 'axios';

class Plan extends Component {
	constructor(props){
		super(props);
		this.state = {
			redirect: false,
			redirect_to:'/login?auth=required',
			amount: parseFloat(this.props.plan.amount),
			currency: this.props.plan.currency,
			email: this.props.user.email, // customer email,
		    key: "FLWPUBK-ce658de50f49fbf3eb23f15d088b9c6f-X", // Live
		    // --testPK-
		    // key: "FLWPUBK-a9bea12aeaf935ae158f5d5625c2efd2-X",
		    reference: 'MP-'+getReference(3)+generate(8)+getReference(2),
		    paymentPlanId: 0,
		}
		this.handleRedirect =  this.handleRedirect.bind(this);
		this.subscribeWithBalance =  this.subscribeWithBalance.bind(this);
		this.callback =  this.callback.bind(this);
		this.close =  this.close.bind(this);
	}

	componentWillMount(){
		// fetch flutterwave payment_plan code
		axios
		.post('/api/wallet/getpartnerplanid',{
			planID: this.props.plan.id
		})
		.then(response => {
			this.setState({
				paymentPlanId: parseInt(response.data.data.code)
			});
		})
		.catch(error => {
			console.log('Error: '+error);
		});
	}

	handleRedirect(location){
		this.setState({
			redirect: true,
			redirect_to: location,
		});
	}

	subscribeWithBalance(){
		this.props.subscribe(this.props.plan.id);
	}

	callback(response){
		if(response.data.status === 'success'){
			toast.success('payment successful. Redirecting shortly...');
			Promise
			.resolve(this.props.addMoneyToWallet(response.tx,'subscribe'))
			.then(response => {
				this.props.subscribe(this.props.plan.id);
				return response
			})
			.catch((error) => {
				toast.error('Oops subscription failed, contact our support');
			});
		}else{
			toast.error('Oops subscription failed, contact our support');
		}
		
	}

	close(){
		toast.error('payment was cancelled!')
	}

	render() {
		if(this.state.redirect){
			return (<Redirect to={this.state.redirect_to}/>);
		}

		return (
			<div className="p-4 p-sm-5" style={{border: '1px solid #ccc'}}>
                <sup className="text-sm" style={{top: '-0.5em'}}>{ this.props.plan.currency }</sup><span className="h1">{ this.props.plan.amount}</span>
                <div className="text-muted">{ this.props.plan.plan_name }</div>
                <div className="py-4">
                    {
                    	this.props.authenticated && this.props.wallet !== null && this.props.wallet.balance < this.props.plan.amount ?
	                    	(<RavePaymentModal
								text="Subscribe"
								class="btn btn-rounded btn-primary"
								metadata={[{metaname:'Web Payment', metavalue : ''}]}
								reference={this.state.reference}
								email={this.state.email}
								amount={this.state.amount}
								custom_title={'MePlaylist'}
								custom_logo={'https://www.meplaylist.com/img/favicon.ico'}
								currency={this.state.currency}
								payment_plan={this.state.paymentPlanId}
								payment_options="card"
								ravePubKey={this.state.key}								
								callback={this.callback}
								close={this.close}
								isProduction={true}
								tag="button"
							/>) 
						: 
						(
							this.props.authenticated && this.props.wallet !== null ?
							(<button className="btn btn-rounded btn-primary" onClick={this.subscribeWithBalance}>Subscribe</button>)
							:
							(this.props.wallet === null ? (<button className="btn btn-rounded btn-primary" onClick={ () => this.handleRedirect('/wallet')}>Subscribe</button>) :(<button className="btn btn-rounded btn-primary" onClick={() => this.handleRedirect('/login?auth=required')}>Subscribe</button>))
						)
                    }                    
                </div>
            </div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authenticated: state.auth.authenticated,
		user: state.auth.user,
		wallet: state.wallet.wallet,
	}
}
export default connect(mapStateToProps,{ subscribe, addMoneyToWallet })(Plan);