import {
    UPDATE_QUEUE,
    ADD_TO_QUEUE
} from '../actions/types';

const initialState = {
    queue:[],
    playing:{},
    next:{}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_QUEUE:
            // global.plyr.init();
            // clear current queue stack
            return {
                ...state,
                queue: action.payload
            }
        case ADD_TO_QUEUE:
            //get add to current queue array
            let currentQueue = state.queue;
            currentQueue.push(action.payload);
            return {
                ...state,
                queue: currentQueue
            }
        default:
            return state;
    }
}
