import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createPlaylist, fetchMoods } from '../../actions/playlist';

class CreatePlaylist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            mood: 0,
        }
        this.handleCreatePlaylist = this.handleCreatePlaylist.bind(this);
    }
    componentWillMount(){
        if(this.props.moods.length === 0)
            this.props.fetchMoods();
    }

    handleCreatePlaylist(){
        this.props.createPlaylist(this.state.title,this.state.mood);
    }

    render() {
        return (
            <div
            className="modal bg-dark"            
            id="createPlaylist" data-backdrop="false">
                <div className="modal-dialog">
                    <div className="modal-content text-light">
                        <div className="modal-header justify-content-center pb-0">
                            <h3 className="modal-title text-center text-dark">CREATE A PLAYLIST</h3>
                            {/* <button type="button" className="close" onClick={this.handleModalCloseRequest}>
                                <span aria-hidden="true">&times;</span>
                                <span className="sr-only">Close</span>
                            </button> */}
                        </div>
                        <div className="modal-body ">
                            <div className="row list mb-3 text-dark">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input className="form-control" placeholder="What is your playlist name" onChange={(e) => this.setState({title: e.target.value}) }/>
                                         <small id="nameHelp" className="form-text text-muted">Give your playlist any name that tickles your fancy</small>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <select className="form-control" onChange={(e) => this.setState({mood: parseInt(e.target.value) }) }>
                                            {
                                                this.props.moods.map((mood) => {
                                                    return (<option key={mood.id} value={mood.id}>{mood.name}</option>)
                                                })
                                            }
                                        </select>
                                         <small id="nameHelp" className="form-text text-muted">Choose a MOOD that best suits your playlist</small>
                                    </div>
                                </div>
                                {/*<div className="col-md-12">
                                    <div className="form-group">
                                        <textarea className="form-control" placeholder="Describe your playlist" onChange={(e) => this.setState({description: e.target.value}) }></textarea>
                                        <small id="descriptionHelp" className="form-text text-muted">Description should not be more than 100 words</small>
                                    </div>
                                </div> */}
                            </div>                            
                        </div>
                        <div className="modal-footer row justify-content-center">
                            <button type="button" className="btn btn-outline-dark py-2 px-4 rounded-pill" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-dark py-2 px-4 rounded-pill" onClick={this.handleCreatePlaylist}>Create</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) =>{
    return {
        moods: state.playlist.moods
    }
}

export default connect(mapStateToProps,{ createPlaylist, fetchMoods })(CreatePlaylist);
