import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { List } from 'react-feather';

const SinglePlaylist = (props) => {
    const [src, setImageSRC] = useState(null);

    useEffect(() => {
        const src = props.playlist.Playlist !== undefined && props.playlist.Playlist.artwork_path && props.playlist.Playlist.artwork_path !== "" ?
            props.playlist.Playlist.artwork_path
            :(props.playlist.Playlist !== undefined && props.playlist.Playlist.artwork_path === "" && props.playlist.Playlist.PlaylistSongs !== undefined && props.playlist.Playlist.PlaylistSongs.length >0 ?
            props.playlist.Playlist.PlaylistSongs[0].Album.artwork_file_path
            :props.playlist.artwork_path)
        const imageLoader = new Image();
        imageLoader.src = src;

        imageLoader.onload = () => {
            setImageSRC(src);
        }
    });

    return (
        <div key={props.playlist.Playlist !== undefined? props.playlist.Playlist.generated_id :props.playlist.generated_id} className="col-12 pl-0 pl-md-1" data-id={ props.playlist.Playlist !== undefined? props.playlist.Playlist.generated_id :props.playlist.generated_id } data-category="Pop" data-tag={ props.playlist.Playlist !== undefined? props.playlist.Playlist.country:props.playlist.country }>
            <div className="list-item slick-item r mb-3">
                <div className="media ">
                    <Link to={props.playlist.Playlist !== undefined?`/playlist/${(props.playlist.Playlist.generated_id)}`:`/playlist/${(props.playlist.generated_id)}`} className="media-content " style={{ backgroundImage: "url(" + src + ")" }}>
                        {
                            !src?(<List size={45} className="text-white"/>): ('')
                        }
                    </Link>
                                
                </div>
                <div className="list-content text-center">
                    <div className="list-body ">
                        <Link to={props.playlist.Playlist !== undefined?`/playlist/${(props.playlist.Playlist.generated_id)}`:`/playlist/${(props.playlist.generated_id)}`} className="list-title title h-1x">
                            <b className="text-white"> { props.playlist.Playlist !== undefined?props.playlist.Playlist.title:props.playlist.title } </b>
                        </Link>
                                    
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SinglePlaylist;