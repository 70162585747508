import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Image from '../assets/images/wallet.jpg';
import Plans from '../components/Modals/Plans';
import WalletHistory from '../components/Wallet/WalletHistory';
import $ from 'jquery';
import { Award } from 'react-feather';
import { getWalletBalance, getUserFreeTrial, getUserCurrentSubscription } from '../actions/wallet';
import moment from 'moment';

class Wallet extends Component {
    constructor(props){
        super(props);
        this.state = {
            isOpen: false
        }
        this.showPlans = this.showPlans.bind(this);
    }

    componentWillMount(){
        $('#plan').modal('hide');
        this.props.getWalletBalance();
        this.props.getUserFreeTrial();
        this.props.getUserCurrentSubscription();
    }

    showPlans(){
        this.setState({
            isOpen: true
        });
        $('#plan').modal('show');
    }

    render() {
        if(this.props.isWalletLoading){
            return (
                <div className="loader">
                    <div className="loading">Loading&#8230;</div>
                </div>
            );
        }

        if(this.props.isFreeTrialActivated || this.props.user.done_trial){
            // console.log(this.props.subscription);
            return (
                    <div id="content" className="flex wallet">
                        <div className="page-container">
                            <div className="padding">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-info alert-dismissible fade show p-4 mb-4" role="alert">
                                            <div className="d-flex">
                                                <span className="w-40 avatar circle gd-info"><Award size={16}/></span>
                                                <div className="d-sm-flex">
                                                    <div className="mx-3 align-self-start">
                                                        <h6>Welcome back, <span className="font-weight-bold">{this.props.user.username}</span></h6>
                                                        {
                                                            this.props.isFreeTrialActivated && moment(this.props.subscription.subscription_enddate/1000).isAfter(moment())?
                                                            (<small>You are on free trial, your free trial ends in <span className="font-weight-bold">{ moment.unix(this.props.subscription.subscription_enddate/1000).fromNow() } time</span></small>)
                                                            : (<small>You are on the { this.props.subscription.subscription_plan } plan</small>)
                                                        }
                                                        
                                                    </div>
                                                    {/*<div className="mx-3">
                                                        <button className="btn btn-sm btn-rounded btn-outline-info my-2" data-target="#plan" data-toggle="modal">Subscribe Now</button>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-9 mx-auto">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="card">
                                                    <div className="card-header">Your balance</div>
                                                    <div className="card-body">
                                                        <div>
                                                            <span className="text-lg">{ this.props.wallet? this.props.wallet.currency: '$'}</span>
                                                            <span className="text-success text-lg"> { this.props.wallet? this.props.wallet.balance: 0 } </span>
                                                        </div>
                                                        <div className="py-3 text-sm text-muted">
                                                            <small>You can add money to your subscription wallet by cicking the button below</small>
                                                        </div>
                                                        <div>
                                                            <a href="" className="btn btn-sm btn-rounded bg-success-lt text-md font-weight-bold" onClick={this.showPlans} data-target="#plan" data-toggle="modal">Add Money</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="card">
                                                    <div className="card-header">Current Subscription 
                                                    {/*<button className="btn btn-sm btn-rounded bg-success-lt font-weight-bold" onClick={this.showPlans} data-target="#plan" data-toggle="modal">Subscribe</button> */}
                                                    </div>
                                                    <div className="card-body mb-md-4">
                                                        {/* <div id="chartist-gauge" className="pos-rlt ct-point-hover" style={{height: '142px'}}></div> */}
                                                        <p className="text-muted">You are currently on the <span className="font-weight-bold text-dark">{ 
                                                            this.props.subscription.subscription_plan 
                                                        } </span>Plan</p>
                                                        {
                                                            this.props.subscription.subscription_plan === 'free'?
                                                            (<div>
                                                                <p className="text-lg text-dark">Freemium</p>
                                                                <Link className="btn btn-rounded bg-dark-lt font-weight-bold" to="/plans">Go Premium</Link>
                                                            </div>)
                                                            :
                                                            
                                                            (
                                                                <div>
                                                                    <p className="text-muted"><span className="badge badge-dark font-weight-bold">Start Date: </span><span className="text-lg text-success"> { moment.unix(this.props.subscription.subscription_startdate/1000).format('ll')}</span></p>
                                                                    <p className="text-muted"><span className="badge badge-dark font-weight-bold">End Date: </span> <span className="text-lg text-success">{ moment.unix(this.props.subscription.subscription_enddate/1000).format('ll') }</span></p>
                                                                </div>
                                                            )
                                                        }
                                                        

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="card">
                                            <div className="p-3-4">
                                                <div className="d-flex mb-3">
                                                    <div>
                                                        <h6>Sale monitor</h6>
                                                        <small className="text-muted">Start from last week</small>
                                                    </div>
                                                    <span className="flex"></span>
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <input type="text" className="btn btn-sm text-muted text-sm w"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-2">
                                                    <div id="chartist-line-area" data-plugin="chartist" className="pos-rlt ct-point-hover" style={{height: '365px'}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12 mt-4">
                                        <h6 className="text-dark font-weight-bold">Wallet History</h6>
                                        <WalletHistory />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Plans isOpen={ this.state.isOpen } action="subscribe"/>
                    </div>
        );
        }else{
            return (
                    <div id="content" className="flex wallet" style={{background: 'rgb(243, 243, 243)'}}>
                        <div className="page-container">
                            <div className="padding">
                                <div className="row justify-content-center align-items-center flex-column py-5">
                                    <img src={Image} alt={""} style={{width: '350px'}}/>
                                    <p className="text-lg text-success">Please start your 7days free trial to activate wallet</p>
                                    <button className="btn btn-dark py-3 px-3 shadow  font-weight-bold rounded-0 text-md" onClick={this.showPlans} data-target="#plan" data-toggle="modal">Start Free Trial</button>
                                </div>
                            </div>
                        </div>
                        <Plans isOpen={ this.state.isOpen } action="free trial"/>
                    </div>
            ) 
        }
        
    }
}

const mapStateToProps = (state) =>{
    return {
        user: state.auth.user,
        wallet: state.wallet.wallet,
        isFreeTrialActivated: state.wallet.isFreeTrialActivated,
        subscription: state.wallet.subscription,
        isWalletLoading: state.wallet.isWalletLoading,
    }
}

export default connect(mapStateToProps,{ getWalletBalance, getUserFreeTrial, getUserCurrentSubscription })(Wallet);
