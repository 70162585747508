import React, { Component } from 'react';
import { ChevronRight, Lock, User } from 'react-feather';
import { connect } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
import { uploadUserAvatar } from '../actions/auth';
import { updateUserInfo, changeGDPRSettings } from '../actions/user';
import { toast } from 'react-toastify';
import axios from 'axios';
import { sha256 } from 'js-sha256';
import base64js from 'base64-js';
import ThemeMode from '../components/Util/ThemeMode';

class Settings extends Component {
    constructor(props){
        super(props);
        this.state = {
            options: [],
            country:[],
            firstname:this.props.user.firstname,
            lastname:this.props.user.lastname,
            gender:this.props.user.gender,
            username:this.props.user.username,
            dob:this.props.user.dob,
            address:this.props.user.address,
            city:this.props.user.city,
            post_code:this.props.user.post_code,
            userID: this.props.user.id,
            avatar: null,
            disabled: true,
            password: '',
            newPassword: '',
            confirmPassword: '',
            invalidPassword: false,
            updating: false,
            usernameAvailable: null,
            checkingUsername: false,
            checkText: '',
            checkStatus: '',
        }

        this.uploadAvatar =  this.uploadAvatar.bind(this);
        this.updateInfo =  this.updateInfo.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.checkUsernameAvailability = this.checkUsernameAvailability.bind(this);
    }

    uploadAvatar(e){
        e.preventDefault();
        const form = new FormData();
        form.append('avatar',this.state.avatar);
        form.append('userID',this.state.userID);
        // call store action for uploading user avatar
        this.props.uploadUserAvatar(form);
    }

    updateInfo(e){
        e.preventDefault();
        this.props.updateUserInfo(this.state);
    }

    changePassword(e){
        e.preventDefault();
        this.setState({
            updating: true
        })
        if(this.state.newPassword !== this.state.confirmPassword){
            this.setState({
                invalidPassword: true
            });
        }else{
            var salt = '330-0@03-2-@((#@#_@--2rfninv9mf ot--4ii8';
            var passwordHash = sha256.digest(salt+''+this.state.password);
            var newPasswordHash = sha256.digest(salt+''+this.state.newPassword);
            var passwordEncode = base64js.fromByteArray(passwordHash);
            var newPasswordEncode = base64js.fromByteArray(newPasswordHash);
            axios
            .post('/api/auth/password/change',{
                generatedID: this.props.user.generated_id,
                password: passwordEncode,
                newPassword: newPasswordEncode
            })
            .then(response => {
                this.setState({
                    updating: false
                });
                toast.success('Password changed successfully!')
            })
            .catch(error =>{
                if (error.response) {
                    toast.error(error.response.data.message);
                    this.setState({
                        updating: false
                    })
                }else{
                    toast.error('An Error occurred, try again later!')
                }
            });

        }
    }

    checkUsernameAvailability(e){
        if(e.target.value === this.props.user.username){
            this.setState({
                username: this.props.user.username,
                checkText: '',
                checkStatus: '',
                checkingUsername: false,
            });
        }else{
            this.setState({
                username: e.target.value,
                usernameAvailable: false,
                checkText: 'checking if username is available...',
                checkStatus: 'warning',
                checkingUsername: true,
            });
            axios
            .post('/api/auth/checkusername',{
                username: e.target.value
            })
            .then(response => {
                if(response.data.data === false){
                    this.setState({
                        usernameAvailable: true,
                        checkText: 'username available 👍',
                        checkStatus: 'success',
                        checkingUsername: false,
                    });
                }else{
                    this.setState({
                        usernameAvailable: false,
                        checkText: 'username not available',
                        checkStatus: 'danger',
                    });
                }
            })
        }
    }

    render() {
        return (
             <div id="content" className="flex">
                <div>
                    <div className="page-hero page-container " id="page-hero">
                        <div className="padding d-flex justify-content-between">
                            <div className="page-title">
                                <h2 className="text-md text-highlight">Account Settings</h2>
                                <small className="text-muted">Configure your settings so we can serve you better</small>
                            </div>
                            
                            <ThemeMode />
                        </div>
                    </div>
                    <div className="page-content page-container" id="page-content">
                        <div className="padding">
                            <div id="accordion">
                                <p className="text-muted">
                                    <strong>Account</strong>
                                </p>
                                <div className="card">
                                    <div className="d-flex align-items-center px-4 py-3 pointer" data-toggle="collapse" data-parent="#accordion" data-target="#c_1">
                                        <div>
                                            <span className="w-48 avatar circle bg-info-lt" data-toggle-class="loading">
                                                <img src={ this.props.user.avatar !== '' && !this.props.user.avatar.includes('https://meplaylist.s3.amazonaws.com/profilepics/?')?this.props.user.avatar:'http://developer.meplaylist.com/assets/landing/images/favicon.ico' } alt="..."/>
                                            </span>
                                        </div>
                                        <div className="mx-3 d-none d-md-block">
                                            <strong>{ this.props.user.username }</strong>
                                            <div className="text-sm text-muted">{ this.props.user.email }</div>
                                        </div>
                                        <div className="flex"></div>
                                        <div className="">
                                            <ChevronRight size={16}/>
                                        </div>
                                    </div>
                                    <div className="collapse p-4" id="c_1">
                                        <form enctype="multipart/form-data" onSubmit={this.uploadAvatar}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Profile picture</label>
                                                        <div className="custom-file">
                                                            <input type="file" name="avatar" className="custom-file-input" id="customFile" onChange={(e) => this.setState({ avatar: e.target.files[0]})}/>
                                                            <label className="custom-file-label">Choose file</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.props.uploading?
                                                (<button type="submit" className="btn btn-primary mt-2" disabled>Upload...</button>)
                                                :
                                                (<button type="submit" className="btn btn-primary mt-2">Upload</button>)
                                            }
                                            
                                        </form>
                                    </div>
                                    <div className="d-flex align-items-center px-4 py-3 pointer" data-toggle="collapse" data-parent="#accordion" data-target="#c_2">
                                        <User size={16}/>
                                        <div className="px-3">
                                            <div>Personal Information</div>
                                        </div>
                                        <div className="flex"></div>
                                        <div>
                                            <ChevronRight size={16}/>
                                        </div>                                      
                                    </div>
                                    <div className="collapse p-4" id="c_2">
                                        <form onSubmit={this.updateInfo}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>First Name</label>
                                                        <input type="text" className="form-control" value={this.state.firstname} onChange={(e) => this.setState({firstname: e.target.value})}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Last Name</label>
                                                        <input type="text" className="form-control" value={this.state.lastname} onChange={(e) => this.setState({lastname: e.target.value})}/>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input type="text" className="form-control" value={this.props.user.email} disabled/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Username</label>
                                                        {
                                                            (<input type="text" className="form-control" value={ this.state.username }  onChange={(e) => this.checkUsernameAvailability(e)} />)
                                                        }
                                                        <span className={`mt-2 text-${this.state.checkStatus}`}>{ this.state.checkText}</span>
                                                        
                                                    </div>
                                                </div>
                                            </div>     
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Date Of Birth</label>
                                                        <input type="date" className="form-control" value={this.state.dob} onChange={(e) => this.setState({dob: e.target.value})}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Gender</label>
                                                        <input type="text" className="form-control" value={this.state.gender} onChange={(e) => this.setState({gender: e.target.value})}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Country</label>
                                                        {/*<input type="text" className="form-control" /> */}
                                                        <Typeahead
                                                            {...this.state}
                                                            id="country"
                                                            labelKey="name"
                                                            defaultSelected={[{name: this.props.user.Country.name}]}
                                                            options={this.state.options}
                                                            multiple      
                                                            onChange={(country)=>this.setState({country:country})}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Address</label>
                                                        <input type="text" className="form-control"  value={this.state.address} onChange={(e) => this.setState({address: e.target.value})}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>City</label>
                                                        <input type="text" className="form-control" value={this.state.city} onChange={(e) => this.setState({city: e.target.value})}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>PostCode</label>
                                                        <input type="text" className="form-control" value={this.state.post_code} onChange={(e) => this.setState({post_code: e.target.value})}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.props.updating || this.state.checkingUsername ?(<button type="button" className="btn btn-primary mt-2" disabled>Updating...</button>):(<button type="submit" className="btn btn-primary mt-2">Update</button>)
                                            }
                                            
                                        </form>
                                    </div>
                                    <div className="d-flex align-items-center px-4 py-3 b-t pointer" data-toggle="collapse" data-parent="#accordion" data-target="#c_3">
                                        <Lock size={16}/>
                                        <div className="px-3">
                                            <div>Password</div>
                                        </div>
                                        <div className="flex"></div>
                                        <div>
                                            <ChevronRight size={16}/>
                                        </div>
                                    </div>
                                    <div className="collapse p-4" id="c_3">
                                        <form onSubmit={this.changePassword}>
                                            <div className="form-group">
                                                <label>Old Password</label>
                                                <input type="password" className="form-control" onChange={(e) => this.setState({password: e.target.value})}/>
                                            </div>
                                            <div className="form-group">
                                                <label>New Password</label>
                                                <input type="password" className="form-control" onChange={(e) => this.setState({newPassword: e.target.value})}/>
                                                
                                            </div>
                                            <div className="form-group">
                                                <label>New Password Again</label>
                                                <input type="password" className="form-control" onChange={(e) => this.setState({confirmPassword: e.target.value,invalidPassword: false})}/>
                                                {
                                                    this.state.invalidPassword?
                                                    (<span className="text-danger font-weight-bold">Password does not match!</span>): ('')
                                                }
                                            </div>
                                            {
                                                this.state.updating?
                                                (<button type="submit" className="btn btn-primary mt-2" disabled>Update</button>)
                                                : (<button type="submit" className="btn btn-primary mt-2">Update</button>)
                                            }
                                            
                                        </form>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between text-white">
                                    <p className="text-muted">
                                        <strong>Privacy</strong>
                                    </p>
                                    {/* <p className="text-muted pr-4">
                                        <span className="pr-5"><strong>Email</strong></span>
                                        <span><strong>Push</strong></span>
                                    </p> */}
                                </div>
                                <div className="card">
                                    <div className="d-flex align-items-center px-4 py-3">
                                        <div>
                                            <p className="h5">GDPR (General Data Protection Regulation)</p>
                                            <p className="mb-3">You can control how MePlaylist uses your data to process ads we send to you</p>
                                            <p className="mb-0 font-weight-bold">MePlaylist Ads</p>
                                            <small className="text-muted">You will always hear audio adverts based on our freemium policy. If this setting is enabled, it means you have withdrawn consent from MePlaylist to use your data to process ads we serve you.</small>
                                        </div>
                                        <div className="flex"></div>
                                        <span className="">
                                            <label className="ui-switch ui-switch-md">
                                                {
                                                    this.props.gdpr?
                                                    <input type="checkbox" checked onClick={() => this.props.changeGDPRSettings(false)}/> :
                                                    <input type="checkbox" onClick={() => this.props.changeGDPRSettings(true)}/> 
                                                }
                                                <i></i>
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                {/* <div className="d-flex justify-content-between text-white">
                                    <p className="text-muted">
                                        <strong>Sharing and Permission</strong>
                                    </p>
                                    <p className="text-muted pr-4">
                                        <span className="pr-5"><strong>Email</strong></span>
                                        <span><strong>Push</strong></span>
                                    </p>
                                </div>
                                <div className="card">
                                    <div className="d-flex align-items-center px-4 py-3">
                                        <div>
                                            <p className="mb-0">Playlist Updates</p>
                                            <small className="text-muted">You get an update when a user you follow creates or updates a playlist</small>
                                        </div>
                                        <div className="flex"></div>
                                        <span className="pr-5">
                                            <label className="ui-switch ui-switch-md">
                                                <input type="checkbox"/>
                                                <i></i>
                                            </label>
                                        </span>
                                        <span>
                                            <label className="ui-switch ui-switch-md">
                                                <input type="checkbox"/>
                                                <i></i>
                                            </label>
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center px-4 py-3">
                                        <div>
                                            <p className="mb-0">Artist Updates</p>
                                             <small className="text-muted">You get an update on new artists</small>
                                        </div>
                                        <div className="flex"></div>
                                        <span className="pr-5">
                                            <label className="ui-switch ui-switch-md">
                                                <input type="checkbox" checked/>
                                                <i></i>
                                            </label>
                                        </span>
                                        <span>
                                            <label className="ui-switch ui-switch-md">
                                                <input type="checkbox"/>
                                                <i></i>
                                            </label>
                                        </span>
                                    </div>  
                                    <div className="d-flex align-items-center px-4 py-3">
                                        <div>
                                            <p className="mb-0">New Music</p>
                                            <small className="text-muted">You get an update when an artist you follow has a new release</small>
                                        </div>
                                        <div className="flex"></div>
                                        <span className="pr-5">
                                            <label className="ui-switch ui-switch-md">
                                                <input type="checkbox"/>
                                                <i></i>
                                            </label>
                                        </span>
                                        <span>
                                            <label className="ui-switch ui-switch-md">
                                                <input type="checkbox" checked/>
                                                <i></i>
                                            </label>
                                        </span>
                                    </div> 
                                    <div className="d-flex align-items-center px-4 py-3">
                                        <div>
                                            <p className="mb-0">News and Promotions</p>
                                            <small className="text-muted">You get an update on MePlaylist news and all our offers</small>
                                        </div>
                                        <div className="flex"></div>
                                        <span className="pr-5">
                                            <label className="ui-switch ui-switch-md">
                                                <input type="checkbox"/>
                                                <i></i>
                                            </label>
                                        </span>
                                        <span>
                                            <label className="ui-switch ui-switch-md">
                                                <input type="checkbox" checked/>
                                                <i></i>
                                            </label>
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center px-4 py-5">
                                        <div>
                                            <p className="mb-0">Facebook</p>
                                            <small className="text-muted">Link your facebook Account</small>
                                        </div>
                                        <div className="flex"></div>
                                        <span className="pr-5">
                                            <label className="ui-switch ui-switch-md">
                                                <input type="checkbox"/>
                                                <i></i>
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <p className="text-muted">
                                    <strong>Security</strong>
                                </p>
                                <div className="card">
                                    <div className="d-flex align-items-center px-4 py-3 b-t pointer" data-toggle="collapse" data-parent="#accordion" data-target="#c_5">
                                        <div>Delete account?</div>
                                        <div className="flex"></div>
                                        <div>
                                            <ChevronRight size={16}/>
                                        </div>
                                    </div>
                                    <div className="collapse p-4" id="c_5">
                                        <div className="py-3">
                                            <p>Are you sure to delete your account?</p>
                                            <button type="button" className="btn btn-white">No</button>
                                            <button type="button" className="btn btn-danger">Yes</button>
                                        </div>
                                    </div>
                                </div>*/}
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        userCountry: state.auth.userCountry,
        uploading: state.auth.uploading,
        updating: state.user.updating,
        gdpr: state.user.gdpr
    }
}
export default connect(mapStateToProps,{ uploadUserAvatar, updateUserInfo, changeGDPRSettings })(Settings);