export const FETCH_SONGS = 'FETCH_SONGS';
export const FETCH_PLAYLISTS = 'FETCH_PLAYLISTS';
export const FETCH_PLAYLIST = 'FETCH_PLAYLIST';
export const FETCH_TOP_PLAYLISTS = 'FETCH_TOP_PLAYLISTS';
export const FETCH_ALBUM_LOADING = 'FETCH_ALBUM_LOADING';
export const FETCH_ALBUM = 'FETCH_ALBUM';
export const FETCH_SINGLE = 'FETCH_SINGLE';
export const FETCH_LATEST_ALBUMS = 'FETCH_LATEST_ALBUMS';
export const FETCH_LATEST_SINGLES = 'FETCH_LATEST_SINGLES';
export const UPDATE_QUEUE = 'UPDATE_QUEUE';
export const ADD_TO_QUEUE = 'ADD_TO_QUEUE';
export const FETCH_ALBUMS = 'FETCH_ALBUMS';
export const FETCH_ALBUMS_LOADING = 'FETCH_ALBUMS_LOADING';
export const FETCH_ARTISTS = 'FETCH_ARTISTS';
export const FETCH_ARTIST = 'FETCH_ARTIST';
export const FETCH_RANDOM_AFRO = 'FETCH_RANDOM_AFRO';
export const FETCH_RECENTLY_PLAYED = 'FETCH_RECENTLY_PLAYED';
export const FETCH_LAYERS = 'FETCH_LAYERS';
export const FETCHING_LAYERS = 'FETCHING_LAYERS';
export const FETCH_ALL_LAYERS = 'FETCH_ALL_LAYERS';
export const FETCH_PODCASTS = 'FETCH_PODCASTS';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const GOOGLE_AUTH = 'GOOGLE_AUTH`';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SEARCH = 'SEARCH';
export const SEARCHING = 'SEARCHING';
export const FETCH_WALLET = 'FETCH_WALLET';
export const FETCH_WALLET_HISTORY = 'FETCH_WALLET_HISTORY';
export const ADD_MONEY_TO_WALLET = 'ADD_MONEY_TO_WALLET';
export const WALLET_PAYMENT_INVALID = 'WALLET_PAYMENT_INVALID';
export const WALLET_PAYMENT_ERROR = 'WALLET_PAYMENT_ERROR';
export const WALLET_PAYMENT_SUCCESS = 'WALLET_PAYMENT_SUCCESS';
export const FETCH_USER_FREE_TRIAL = 'ACTIVATE_USER_FREE_TRIAL';
export const ACTIVATE_FREE_TRIAL = 'ACTIVATE_FREE_TRIAL';
export const ACTIVATE_FREE_TRIAL_LOADING = 'ACTIVATE_FREE_TRIAL_LOADING';
export const ACTIVATE_FREE_TRIAL_ERROR = 'ACTIVATE_FREE_TRIAL_ERROR';
export const FETCH_USER_CURRENT_SUBSCRIPTION = 'FETCH_USER_CURRENT_SUBSCRIPTION';
export const FETCH_USER_PLAYLISTS = 'FETCH_USER_PLAYLISTS';
export const ADD_SONG_TO_PLAYLIST = 'ADD_SONG_TO_PLAYLIST';
export const ADD_TO_PLAYLIST_SONG = 'ADD_TO_PLAYLIST_SONG';
export const CREATE_PLAYLIST = 'CREATE_PLAYLIST';
export const EDIT_PLAYLIST = 'EDIT_PLAYLIST';
export const DELETE_PLAYLIST = 'DELETE_PLAYLIST';
export const FETCH_SONGS_BY_GENRE = 'FETCH_SONGS_BY_GENRE';
export const FETCH_PLANS = 'FETCH_PLANS';
export const SUBSCRIBE = 'SUBSCRIBE';
export const FETCH_MOODS = 'FETCH_MOODS';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_USER_COUNTRY = 'FETCH_USER_COUNTRY';
export const IMAGE_UPLOADING = 'IMAGE_UPLOADING';
export const IMAGE_UPLOADED = 'IMAGE_UPLOADED';
export const FETCH_USER = 'FETCH_USER';
export const FETCHING_USER = 'FETCHING_USER';
export const FETCH_SUGGESTED_USERS = 'FETCH_SUGGESTED_USERS';
export const FOLLOWING_LOADING = 'FOLLOWING_LOADING';
export const AUTH_FOLLOWERS_FOLLOWING = 'AUTH_FOLLOWERS_FOLLOWING';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATING_USER_INFO = 'UPDATING_USER_INFO';
export const FOLLOW_ARTIST = 'FOLLOW_ARTIST';
export const UNFOLLOW_ARTIST = 'UNFOLLOW_ARTIST';
export const FETCH_TOP_ARTISTS = 'FETCH_TOP_ARTISTS';
export const FETCHING_ARTIST = 'FETCHING_ARTIST';
export const LIKE_ALBUM = 'LIKE_ALBUM';
export const UNLIKE_ALBUM = 'UNLIKE_ALBUM';
export const LIKE_PLAYLIST = 'LIKE_PLAYLIST';
export const UNLIKE_PLAYLIST = 'UNLIKE_PLAYLIST';
export const FETCH_USER_LIKES = 'FETCH_USER_LIKES';
export const UPDATE_USER_LIKES = 'UPDATE_USER_LIKES';
export const UPDATE_USER_UNLIKE = 'UPDATE_USER_UNLIKE';
export const FETCHING_PLAYLIST = 'FETCHING_PLAYLIST';
export const FETCH_USER_FAVOURITES = 'FETCH_USER_FAVOURITES';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGING_PASSWORD = 'CHANGING_PASSWORD';
export const DELETE_PLAYLIST_SONG = 'DELETE_PLAYLIST_SONG';
export const SENDING_RESET_CODE = 'SENDING_RESET_CODE';
export const RESET_CODE_SENT = 'RESET_CODE_SENT';
export const FETCH_EDITOR_PLAYLISTS = 'FETCH_EDITOR_PLAYLISTS';
export const FETCH_MOODS_PLAYLISTS = 'FETCH_MOODS_PLAYLISTS';
export const FETCH_LOCATION_FROM_IP_ADDRESS = 'FETCH_LOCATION_FROM_IP_ADDRESS';
export const UPDATE_SHARE_PROPERTIES = 'UPDATE_SHARE_PROPERTIES';
export const FETCH_MOST_STREAMED_SONGS = 'FETCH_MOST_STREAMED_SONGS';
export const FETCH_MOST_STREAMED_LOADING = 'FETCH_MOST_STREAMED_LOADING';
export const FETCH_SIMILAR_ARTISTS = 'FETCH_SIMILAR_ARTISTS';
export const UPDATE_USER_THEME_MODE = 'UPDATE_USER_THEME_MODE';
export const FETCH_ARTIST_TOP_TRACKS = 'FETCH_ARTIST_TOP_TRACKS';
export const FETCH_ARTIST_PLAYLISTS = 'FETCH_ARTIST_PLAYLISTS';
export const FETCH_ARTIST_FOLLOWERS = 'FETCH_ARTIST_FOLLOWERS';
export const FETCH_ARTIST_USER_IS_FOLLOWING = 'FETCH_ARTIST_USER_IS_FOLLOWING';
export const UPDATE_GDPR = 'UPDATE_GDPR';
export const SAVE_NOTIFICATION_TOKEN = 'SAVE_NOTIFICATION_TOKEN';
