import React, { Component } from "react"
import { Heart, Headphones } from "react-feather";
import { Link } from 'react-router-dom';
import { cipher } from '../../utils/util';
import PlayerActions from '../Player/PlayerActions';
import LikeBtn from '../Album/LikeBtn';
import { connect } from 'react-redux';
import { UPDATE_QUEUE } from '../../actions/types';
import PropTypes from 'prop-types';
import TrackDuration from './TrackDuration';

class Track extends Component {
    constructor(props) {
        super(props);
        this.state = {
            liked: false
        }
        this.handleLike = this.handleLike.bind(this);
    }

    handleLike(){
        // dispatch action here
        this.setState(state => ({
            liked: !state.liked
        }));
    }

    render() {
        const encrypt = cipher('78sji3w9-2djadw2');
        return (
            <div className="col-12" data-id={ this.props.track.Album ? this.props.track.Album.isrc : this.props.track.isrc } data-category={ this.props.track.Album !== undefined ? this.props.track.Album.genre: this.props.track.genre } data-tag="USA" data-source={ this.props.track.Album !== undefined? encrypt(this.props.track.Album.audio_file_path_192): encrypt(this.props.track.audio_file_path_192) }>
                <div className="list-item r">
                    <div className="media ">
                        <Link to = {
                            this.props.track.Album !== undefined ? `/album/${this.props.track.Album.album_id}`:`/album/${this.props.track.album_id}`
                        }
                        className = "media-content text-light"
                         style={ this.props.isAlbum || this.props.isPlaylist? (this.props.track.Album?{backgroundImage: 'url("'+this.props.track.Album.artwork_file_path+'")'}:{backgroundImage: 'url("'+this.props.track.artwork_file_path+'")' }):{}}>
                            {
                                this.props.isAlbum || this.props.isPlaylist? ('') : (<Headphones size={24}/>)
                            }                            
                        </Link>
                        <div className="media-action media-action-overlay">
                            <button className="btn btn-icon no-bg no-shadow hide-row" onClick={this.handleLike}>
                                <Heart size={16} className="active-danger"/>
                            </button>
                            <button className="btn btn-raised btn-icon btn-rounded bg--white btn-play" onClick={() => this.props.updateQueue(this.props.tracks)}></button>
                        </div>
                    </div>
                    <div className="list-content text-center">
                        <div className="list-body ">
                            <Link to = {
                                this.props.track.Album !== undefined ? `/album/${this.props.track.Album.album_id}` : `/album/${this.props.track.album_id}`
                            }
                            className = "list-title title h-1x" data-location={ this.props.track.Album !== undefined ? `/album/${this.props.track.Album.album_id}` : `/album/${this.props.track.album_id}` } >
                                {
                                    this.props.track.Album !== undefined ? this.props.track.Album.resource_title : (this.props.isAlbum? this.props.track.release_title :this.props.track.resource_title)
                                }
                            </Link>
                            <Link to = {
                                this.props.track.Album !== undefined && this.props.track.Album.Artist !== undefined && this.props.track.Album.Artist !== null && this.props.track.Album.Artist.generated_id !== null ? `/artist/${ this.props.track.Album.Artist.generated_id }` :
                                (this.props.track.Artist !== undefined && this.props.track.Artist !== null && this.props.track.Artist.generated_id !== null ? `/artist/${ this.props.track.Artist.generated_id }`:'#')
                            }
                            className = "list-subtitle d-block text-muted subtitle h-1x" >
                                { (this.props.track.parental_warning_type!== undefined &&this.props.track.parental_warning_type === 'Explicit')||(this.props.track.Album !==undefined && this.props.track.Album.parental_warning_type === 'Explicit')?
                                    (
                                        <span className="badge badge-secondary text-dark mr-3" style={{backgroundColor: '#7b86a0'}}>E</span>
                                    ):''
                                }
                                { this.props.track.Album !== undefined? this.props.track.Album.display_artist_name:  this.props.track.display_artist_name }
                            </Link>
                        </div>
                    </div>
                    <div className="list-action  show-row">
                        <div className="d-flex align-items-center">
                            {/* <div className="px-3 text-sm d-none d-md-block">
                                { 
                                    this.props.track.Album !== undefined && !this.props.isAlbum? 
                                    
                                    this.props.track.Album.duration.substring(5, 7) + ':' + this.props.track.Album.duration.substring(8, 10) 
                                    :!this.props.isAlbum?
                                    this.props.track.duration.substring(5, 7) + ':' + this.props.track.duration.substring(8, 10):('')
                                } 
                            </div>                             */}
                            <TrackDuration track={this.props.track} isAlbum={this.props.isAlbum} />
                            <LikeBtn album={this.props.track.Album !== undefined ? this.props.track.Album : this.props.track}/>
                            {
                                this.props.playlistID?
                                (<PlayerActions songID={this.props.track.Album !== undefined ? this.props.track.Album.id : this.props.track.id } playlistID={this.props.playlistID} link={this.props.track.Album !== undefined ?`https://app.meplaylist.com/album/${this.props.track.Album.album_id}`:`https://app.meplaylist.com/album/${this.props.track.album_id}`} song={this.props.track.Album !== undefined ? this.props.track.Album : this.props.track } type="album"/>)
                                :
                                (<PlayerActions songID={this.props.track.Album !== undefined ? this.props.track.Album.id : this.props.track.id } link={this.props.track.Album !== undefined ?`https://app.meplaylist.com/album/${this.props.track.Album.album_id}`:`https://app.meplaylist.com/album/${this.props.track.album_id}`} song={this.props.track.Album !== undefined ? this.props.track.Album : this.props.track } type="album"/>)
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        )    
    }
}

Track.propTypes = {
    track: PropTypes.object.isRequired,
    tracks: PropTypes.array.isRequired,
}

const mapDispatchToProps = (dispatch,ownProps) => {
    return {        
      // dispatching plain actions
      updateQueue: () => {
          return dispatch({ type: UPDATE_QUEUE,payload:ownProps.tracks })
      }
    }
  }

export default connect(null,mapDispatchToProps)(Track);
