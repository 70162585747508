import * as actionTypes from './types';
import axios from 'axios';
import { sha256 } from 'js-sha256';
import base64js from 'base64-js';
import { toast } from 'react-toastify';
import { getCookie } from '../utils/util';
import Swal from 'sweetalert2';
import store from '../store';

var csrfToken = getCookie('csrf_token');


axios.defaults.headers.common = {
	'Content-Type': 'application/json;charset=utf-8',
	'Access-Control-Allow-Origin': '*',
	'x-csrf-token': csrfToken,
	'Authorization': store.getState().auth.token
}

export const handleLogin = ({ email, password, recaptchaToken }) => dispatch => {
	var salt = '330-0@03-2-@((#@#_@--2rfninv9mf ot--4ii8';
	var hash = sha256.digest(salt+''+password);
	// console.log(hash);
	var encode = base64js.fromByteArray(hash);

	// console.log(encode);
	dispatch({
		type: actionTypes.AUTH_LOADING
	});
	axios.post('/login',{
		email:email,
		password: encode,
		recaptchaToken: recaptchaToken
	})
	.then(response => {
		if(response.data.data){
			localStorage.setItem('user',window.btoa(JSON.stringify(response.data.data.user)));
			//user authentication token
			localStorage.setItem('__ekaeae',window.btoa(response.data.data.token));
			dispatch({
				type: actionTypes.LOGIN,
				payload: response.data.data
			});
			// toast.success('Welcome...',{ delay: 4000});
			//TODO: redirect to home page --
			window.location.replace('/playlists');
		}else{
			// console.log(response.data);
			dispatch({
				type: actionTypes.AUTH_ERROR,
			});
			return toast.error("Email or Password Incorrect");
		}	

	})
	.catch(error => {
		console.log(error);
	});

}

export const logout = () => dispatch => {
	//remove user object from localstorage
	localStorage.removeItem('user');
	localStorage.removeItem('__ekaeae');
	// dispatch to reducer
	dispatch({
		type: actionTypes.LOGOUT
	});
	window.location.replace('/');
}

export const googleAuth = (token) => dispatch => {
	// token is base64 encode user generated_id
	// axios
	axios.get('/api/auth/user/'+token)
	.then((response) => {
		dispatch({
			type: actionTypes.GOOGLE_AUTH
		});
		// save user data to local localStorage
		localStorage.setItem('user',window.btoa(JSON.stringify(response.data.data.user)));
		// redirect again to homepage
		return window.location.replace('/playlists');

	})
	.catch((error) => {
		console.log(error);
	});
}

export const updateDetails = (token,type,username,country) => dispatch => {
		axios
		.post('/api/auth/user/updatedetails',{
			token,
			type,
			username,
			country
		})
		.then(response => {
			if(type === 'google')
				dispatch(googleAuth(token));
			else
				dispatch(facebookAuth(token));
		})
		.catch((error) => {
			console.log(error);
		});
}

export const facebookAuth = (token) => dispatch => {
	// token is base64 encode user generated_id
	// axios
	axios.get('/api/auth/user/facebook/'+token)
	.then((response) => {
		dispatch({
			type: actionTypes.GOOGLE_AUTH
		});
		// save user data to local localStorage
		localStorage.setItem('user',window.btoa(JSON.stringify(response.data.data.user)));
		// redirect again to homepage
		return window.location.replace('/playlists');

	})
	.catch((error) => {
		console.log(error);
	});
}

export const handleSignUp = ({lastname,firstname,deviceSerialNo,gender,username,country,email,password, recaptchaToken}) => dispatch => {
	dispatch({
		type: actionTypes.AUTH_LOADING
	});
	gender = gender[0];
	country = country[0];

	// make axios call to server
	axios.post('/api/auth/register',{
		email,
		password,
		username,
		lastname,
		firstname,
		recaptchaToken,
		deviceSerialNo,
		gender: gender.name,
		location: country.name,
		iso: country.iso_3166_2,
		currency: country.currency,
		countryCode: country.alpha_2,
		signupChannel: 'default-web',
	})
	.then(response => {
		dispatch({
			type: actionTypes.REGISTER
		});
		window.location.replace('/login?from=registration');
	})
	.catch(error => {
		dispatch({
			type: actionTypes.AUTH_ERROR,
			payload: error
		});
		toast.error(error.response.data.message);
	});
	// dispatch action 
}

export const fetchCountries = () => dispatch => {
	axios
	.get(`/api/countries`)
    .then((response) => {
        dispatch({
        	type: actionTypes.FETCH_COUNTRIES,
        	payload: response.data.data.countries
        })
    })
    .catch((error)=>{
        console.log(error)
    });
}

export const fetchUserCountry = (country) => dispatch => {
	axios
	.post(`/api/user/country`,{
		country_code: country
	})
    .then((response) => {
        dispatch({
        	type: actionTypes.FETCH_USER_COUNTRY,
        	payload: response.data.data
        })
    })
    .catch((error)=>{
        console.log(error)
    });
}

export const uploadUserAvatar = (data) => dispatch => {
	dispatch({
		type: actionTypes.IMAGE_UPLOADING
	});
	axios
	.post('/api/user/upload-image',data)
	.then(response => {
		dispatch({
			type: actionTypes.IMAGE_UPLOADED,
			payload: response.data.data
		});
	})
	.catch(error => console.log(error));
}

export const fetchFollows = (userID) => dispatch => {
	axios
	.post('/api/user',{
		generatedID: userID
	})
	.then(response => {
		dispatch({
			type: actionTypes.AUTH_FOLLOWERS_FOLLOWING,
			payload: response.data.data
		});
		// console.log(response.data.data.Followed);
	})
	.catch((error)=>{
		console.log(error);
	});
}

export const fetchLikes = (id) => dispatch => {
	axios
	.post('/api/user/likes',{
		userID: id
	})
	.then(response => {
		dispatch({
			type: actionTypes.FETCH_USER_LIKES,
			payload: response.data.data
		});
	})
	.catch(error => {
		console.log('could not fetch resource at this time!');
	})
}

export const sendResetCode = (email) => dispatch => {
	dispatch({
		type: actionTypes.SENDING_RESET_CODE
	});
	axios
	.post('/api/auth/user/sendresetpasswordcode',{
		email
	})
	.then(response => {
		if(response.data.success){
			dispatch({
				type: actionTypes.RESET_CODE_SENT
			});
		}else{
			toast.error('Reset code could not be sent at this time, try again!');
		}
		
	})
	.catch(error => {
		console.log('could not fetch resource at this time!');
	})
}

/**
 * save notification token for user
 * 
 * @param {*} setting 
 */
export const saveNotificationToken = (token) => dispatch => {
	//persist token to DB
	axios
	.post('/api/v1/user/notification/token',{
		token
	})
	.then(response => {
		dispatch({
			type: actionTypes.SAVE_NOTIFICATION_TOKEN,
			payload: token
		});
	})
	.catch(error => console.log(error));
}


const hashPassword = (password) => {
	var salt = '330-0@03-2-@((#@#_@--2rfninv9mf ot--4ii8';
	var hash = sha256.digest(salt+''+password);
	return base64js.fromByteArray(hash);
}
