import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { activateFreeTrial } from '../../actions/wallet';
import { XCircle } from 'react-feather';
import RavePaymentModal from 'react-ravepayment';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { subscribe, addMoneyToWallet } from '../../actions/wallet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getReference, generate } from '../../utils/util';
import Swal from 'sweetalert2';


class Plans extends Component {
	constructor(props) {
	    super(props);
	 
	    this.state = {
	      modalIsOpen: this.props.isOpen,
	      amount: 0,
	      currency:'',
	      plans: [],
	      selectedPlan:{},
	      email: this.props.user.email, // customer email,
	      key: "FLWPUBK-ce658de50f49fbf3eb23f15d088b9c6f-X", 
	      // --testPK-
	    //   key:"FLWPUBK-a9bea12aeaf935ae158f5d5625c2efd2-X ",
		  reference: '',
		  plan: {}
	    };

	    this.handleInputChange = this.handleInputChange.bind(this);
	    this.activatePlan = this.activatePlan.bind(this);
	    this.addMoney = this.addMoney.bind(this);
	    // this.generate = this.generate.bind(this);
	    // this.getReference = this.getReference.bind(this);
	 }
	 
	
	handleInputChange(e){
		this.setState({
			amount: parseFloat(e.target.value),
			plan: this.state.plans.filter(plan => parseInt(plan.amount) === e.target.value)[0]
		});
		// var [plan] = this.state.plans.filter(plan => parseInt(plan.amount) ===this.state.amount);
		// this.setState({
		// 	currency: plan.currency 
		// });
		console.log(this.state.plan);

	}

	activatePlan(){
		// call redux action here
		// get selected plan
		const plan = this.state.plans.filter(plan => parseInt(plan.amount) ===this.state.amount);
		// object destructuring
		var [selectedPlan] = plan;
		// toast.success('Free Trial Activated');
		this.props.activateFreeTrial(selectedPlan);
	}

	addMoney(){

	}

	/**
	 * callback after flutterwave
	 * 
	 * @params Object response
	 */
	callback = (response) => {
		if(response.data.status === 'success'){
			Swal.fire({
				type: 'success',
				title: 'Payment Successful!',
				text: 'Redirecting you back to your wallet shortly...',
				showConfirmButton: false,
			});
			//check if user has an active subscription
			//If yes, add money to wallet for recurrent purposes
			//If no, add money and subscribe to plan with the money immediately
			if(this.props.subscription.subscription_plan !== "free"){
				this.props.addMoneyToWallet(response.tx,'wallet');
			}else{
				Promise
				.resolve(this.props.addMoneyToWallet(response.tx,'subscribe'))
				.then(response => {
					this.props.subscribe(this.state.plan.id);
					return response
				})
				.catch((error) => {
					toast.error('Oops subscription failed, contact our support');
				});
			}
 			
		}else{
			Swal.fire({
				type: 'error',
				title: 'Payment Failed!',
				text: 'Redirecting you back to your wallet shortly...',
				showConfirmButton: false,
				timer: 2000
			})
			.then(() => {
				window.location.replace('/wallet');
			});
		}
   	}
 
   	close = () => {
    	$('#plan').modal('hide');
 		toast.error('Payment was cancelled!!!');
   	}
 

	componentWillMount(){
		var reference = 'MP-'+getReference(3)+generate(8)+getReference(2);
		this.setState({
			reference: reference
		});

		// fetch all the subscription plans
		axios.get('/api/wallet/plans/'+this.props.user.Country.alpha_2)
		.then(response => {
			this.setState({
				plans: response.data.data.plans,
				amount: parseFloat(response.data.data.plans[0].amount),
				currency: this.props.user.Country.alpha_2 === 'GH'? 'GHS':response.data.data.plans[0].currency, //check if country is Ghana and make currency GHS - so flutterwave can recognise it - we rep as GHC,
				plan: response.data.data.plans[0]
			});
		})
		.catch(error => {
			toast.error('Cannot fetch plans for your country at this time, please reload');
		})
	}

	render() {
		if(this.state.plans.length >0){
			return (
			<div id="plan" className="modal fade">
		        <div className="modal-dialog">
		        	<div className="modal-content">
			 			<div className="modal-header">
			 				<h2 className="text-dark font-weight-bold">Select a subscription plan</h2>
					        <button onClick={this.closeModal} data-dismiss="modal" className="btn btn-link"><XCircle size={24}/></button>
			 			</div>				        
			          	<div className="modal-body">
							<div className="list list-row h4">
		                    	{
		                    		this.state.plans.map((plan) => {
		                    			return (
		                    				<div className="list-item " data-id="19" key={plan.id}>
											    <div>
											        <label className="ui-check m-0">
											        	<input type="radio" name="plan" value={plan.amount} checked={this.state.amount === parseFloat(plan.amount)} onChange={this.handleInputChange}/>
											            <i></i>
											        </label>
											    </div>
											    <div>
											    	<Link to="#">
											            <span className="w-24 avatar gd-dark">
													        W
													    </span>
											        </Link>
											    </div>
											    <div className="flex">
											        <Link to="#" className="item-author text-color "><span className="text-dark font-weight-bold">{`${plan.currency} ${plan.amount}`} </span> {plan.plan_name}</Link>
											    </div>
											</div>
		                    			)
		                    		})
		                    	}
		                        
		                    </div>
			          	</div>
	                    <div className="modal-footer">
	                    	{
	                    		this.props.action ==='free trial'?
	                        	(<button className="btn btn-dark btn-block rounded-0 shadow font-weight-bolder" onClick={this.activatePlan}>{
	                        		!this.props.isFreeTrialLoading?'Activate Free Trial':(<FontAwesomeIcon icon={faSpinner} size="lg" spin/>)

	                        	}</button>)
	                        	:this.props.action ==='subscribe'? (
	                        		<RavePaymentModal
						    		    text="Subscribe"
						    		    class="payButton btn btn-dark btn-block rounded-0 shadow font-weight-bolder"
						    		    metadata={[{metaname:'Device', metavalue : 'Web'}]}
						    		    reference={this.state.reference}
						    		    email={this.state.email}
						    		    amount={this.state.amount}
										currency={this.state.currency}
										custom_title={'MePlaylist'}
										custom_logo={'https://www.meplaylist.com/img/favicon.ico'}
						    		    ravePubKey={this.state.key}
						    		    callback={this.callback}
						    		    close={this.close}
						                isProduction={true}
						               	tag="button"
						    	    />): <button className="btn btn-dark btn-block rounded-0 shadow font-weight-bolder" onClick={this.addMoney}>Active Free Trial</button>
	                    	}
	                    </div>
                    </div>
                </div>
	      	</div>
		);
		}else{
			return ('');
		}
		
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		isFreeTrialLoading: state.wallet.isFreeTrialLoading,
		subscription: state.wallet.subscription,
	}
}

export default connect(mapStateToProps,{ activateFreeTrial, addMoneyToWallet, subscribe })(Plans);
