import { 
    FETCH_MOST_STREAMED_SONGS,
    FETCH_MOST_STREAMED_LOADING
} from './types';
import axios from 'axios';
import { toast } from 'react-toastify';
import store from '../store';
const user = store.getState().auth.user;

export const fetchMostStreamedSongs = () => dispatch => {
    dispatch({
        type: FETCH_MOST_STREAMED_LOADING
    });
    axios.post("/api/album/topchart",{
        location: user?.Country?.alpha_2? user.Country.alpha_2: store.getState().user.location
    })
    .then(response => {
        console.log(response.data);
        dispatch({  
            type: FETCH_MOST_STREAMED_SONGS,
            payload: response.data.data
        });
    })
    .catch(error =>{
        // console.log(error);
        toast.error(error);
    });
}