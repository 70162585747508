/**
 * remove duplicate objects in an array using a key value
 * @param {*} array 
 * @param {*} key 
 */
export const getUnique = (array,key) => {
    const unique = array
            .map(element => element[key])
            // store the keys of the unique objects
            .map((element,index,final) => final.indexOf(element) === index && index)
            // eliminate dead keys and store unique objects
            .filter(element => array[element]).map(element => array[element]);
    return unique; 
}

/**
 *Encrypt a string using a specific salt
 *@params string salt
 *@return string
*/

export const cipher = salt => {
    let textToChars = text => text.split('').map(c => c.charCodeAt(0))
    let byteHex = n => ("0" + Number(n).toString(16)).substr(-2)
    let applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code)    

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('')
}

/** 
 *Decrypt a string that was encrypted with a specific salt
 *@params string salt
 *@return string 
*/
export const decipher = salt => {
    let textToChars = text => text.split('').map(c => c.charCodeAt(0))
    let applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code)
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('')
}

export const getReference = (n) => {
        let text = "";
        // let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
 
        for( let i=0; i < n; i++ )
            text += possible.charAt(Math.floor(Math.random() * possible.length));
 
        return text;
    }
    
export const  generate = (n) => {
        var add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.   

        if ( n > max ) {
                return this.generate(max) + this.generate(n - max);
        }

        max        = Math.pow(10, n+add);
        var min    = max/10; // Math.pow(10, n) basically
        var number = Math.floor( Math.random() * (max - min + 1) ) + min;

        return ("" + number).substring(add); 
    }

export const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  return "";
}
