import React, { Component } from 'react';
import { cipher } from '../../utils/util';

class CurrentTrack extends Component {
    render() {
        const encrypt = cipher('78sji3w9-2djadw2');
        return (
            <div className="plyr-item" data-id={ this.props.track.Album? this.props.track.Album.isrc :this.props.track.isrc } data-type="audio" data-source={ this.props.track.Album !== undefined ? encrypt(this.props.track.Album.audio_file_path_192) : encrypt(this.props.track.audio_file_path_192) } data-poster={ this.props.track.Album? this.props.track.Album.artwork_file_path:this.props.track.artwork_file_path} data-location={ this.props.track.Album !== undefined ? `/album/${this.props.track.Album.album_id}` : `/album/${this.props.track.album_id}` }>
                <div className="plyr-item-poster">                    
                </div>
                <div className="flex">
                    <div className="plyr-item-title h-1x">{ this.props.track.Album? this.props.track.Album.resource_title :this.props.track.resource_title }</div>
                <div className="plyr-item-author text-sm text-fade">{ this.props.track.Album? this.props.track.Album.display_artist_name:this.props.track.display_artist_name }</div>
                </div>
                <button className="plyr-item-close close text">&times;</button>
            </div>
        )
    }
}
export default CurrentTrack;
