import firebase from 'firebase/app';
import "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBumF61duUH1Tnoh46tzv1mNa9zD8oPw0o",
    authDomain: "meplaylist-36e43.firebaseapp.com",
    databaseURL: "https://meplaylist-36e43.firebaseio.com/",
    projectId: "meplaylist-36e43",
    storageBucket: "meplaylist-36e43.appspot.com",
    messagingSenderId: "638507789257",
    appId: "1:638507789257:web:31f7b8e15b6c1460"
});

const messaging = initializedFirebaseApp.messaging();

export { messaging };