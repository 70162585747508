import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';
import { fetchUser } from '../actions/user';
import { User as Usser } from 'react-feather';
import FollowBtn from '../components/User/FollowBtn';


class User extends Component {
    componentWillMount(){
        this.props.fetchUser(this.props.match.params.id);
    }

    componentDidUpdate(prevProps){
        // if the location params change -- usually when a user search on the top bar
        if(this.props.location !== prevProps.location){
          this.props.fetchUser(this.props.match.params.id);
        }
    }
    render() {
        if(this.props.loading){
            return (
                    <div id="content" className="flex ">
                        <div className="page-container">
                            <div className="bg-light">
                                <div className="page-container padding">
                                    <div className="loader">
                                        <div className="loading">Loading&#8230;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    );
        }
        return (
            <div id="content" className="flex">
                <div className="page-container">
                    <div className="bg-light">
                        <div className="page-container padding">
                            <div className="d-sm-flex page-heading">
                                <div className="media circle w w-auto-xs mb-4">
                                    <div className="media-content" style={{ backgroundImage: "url('"+this.props.user.avatar+"')" }}>
                                        { this.props.user.avatar ==='' || this.props.user.avatar === null? (<Usser size={50}/>): ('')}
                                    </div>
                                </div>
                                <div className="d-md-flex flex">
                                    <div className="mx-sm-4 flex text-light">
                                        <h1 className="h4 font-weight-bold mb-0">{ this.props.user.username !== '' && this.props.user.username !== null ? this.props.user.username : `User-${this.props.user.generated_id}` }</h1>
                                        <div className="py-4 toolbar align-items-center">
                                            {
                                                this.props.authUser.id === this.props.user.id?(''):
                                                (<FollowBtn />)
                                            }
                                            
                                        </div>
                                        <div className="mb-2">
                                            <span className="d-inline">Followers:</span>
                                            <span className="badge badge-light text-dark ml-1">{ this.props.followers.length }</span>
                                            <span className="d-inline ml-3">Following:</span>
                                            <span className="badge badge-light text-dark ml-1">{ this.props.following.length }</span>
                                        </div>
                                        <div className="mb-2">
                                            <span className="d-inline">Country:</span>
                                            <span className="badge badge-light text-dark ml-1">{ this.props.user.country }</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-container padding">
                        <div className="d-md-flex">
                            <div className="flex">
                                <div className="d-flex">
                                    <ul className="nav nav-sm text-sm nav-pills nav-rounded py-4">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="#playlist" data-toggle="tab">{ pluralize('Playlist',this.props.playlists.length) } <span className="badge badge-pill badge-light">{this.props.playlists.length}</span></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#followers" data-toggle="tab">Followers <span className="badge badge-pill badge-light">{ this.props.followers.length }</span></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#following" data-toggle="tab">Following <span className="badge badge-pill badge-light">{ this.props.following.length }</span></a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="playlist">
                                        <div className="row list mb-4">
                                            {
                                                this.props.playlists.map(playlist => {
                                                    return (
                                                        <div className="col-4 col-sm-2" data-id="163144443" data-category="Electronic" data-tag="Norway" key={playlist.generated_id}>
                                                            <div className="list-item r">
                                                                    {
                                                                        playlist.artwork_path !==''?
                                                                        (<div className="media "><Link to={`/playlist/${(playlist.generated_id)}`} className="ajax media-content " style={{backgroundImage:"url('"+playlist.artwork_path+"')"}}>
                                                                        </Link></div>)
                                                                        : playlist.PlaylistSongs.length >0?
                                                                        (<div className="media "><Link to={`/playlist/${(playlist.generated_id)}`} className="ajax media-content " style={{backgroundImage:"url('"+playlist.PlaylistSongs[0].Album.artwork_file_path+"')"}}>
                                                                        </Link></div>):(<div className="media"></div>)
                                                                    }
                                                                <div className="list-content text-center">
                                                                    <div className="list-body ">
                                                                        <Link to={`/playlist/${(playlist.generated_id)}`} className="list-title title ajax h-1x">
                                                                            { playlist.title }
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="followers">
                                        <div className="row list media-circle">
                                            {
                                                this.props.followers.map(follower => {
                                                    return (
                                                        <div key={follower.Following.generated_id } className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id={follower.Following.generated_id } data-category="all">
                                                            <div className="list-item r">
                                                                <div className="media ">
                                                                    <Link to={`/user/${follower.Following.generated_id}`} className="media-content text-white" style={follower.Following.avatar!== ''?{backgroundImage:"url('"+follower.Following.avatar+"')"}:{}}>
                                                                        {
                                                                            follower.Following.avatar === ''?
                                                                            (<Usser size={40}/>):('')
                                                                        }
                                                                    </Link>
                                                                    <div className="media-action ">
                                                                    </div>
                                                                </div>
                                                                <div className="list-content text-center">
                                                                    <div className="list-body ">
                                                                        <Link to={`/user/${follower.Following.generated_id}`} className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                            { follower.Following.username !==''? follower.Following.username:`User-${follower.Following.generated_id}` }
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="following">
                                        <div className="row list media-circle">
                                        {
                                            this.props.following.map(following => {
                                                    return (
                                                        <div key={following.Followed.generated_id } className="col-4 col-sm-3 col-md-2-4 col-lg-2" data-id={following.Followed.generated_id } data-category="all">
                                                            <div className="list-item r">
                                                                <div className="media ">
                                                                    <Link to={`/user/${following.Followed.generated_id}`} className="media-content text-white" style={following.Followed.avatar!== ''?{backgroundImage:"url('"+following.Followed.avatar+"')"}:{}}>
                                                                        {
                                                                            following.Followed.avatar === ''?
                                                                            (<Usser size={40}/>):('')
                                                                        }
                                                                    </Link>
                                                                    <div className="media-action ">
                                                                    </div>
                                                                </div>
                                                                <div className="list-content text-center">
                                                                    <div className="list-body ">
                                                                        <Link to={`/user/${following.Followed.generated_id}`} className="list-subtitle d-block text-muted  subtitle ajax h-1x">
                                                                            { following.Followed.username !==''? following.Followed.username:`User-${following.Followed.generated_id}`}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        playlists: state.user.playlists,
        user: state.user.user,
        authUser: state.auth.user,
        following: state.user.following,
        followers: state.user.followers,
        loading: state.user.loading
    }
}
export default connect(mapStateToProps,{ fetchUser })(User);
