import { 
    FETCH_MOST_STREAMED_SONGS, 
    FETCH_MOST_STREAMED_LOADING 
} from '../actions/types';

const initialState = {
    isLoading: true,
    mostStreamedSongs: []
}

export default (state = initialState, action = {} ) => {
    switch (action.type) {
        case FETCH_MOST_STREAMED_SONGS:
            return {
                ...state,
                mostStreamedSongs: action.payload,
                isLoading: false
            } 
        case FETCH_MOST_STREAMED_LOADING:
            return {
                ...state,
                isLoading: true
            }  
        default:
            return state;
    }
}