import React, {Component} from 'react';
import { Headphones, Heart, User } from 'react-feather';
import Track from '../components/Track/Track';
import { connect } from 'react-redux';
import { fetchSingle } from '../actions/album';
import { setQueue } from '../actions/playlist';
import PropTypes from 'prop-types';
import { Link,withRouter } from 'react-router-dom';
import pluralize from 'pluralize';
import { cipher } from '../utils/util';
import Stream from '../components/Stream';
import DisplayArtists from '../components/Artist/DisplayArtists';
import moment from 'moment';

class Single extends Component {
  componentWillMount(){
    this.props.fetchSingle(this.props.match.params.id);
  }
  componentDidUpdate(prevProps){
    // if the location params change -- usually when a user search on the top bar
    if(this.props.location !== prevProps.location){
        this.props.fetchSingle(this.props.match.params.id);
    }
  }

  render(){
    const encrypt = cipher('78sji3w9-2djadw2');
    if(this.props.albumLoading){
        return (
                <div id="content" className="flex ">
                    <div className="page-container">
                        <div className="bg-light">
                            <div className="page-container padding">
                                <div className="loader">
                                    <div className="loading">Loading&#8230;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                );
    }

      return (
        <div id="content" className="flex">
                <div className="page-container">
                    <div className="bg-light">
                        <div className="page-container padding">
                            <div className="d-sm-flex page-heading" data-id={
                                      this.props.album.isrc 
                                    } data-source={
                                        Object.entries(this.props.album).length === 0 && this.props.album.constructor === Object?'':
                                      encrypt(this.props.album.audio_file_path_192)
                                    }>
                                <div className="media r w w-auto-xs mb-4">
                                    <div className="media-content" style={
                                      {  backgroundImage: "url(" + this.props.album.artwork_file_path + ")" }
                                    }></div>
                                </div>
                                <div className="d-md-flex flex">
                                    <div className="mx-sm-4 flex">
                                        <h1 className="h4 font-weight-bold mb-0 text-highlight title">{ this.props.album.release_title }</h1>
                                        <div className="py-4 toolbar align-items-center">
                                            {   Object.entries(this.props.album).length === 0 && this.props.album.constructor === Object? (''):(<button className="btn btn-play btn-icon btn-md btn-rounded btn-primary" onClick={() => this.props.setQueue([this.props.album])}></button>)}
                                            
                                            <Headphones size={20}/>
                                            { Object.entries(this.props.album).length === 0 && this.props.album.constructor === Object?<span className="text-muted">0</span>: <Stream id={ this.props.album.id } type="single"/>
                                                }
                                            <button className="btn no-bg text-white btn-icon btn-rounded" data-toggle-class>
                                                <i data-feather="heart" className="active-solid active-danger"></i>
                                                <Heart size={24} className="active-solid active-danger" style={{fill: 'white'}}/>
                                            </button>
                                            <span className="text-muted">{ this.props.album.Likes.length } { pluralize('Like',this.props.album.Likes.length) }</span>
                                            {/* <button className="btn no-bg text-muted btn-icon btn-rounded btn-more" data-toggle="dropdown">
                                                <MoreHorizontal size={20} />
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right"></div> */}
                                        </div>
                                        <div className="mb-4">
                                            <span className="text-muted">Genre: </span>
                                            <Link to={ `/album/genre/${ this.props.album.genre }`} className="text-color ajax">
                                              <span className="badge badge-light">{ this.props.album.genre }</span>
                                            </Link>
                                            {/* <a href="music.cat.html" className="text-color ajax">World</a> */}
                                        </div>
                                        <div className="mb-2">
                                            <span className="text-muted">Released: <span className="text-light font-weight-bold">{ moment(this.props.album.ReleaseDeals[0].start_date).format('ll') }</span></span>
                                            <span className="text-muted ml-3">Record Label: <span className="text-light font-weight-bold">{this.props.album.label }</span></span>
                                        </div>
                                    </div>
                                    <div className="py-3 py-sm-4 mx-sm-4">
                                        <div className="d-flex align-items-center">
                                            <div className="media w-56 circle">
                                                <Link to = {
                                                    this.props.album.Artist && this.props.album.Artist.generated_id?`/artist/${ this.props.album.Artist.generated_id }`:'#'
                                                }
                                                className = "media-content text-secondary" style={this.props.album.Artist && this.props.album.Artist.artist_image !== ''? { backgroundImage: "url(" + this.props.album.Artist.artist_image + ")"}:{}}>
                                                    {
                                                        this.props.album.Artist === null || this.props.album.Artist.artist_image === '' || this.props.album.Artist.artist_image === null? 
                                                        (<User size={24}/>)
                                                        :
                                                        ('') 
                                                    }
                                                </Link>
                                            </div>
                                            <div className="px-3">
                                                <div className="text-sm text-muted">Artist</div>
                                                {/* <Link to={
                                                    this.props.album.Artist?`/artist/${ this.props.album.Artist.generated_id }`:'#'
                                                } className="text-color ajax">{ this.props.album.display_artist_name}</Link> */}
                                                {
                                                    this.props.album && this.props.album.Artist?
                                                    <Link to={
                                                        this.props.album.Artist?`/artist/${ this.props.album.Artist.generated_id }`:'#'
                                                    } className="text-color ajax">{ this.props.album.display_artist_name}</Link>
                                                    : (<DisplayArtists artist={ this.props.album.display_artist_name}/>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-container padding">
                        <div className="">
                            <div className="py-4">
                                <div className="heading py-2 d-flex ">
                                    <div>
                                        <div className="text-muted text-sm sr-item">Album</div>
                                        <h5 className="text-highlight sr-item">{ pluralize('Track',this.props.album.length) }</h5>
                                    </div>
                                    <span className="flex"></span>
                                </div>
                                <div className="row list-row list-index">
                                    {
                                        Object.entries(this.props.album).length === 0 && this.props.album.constructor === Object?
                                         ('')
                                        : <Track track={this.props.album} tracks={[this.props.album]}/>
                                    }
                                  </div>                               
                              </div>                            
                        </div>
                    </div>
                </div>
            </div>
      );
  }
}

Single.propTypes = {
    fetchSingle: PropTypes.func.isRequired,
    album: PropTypes.object.isRequired
}
const mapStateToProps = state => {
    return {
      album: state.album.single,
      albumLoading: state.album.albumLoading,
    }
}

export default withRouter(connect(mapStateToProps,{ fetchSingle, setQueue })(Single))
