import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './views/Home';
import Playlist from './views/Playlist';
import Album from './views/Album';
import Albums from './views/Albums';
import Playlists from './views/Playlists';
import Genres from './views/Genres';
import GenreSongs from './views/GenreSongs';
import Artist from './views/Artist';
import Artists from './views/Artists';
import User from './views/User';
import Settings from './views/Settings';
import Login from './views/Login/index.js';
import Register from './views/Register';
import Forgot from './views/Forgot';
import Resetpassword from './views/Resetpassword';
import NotAvailable from './views/NotAvailable';
import Wallet from './views/Wallet';
import Single from './views/Single';
import Plans from './views/Plans';
import Favourites from './views/Favourites';
import Feeds from './components/Feeds/Feeds'
import CompleteRegisteration from './views/CompleteRegisteration';
//import topchart from views
import TopChart from './views/TopChart';
//saerch view
import SearchView from './views/Search';
import * as serviceWorker from './serviceWorker';
import "bootstrap/dist/js/bootstrap";
import "jquery";
import "popper.js";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import MainLayout from './views/layouts/MainLayout';
import AuthLayout from './views/layouts/AuthLayout';
import WalletLayout from './views/layouts/WalletLayout';

// ReactDOM.render(<App />, document.getElementById('root'));
const routing = (
  <Provider store={store}>
    <Router>
      {/* <div className="App layout-row">
        <Sidebar />
        <div id="main" className="layout-column flex bg-white">
        <Header />
        <div className="alert alert-secondary font-weight-bold" role="alert">
            <AlertCircle size={20} />
            <span className="mx-2">Songs restricted to 30 seconds, <Link to="/login">login</Link> or <Link to="/register">register</Link> to listen to full tracks</span>
        </div> */}
        <Switch>
            <MainLayout exact path="/" component={Home} />
            <MainLayout path="/album/:albumID" component={Album}/>
            <MainLayout path="/single/:id" component={Single}/>
            <MainLayout exact path="/albums" component={Albums}/>
            <MainLayout path="/playlist/:id" component={Playlist}/>
            <MainLayout path="/playlists" component={Playlists}/>
            <MainLayout path="/feeds" component={Feeds}/>
            {/* top chart */}
            <MainLayout path="/topchart" component={TopChart}/>
            {/*search view*/}
            <MainLayout path="/search/:query?" component={SearchView}/>
            <MainLayout path="/genres" component={Genres}/>
            <MainLayout path="/albums/genre/:genre" component={GenreSongs}/>
            <MainLayout path="/artists" component={Artists}/>
            <MainLayout path="/artist/:id" component={Artist}/>
            <MainLayout path="/user/:id" component={User}/>
            <MainLayout path="/settings" component={Settings}/>
            <MainLayout path="/plans" component={Plans}/>
            <MainLayout path="/favourites" component={Favourites}/>
            <AuthLayout path="/login" component={Login}/>
            <AuthLayout path="/register" component={Register}/>
            <AuthLayout path="/forgotpassword" component={Forgot}/>
            <AuthLayout path="/resetpassword" component={Resetpassword}/>
            <AuthLayout path="/notavailable" component={NotAvailable}/>
            <WalletLayout path="/wallet" component={Wallet}/>
            <Route path="/signup/complete" component={CompleteRegisteration}/>
        </Switch>
        {/* <Footer />
        </div>
      </div> */}
    </Router>
  </Provider>
)
ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
