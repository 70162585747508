import { SEARCH, SEARCHING } from '../actions/types';
import { isArray } from 'jquery';

const initialState = {
	loading: true,
	isEmpty:false,
	albums:[],
	artists: [],
	playlists:[],
	users:[],
	tracks:[]
}

export default function(state = initialState,action){
	switch(action.type){ 
		case SEARCH:
			// when search query is undefined the payload returns an empty array
			if(isArray(action.payload) || (action.payload.artists.length === 0 
				&& action.payload.playlists.length === 0 
				&& action.payload.albums.length === 0 
				&& action.payload.users.length === 0)){
				return{
					loading:false,
					isEmpty:true
				}
			}else{
				return {
					...state,
					loading:false,
					albums: action.payload.albums.filter(track => track.release_type === 'Album'),
					artists: action.payload.artists,
					playlists: action.payload.playlists,
					users: action.payload.users,
					tracks: action.payload.albums.filter(track => track.release_type === 'Single'),
					isEmpty: false
				}
			}

		case SEARCHING:
			return {
				...state,
				loading: true,
			}
		default:
		return state;
	}
}