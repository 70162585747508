import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getWalletHistory } from '../../actions/wallet';
import moment from 'moment';

class WalletHistory extends Component {
	componentWillMount(){
		this.props.getWalletHistory();
	}
	render() {
		let i = 1;
		if(this.props.history.length >0){
			return (
				<div className="table-responsive py-2">
	                <div id="toolbar">
	                </div>
	                <table id="datatable" className="table table-theme table-row v-middle" data-plugin="bootstrapTable" data-toolbar="#toolbar" data-search="true" data-search-align="left" data-show-export="true" data-show-columns="true" data-detail-view="false" data-pagination="true" data-page-list="[10, 25, 50, 100, ALL]">
	                        <thead>
	                            <tr>
	                                <th data-sortable="true" data-field="id">#</th>
	                                <th data-sortable="true" data-field="activity">Activity</th>
	                                <th data-sortable="true" data-field="type">Activity Type</th>
	                            	<th data-field="amount"><span className="d-sm-block">Amount</span></th>
	                            	<th data-field="currency"><span className="d-none d-sm-block">Currency</span></th>
	                                <th data-field="balance"><span className="d-none d-sm-block">Last Balance</span></th>
	                                <th data-field="current"><span className="d-none d-sm-block">Balance</span></th>
	                                <th data-field="created"><span className=" d-sm-block">Created</span></th>
	                                {/* <th className="d-none d-sm-block"></th> */}
	                            </tr>
	                        </thead>
	                        <tbody>
		                        {
		                        	this.props.history.map(history => {

		                        		return (
		                        			<tr className=" " data-id="12" key={history.id}>
				                                <td style={{minWidth:'30px',textAlign:'center'}}>
				                                    <small className="text-muted">{ i++ }</small>
				                                </td>
				                                <td>
				                                    { history.activity }
				                                </td>
				                                <td className="flex">
				                                    <div className="item-except text-muted text-sm h-1x">
				                                        <span className="badge badge-dark">{ history.activity_type }</span>
				                                    </div>
				                                </td>
				                                <td>
				                                    <span className="item-amount  d-sm-block text-sm ">
				                                        { history.trans_amount }
				                                    </span>
				                                </td>
				                                <td>
				                                    <span className="item-amount d-none d-sm-block text-sm [object Object]">
				                                        { history.trans_currency }
				                                	</span>
				                                </td>
				                                <td>
				                                    <span className="item-amount d-none d-sm-block text-sm [object Object]">
				                                        { history.last_balance }
				                                	</span>
				                                </td>
				                                <td>
				                                    <span className="item-amount d-none d-sm-block text-sm [object Object]">
				                                        { history.current_balance }
				                                	</span>
				                                </td>						 
				                                <td>
				                                    <span className="item-amount d-none d-sm-block text-sm [object Object]">
				                                        { moment(history.created).format('ll') }
				                                	</span>
				                                </td>
				                                {/* <td className="d-none d-sm-block"></td> */}
				                            </tr>
		                        		)
		                        	})
		                        }
	                        </tbody>
	                    </table>
	            	</div>
		);
		}else{
			return (
				<div className="my-4 text-center py-2">					
					<p className="text-lg font-weight-bold text-dark">No Wallet History Yet!</p>
				</div>
				)
		}
		
	}	
}

const mapStateToProps = (state) => {
	return {
		history: state.wallet.history
	}
}

export default connect(mapStateToProps,{ getWalletHistory })(WalletHistory);