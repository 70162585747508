import { combineReducers } from 'redux';
import playlist from './playlist';
import queue from './queue';
import album from './album';
import artist from './artist';
import auth from './auth';
import search from './search';
import wallet from './wallet';
import user from './user';
import topChart from './topChart';

const rootReducer = combineReducers({
    playlist,
    queue,
    album,
    artist,
    auth,
    search,
    wallet,
    user,
    topChart
});

export default rootReducer;