import React, {useState, useEffect} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import { User as Usser } from 'react-feather';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, width:50}}
        onClick={onClick}
      />
    );
}
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, width:50}}
        onClick={onClick}
      />
    );
  }

const sliderSettings = {
    slidesToShow: 8,
    slidesToScroll: 2,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    dots: false,
    rtl: false,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 6
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 6
            }
        },
        {
            breakpoint: 920,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 1,
                speed: 300,
            }
        }
    ]
};

const style = {
    visibility: 'visible',
    transform: 'none',
    opacity: 1,
    transition: 'none 0 s ease 0 s'
};

const User = (props) => {
    const [src, setImageSRC] = useState(null);

    useEffect(() => {
        const src = props.user.avatar;

        const imageLoader = new Image();
        imageLoader.src = src;

        imageLoader.onload = () => {
            setImageSRC(src);
        }
    });

    return (
        <div key={props.user.generated_id} className="col-12" data-id={ props.user.generated_id }>
            <div className="list-item r">
                <div className="media">
                    <Link to={`/user/${props.user.generated_id}`} className="media-content " style={{backgroundImage: 'url('+src+')'}}>
                        {
                            !src? (<Usser size={45} className="text-white"/>): ('')
                        }
                    </Link>
                    <div className="media-action">
                    </div>
                </div>
                <div className="list-content text-center">
                    <div className="list-body ">
                        <Link to={`/user/${props.user.generated_id}`} className="list-subtitle d-block text-muted  subtitle h-1x">
                            { props.user.username !== '' && props.user.username !== null? props.user.username: `User-${props.user.generated_id}` }
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

const SuggestedUsers = (props) => {
    return (
            <Slider {...sliderSettings} className="slick slick-visible slick-arrow-top row media-circle mb-3" style={style}>
                { props.users.map((user) => {
                    return (
                        <User user={user} key={user.generated_id}/>
                        );
                })}
            </Slider>
    )
}

export default SuggestedUsers;