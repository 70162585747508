import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editPlaylist, deletePlaylist, fetchMoods } from '../../actions/playlist';
import Swal from 'sweetalert2';

class EditPlaylist extends Component{
	constructor(props){
		super(props);
		this.state = {
			id: this.props.playlist.id,
			title: this.props.playlist.title,
			// description: this.props.playlist.description,
			mood: this.props.playlist.mood_id,
		}
		this.deletePlaylist = this.deletePlaylist.bind(this);
	}

	componentWillMount(){
		if(this.props.moods.length === 0)
            this.props.fetchMoods();
	}

	deletePlaylist(){
		Swal.fire({
		  title: 'Are you sure?',
		  text: "You won't be able to revert this!",
		  type: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
		  if (result.value) {
		    this.props.deletePlaylist(this.state.id);
		  }
		})
	}

	render() {
		return (
				<div id="editPlaylist" className="modal fade" data-backdrop="true" aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content h-100 no-radius"  style={{overflowY:'auto'}}>
                            <div className="modal-header ">
               	                <div className="modal-title text-md text-dark font-weight-bold">Edit Playlist</div>
                                <button className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                	<div className="col-12">
	                                	<div className="form-group text-dark">
	                                        <label>Change playlist title</label>
	                                        <input type="textarea" className="form-control" value={this.state.title} onChange={e => this.setState({title: e.target.value})}/>
	                                    </div>
                                	</div>
                                </div>
                                {/*<div className="row">
                                	<div className="col-12">
	                                    <div className="form-group text-dark">
	                                        <label>Tell us about your playlist</label>
	                                        <textarea className="form-control" placeholder="..." onChange={(e) => this.setState({ description: e.target.value })} value={ this.state.description}>
	                                        </textarea>
	                                    </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                	<div className="col-12">
	                                    <div className="form-group text-dark">
	                                        <label>Tell us about your playlist</label>
	                                        <select className="form-control" onChange={(e) => this.setState({mood: parseInt(e.target.value) }) }>
	                                            {
	                                                this.props.moods.map((mood) => {
	                                                    return (<option selected={this.state.mood === mood.id? 'true':''} key={mood.id} value={mood.id}>{mood.name}</option>)
	                                                })
	                                            }
	                                        </select>
	                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                            	<button type="button" className="btn red text-white" onClick={() => {this.deletePlaylist()}}>Delete this playlist</button>
                                <button type="button" className="btn btn-primary" onClick={() =>this.props.editPlaylist(this.state) }>Save Changes</button>             
                            </div>
                        </div>
                    </div>
                </div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		playlist: state.playlist.playlist,
		moods: state.playlist.moods,
	}
}

export default connect(mapStateToProps,{ editPlaylist, deletePlaylist, fetchMoods })(EditPlaylist);
