import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Track from '../Track';
import SingleTrack from '../Track/SingleTrack';

const sliderSettings = {
    slidesToShow: 8,
    slidesToScroll: 2,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    dots: false,
    rtl: false,
    arrows: false,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 6
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 920,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2.2,
                slidesToScroll: 1,
                speed: 300
            }
        }
    ]
};

const style = {
    visibility: 'visible',
    transform: 'none',
    opacity: 1,
    transition: 'none 0 s ease 0 s'
};


const TopTracks = (props) => {
    return (
            <Slider {...sliderSettings} className="slick slick-visible slick-arrow-right col mb-3 pl-0 left-align-slick" style={style}>
                { props.track.map((track) => {
                    if(props.type === 'single')
                        return  <SingleTrack key={track.id} track={track} tracks={props.track} />
                    return <Track key={track.id} track={track} tracks={props.track} />
                })}
            </Slider>
    )
}

export default TopTracks;