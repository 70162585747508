import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
import { fetchCountries, updateDetails } from '../actions/auth';
import axios from 'axios';

const search = window.location.search;
const params = new URLSearchParams(search);

class CompleteRegisteration extends Component {
	constructor(props){
		super(props);
		this.state = {
			username: '',
			country: '',
			isAvailable: true,
			checking: false,
			timeout: 0,
		}

		this.checkUsernameAvailability = this.checkUsernameAvailability.bind(this);
		this.submitDetails = this.submitDetails.bind(this);
	}

	componentWillMount(){
		if(this.props.countries.length === 0)
			this.props.fetchCountries();
	}

	checkUsernameAvailability(e){
		this.setState({
			username: e.target.value,
			checking: true,
			isAvaliable: false
		});

		if(this.state.timeout) clearTimeout(this.state.timeout);

	    // this.state.timeout = setTimeout(() => {
	    //   	//  check if username is available
		// 	axios
		// 	.post('/api/user/checkusername',{
		// 		username: this.state.username
		// 	})
		// 	.then(response => {
		// 		if(response.data.data){
		// 			this.setState({
		// 				checking: false,
		// 				isAvaliable: false
		// 			});
		// 		}else{
		// 			this.setState({
		// 				checking: false,
		// 				isAvaliable: true
		// 			});
		// 		}
		// 	})
		// 	.catch(error => {
		// 		console.log(''+ error);
		// 	})
		// }, 300);
		
		this.setState({
			timeout: setTimeout(()=>{
					axios
					.post('/api/user/checkusername',{
						username: this.state.username
					})
					.then(response => {
						if(response.data.data){
							this.setState({
								checking: false,
								isAvaliable: false
							});
						}else{
							this.setState({
								checking: false,
								isAvaliable: true
							});
						}
					})
					.catch(error => {
						console.log(''+ error);
					})
				}, 300)
		})
	}
	
	submitDetails(e){
		var [country] = this.state.country;
		if(params.get('token') && params.get('type')){
			console.log(country);
			this.props.updateDetails(params.get('token'),params.get('type'),this.state.username,country);
		}
	}

	render() {
		return (
			<div className="App layout-row bg-login">
				<div id="ajax-content" className="h-v d-flex flex align-items-center">
		            <div className="mx-auto w-xl w-auto-xs animate fadeIn text-center">
		                <div className="mb-3">
		                    <img src="http://developer.meplaylist.com/assets/landing/images/favicon.ico" className="w-72 circle" alt="favicon"/>
		                    <h5 className="mt-3 font-bold">Fill these out to complete signup process</h5>
		                </div>
		                <div className="form-group">
		                    <label className="d-block w-100">Enter your username</label>
		                    <input type="text" className="form-control border-0 login-input" placeholder="Enter your username" onKeyUp={this.checkUsernameAvailability}/>
		                    {
		                    	this.state.checking?
		                    	(<span className="font-bold text-success">checking for availability...</span>):(!this.state.checking && this.state.isAvaliable === false ? (<span className="font-bold text-danger">username already taken</span>): (!this.state.checking && this.state.isAvaliable?<span className="font-bold text-success">username available</span>:''))
		                    }
		                    
		                </div>
		                <div className="form-group login">
		                	<label className="d-block w-100">Choose your country</label>
		                	<Typeahead
                                {...this.state}
                                id="country"
                                labelKey="name"
                                options={this.props.countries}
                                placeholder="Select Your Country"
                                onChange={(country)=>this.setState({country:country})}
                            />
		                </div>
		                <div className="mt-3">
		                    <button type="submit" className="btn btn-dark mb-4 px-3 py-2 btn-block submit mt-3" disabled={this.state.isAvaliable? false: true} onClick={this.submitDetails}>Continue</button>
		                </div>
		            </div>
		        </div>
	        </div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		countries: state.auth.countries
	}
}
export default connect(mapStateToProps,{ fetchCountries, updateDetails })(CompleteRegisteration);
