import React, { Component } from 'react';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';
import { Facebook, Twitter,Instagram , MessageCircle } from 'react-feather';

class ShareModal extends Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.copyLink = this.copyLink.bind(this);
    }

    copyLink(){
		var copyText;
		copyText = document.getElementById(`shareText`);
		copyText.focus();
		copyText.select();

		document.execCommand("copy");

		Swal
		.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 4000,
			timerProgressBar: true,
			onOpen: (toast) => {
			  toast.addEventListener('mouseenter', Swal.stopTimer)
			  toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		})
		.fire({
			icon: 'success',
			title: 'Link Copied to Clipboard!'
		})
	}
    render() {
        if(!this.props.share){
            return (
            <div id={`share`} className="modal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-0">
                        <div className="modal-header">
                            <h3 className="modal-title text-dark text-center font-weight-bold">Share this</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <img className="" alt={""} src="#" style={{width: '200px'}}/>
                                    <img className="" src="#" style={{width: '200px'}}alt="image" />
                                </div>
                                <div className="col-md-6">
                                    <p className="text-dark">Social</p>
                                    <a className="btn btn-raised btn-wave btn-icon btn-rounded mb-2 ml-1 blue text-white" href={`https://www.facebook.com/sharer/sharer.php?u=`} target="_blank" rel="noopener noreferrer">
                                        <Facebook size={22}/>
                                    </a>
                                    <a className="btn btn-raised btn-wave btn-icon btn-rounded mb-2 ml-1 light-blue text-white" href={`https://twitter.com/share?url=&text=Check out this song () on MePlaylist`} target="_blank" rel="noopener noreferrer">
                                        <Twitter size={22}/>
                                    </a>
                                    <a className="btn btn-raised btn-wave btn-icon btn-rounded mb-2 ml-1 indigo text-white" href={`https://www.instagram.com/?url=`} target="_blank" rel="noopener noreferrer">
                                        <Instagram size={22} />
                                    </a>
                                    <a className="btn btn-raised btn-wave btn-icon btn-rounded mb-2 ml-1 btn-success text-white" href={`whatsapp://send?text=Check out this song () on MePlaylist`} target="_blank" rel="noopener noreferrer">
                                        <MessageCircle size={22}/>
                                    </a>
                                    <div className="my-5">
                                    <div className="input-group" style={{ width: '100%' }}>
                                        <input type="text" className="form-control" placeholder="Song Link" value="" aria-label="Song Link" aria-describedby="basic-addon2" id={`shareText`} readOnly/>
                                            <div className="input-group-append">
                                                <button className="btn btn-secondary" type="button" onClick={() => this.copyLink()}>copy</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
        }

        if(this.props.share.type === 'playlist'){
    
			return (
				<div id={`share`} className="modal" tabIndex="-1">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content rounded-0">
							<div className="modal-header">
								<h3 className="modal-title text-dark text-center font-weight-bold">Share this {this.props.share.type}</h3>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-md-6">
										<img className="" alt={""} src={this.props.share.song.artwork_path !==""?this.props.share.song.artwork_path: this.props.share.song.PlaylistSongs[0].Album.artwork_file_path} style={{width: '200px'}}/>
									</div>
									<div className="col-md-6">
										<p className="text-dark font-weight-bold mt-3 mt-md-0">Social</p>
										<a className="btn btn-raised btn-wave btn-icon btn-rounded mb-2 ml-1 blue text-white" href={`https://www.facebook.com/sharer/sharer.php?u=${this.props.share.link}`} target="_blank" rel="noopener">
											<Facebook size={22}/>
										</a>
										<a className="btn btn-raised btn-wave btn-icon btn-rounded mb-2 ml-1 light-blue text-white" href={`https://twitter.com/share?url=${this.props.share.link}&text=Check out this Playlist (${this.props.share.song.title}) on MePlaylist`} target="_blank" rel="noopener">
											<Twitter size={22}/>
										</a>
										<a className="btn btn-raised btn-wave btn-icon btn-rounded mb-2 ml-1 btn-danger text-white" href={`https://www.instagram.com/?url=${this.props.share.link}`} target="_blank" rel="noopener">
											<Instagram size={22} />
										</a>
										<a className="btn btn-raised btn-wave btn-icon btn-rounded mb-2 ml-1 btn-success text-white" href={`whatsapp://send?text=Check out this Playlist (${this.props.share.song.title}) on MePlaylist ${this.props.share.link}`} target="_blank" rel="noopener">
											<MessageCircle size={22}/>
										</a>
										<div className="my-5">
										<div className="input-group" style={{ width: '100%' }}>
											<input type="text" className="form-control text-dark" placeholder="Song Link" value={this.props.share.link} aria-label="Song Link" aria-describedby="basic-addon2" id={`shareText`} readOnly/>
												<div className="input-group-append">
													<button className="btn btn-dark" type="button" onClick={() => this.copyLink()}>copy</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}


		return (
			<div id={`share`} className="modal" tabIndex="-1">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content rounded-0">
						<div className="modal-header">
							<h3 className="modal-title text-dark text-center font-weight-bold">Share this {this.props.share.type}</h3>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-md-6">
									<img className="" alt={""} src={this.props.share.song.artwork_file_path} style={{width: '200px'}}/>
								</div>
								<div className="col-md-6">
									<p className="text-dark font-weight-bold mt-3 mt-md-0">Social</p>
									<a className="btn btn-raised btn-wave btn-icon btn-rounded mb-2 ml-1 blue text-white" href={`https://www.facebook.com/sharer/sharer.php?u=${this.props.share.link}`} target="_blank" rel="noopener">
										<Facebook size={22}/>
									</a>
									<a className="btn btn-raised btn-wave btn-icon btn-rounded mb-2 ml-1 light-blue text-white" href={`https://twitter.com/share?url=${this.props.share.link}&text=Check out this song (${this.props.share.song.release_title} by ${this.props.share.song.display_artist_name}) on MePlaylist`} target="_blank" rel="noopener">
										<Twitter size={22}/>
									</a>
									<a className="btn btn-raised btn-wave btn-icon btn-rounded mb-2 ml-1 btn-danger text-white" href={`https://www.instagram.com/?url=${this.props.share.link}`} target="_blank" rel="noopener">
										<Instagram size={22} />
									</a>
									<a className="btn btn-raised btn-wave btn-icon btn-rounded mb-2 ml-1 btn-success text-white" href={`whatsapp://send?text=Check out this song (${this.props.share.song.release_title} by ${this.props.share.song.display_artist_name}) on MePlaylist ${this.props.share.link}`} target="_blank" rel="noopener">
										<MessageCircle size={22}/>
									</a>
									<div className="my-5">
									<div className="input-group" style={{ width: '100%' }}>
										<input type="text" className="form-control text-dark" placeholder="Song Link" value={ this.props.share.link} aria-label="Song Link" aria-describedby="basic-addon2" id={`shareText`} readOnly/>
											<div className="input-group-append">
												<button className="btn btn-dark" type="button" onClick={() => this.copyLink()}>copy</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
    }
}

// export const ShareModal = () => {
// 	const share = store.getState().album.share;

// 	const [artwork, setArtwork] = useState(share?share.song.artwork_file_path:'');

	

// 	if(share){
		
// 	}else{
// 		return ('');
// 	}
	
// }

const mapStateToProps = (state) => {
    return {
        share: state.album.share
    }
}
export default connect(mapStateToProps,null)(ShareModal);