import * as types from '../actions/types';

const initialState = {
	balance: 0,
	history: [],
	wallet: {},
	isFreeTrialActivated: false,
	trial: {},
	isFreeTrialLoading: false,
	subscription: {},
	isWalletLoading: true,
	paymentStatus: '',
	plans:[]
}

export default function(state = initialState, action){
	switch(action.type){
		case types.FETCH_WALLET: 
			return {
				...state,
				wallet: action.payload,
				isWalletLoading: false
			}
		case types.FETCH_WALLET_HISTORY: 
			return {
				...state,
				history: action.payload
			}
		case types.ACTIVATE_FREE_TRIAL: 
			return {
				...state,
				isFreeTrialActivated: true,
				isFreeTrialLoading: false,
				isWalletLoading: false
			}
		case types.ACTIVATE_FREE_TRIAL_LOADING: 
			return {
				...state,
				isFreeTrialLoading: true
			}
		case types.ACTIVATE_FREE_TRIAL_ERROR: 
			return {
				...state,
				isFreeTrialLoading: false
			}
		case types.FETCH_USER_FREE_TRIAL: 
			return {
				...state,
				isFreeTrialActivated: action.payload.trial?true:false,
				trial:action.payload.trial?action.payload.trial:state.trial
			}
		case types.FETCH_USER_CURRENT_SUBSCRIPTION: 
			return {
				...state,
				subscription: action.payload.history
			}
		case types.WALLET_PAYMENT_SUCCESS: 
			return {
				...state,
				paymentStatus: 'success'
			}
		case types.WALLET_PAYMENT_ERROR: 
			return {
				...state,
				paymentStatus: 'failed'
			}
		case types.WALLET_PAYMENT_INVALID: 
			return {
				...state,
				paymentStatus: 'pending'
			}
		case types.FETCH_PLANS: 
			return {
				...state,
				plans: action.payload
			}
		case types.SUBSCRIBE: 
			return {
				...state,
				subscription: action.payload
			}
		default:
			return state;
	}
}