import React, { Component } from 'react';
import { Check } from 'react-feather';
import { connect } from 'react-redux';
import { followUser, unfollowUser } from '../../actions/user';
import Swal from 'sweetalert2';


class FollowBtn extends Component {
	constructor(props){
		super(props);

		this.state = {
			following: false,
			followingText : "Following"
		}

		this.followUser = this.followUser.bind(this);
		this.unfollowUser = this.unfollowUser.bind(this);
		this.unauthenticatedUser = this.unauthenticatedUser.bind(this);
	}

	followUser(){
		this.setState({
			following: true
		});
		this.props.followUser(this.props.authUser.id, this.props.user.id);
	}

	unfollowUser(){
		this.setState({
			following: false
		});
		this.props.unfollowUser(this.props.authUser.id, this.props.user.id);
	}

	unfollowText = (followingText)=>{
		this.setState({followingText})
	}

	unfolowPopUpBox=()=>{
		Swal.fire({
			html: `<h3>Are you sure you want to unfollow ${this.props.user.username} ? </h3>`,
			icon: 'warning',
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Unfollow',
			showCancelButton: true
		  }).then((result) => {
			if (result.value) {
				this.unfollowUser()
			}
		})
	}

	unauthenticatedUser(){
		Swal.fire({
			title: 'Oops!',
			text: "You must login to follow this user",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Login'
		  }).then((result) => {
			if (result.value) {
			  window.location.replace('/login');
			}
		})
	}

	render() {
		if(this.props.authenticated === false){
			return(
				<div>				
					<button className="btn btn-raised btn-rounded btn-dark" onClick={this.unauthenticatedUser}>Follow</button>
				</div>
			);
		}

		return (
			<div>
				{
					this.state.following || this.props.followers.filter((user) => this.props.authUser.id === user.follower_id ).length > 0?
					
					(<button className="btn btn-raised btn-rounded btn-info" id="unfollow-btn"
						onMouseOver={()=>{this.unfollowText("Unfollow")}} 
						onMouseLeave={()=>{this.unfollowText("Following")}}
						onClick={this.unfolowPopUpBox}> 
					
						{this.state.followingText} 
					
						<Check size={18}/>
					</button>)
					:
					(<button className="btn btn-raised btn-rounded btn-dark" onClick={this.followUser}>Follow </button>)
				}

			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		followers: state.user.followers,
		authUser: state.auth.user,
		user: state.user.user,
		authenticated: state.auth.authenticated,
		following: state.user.following
	}
}
export default connect(mapStateToProps,{ followUser, unfollowUser })(FollowBtn);
