import React, { Component } from 'react';
import { connect } from 'react-redux';
import { favourites } from '../actions/user';
import TopTracks from '../components/TopTracks';
import { Link } from 'react-router-dom';
import Image from '../assets/images/headphones-monochrome.svg';

class Favourites extends Component{	
	componentDidMount(){
		this.props.favourites(this.props.user.id);
	}

	render() {
        if(this.props.likes.length === 0){
            return (
                <div id="content" className="flex">
                    <div className="page-container">
                        <div className="padding sr" id="list">
                            <div className="row sr-item justify-content-center">
                                <div className="col-md-12 text-center">
                                    <img src={Image} alt={""} style={{ width: '500px'}}/>
                                    <div className="m-5">
                                        <p className="h1">You have no favourites song or playlist yet!</p>
                                        <Link to="/playlists" className="btn btn-light p-2 px-4">Checkout Playlists</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

		return (
			<div id="content" className="flex">
                <div className="page-container">
                    <div className="padding sr" id="list">
                        <div className="heading py-2 d-flex ">
                            <div>
                                <h1 className="text-highlight sr-item font-weight-bold">Your Favourites</h1>
                            </div>
                            <span className="flex"></span>
                        </div>
                       		{
                        		this.props.likes.filter(favourite => favourite.liked_album_fk !==null).length > 0?
                        		(<div className="heading py-2 d-flex">
					                <div>
					                    <h2 className="text-highlight font-weight-bold">Tracks</h2>
					                </div>
					                <span className="flex"></span>
					            </div>):('')
                        	}			                
                            <TopTracks track={this.props.likes.filter(favourite => favourite.liked_album_fk && favourite.Album)} type="single"/>
                        	{
                        		this.props.likes.filter(favourite => favourite.liked_playlist_fk && favourite.Playlist).length > 0?
                        		(<div className="heading py-2 d-flex mt-5">
					                <div>
					                    <h2 className="text-highlight font-weight-bold">Playlists</h2>
					                </div>
					                <span className="flex"></span>
					            </div>):('')
                        	}
                        
                        <div className="row sr-item">
			                {
                                
                               this.props.likes.filter(favourite => favourite.liked_playlist_fk && favourite.Playlist).map(favourite => {
                                    return (
                                        <div className="col-4 col-sm-2" data-id={favourite.Playlist.generated_id} key={favourite.Playlist.generated_id}>
                                            <div className="list-item r">
                                                {
                                                   favourite.Playlist.artwork_path !==''?
                                                    (<div className="media">
                                                    	<Link to={`/playlist/${(favourite.Playlist.generated_id)}`} className="ajax media-content " style={{backgroundImage:"url('"+favourite.Playlist.artwork_path+"')"}}>
                                                        </Link>
                                                     </div>)
                                                    : favourite.Playlist.PlaylistSongs.length >0?
                                                       	(<div className="media ">
                                                       		<Link to={`/playlist/${(favourite.Playlist.generated_id)}`} className="ajax media-content " style={{backgroundImage:"url('"+favourite.Playlist.PlaylistSongs[0].Album.artwork_file_path+"')"}}>
                                                            </Link>
                                                        </div>):
                                                        (<div className="media"></div>)
                                                    }
                                                    <div className="list-content text-center">
                                                        <div className="list-body ">
                                                            <Link to={`/playlist/${(favourite.Playlist.generated_id)}`} className="list-title title ajax h-1x">
                                                                            { favourite.Playlist.title }
                                                            </Link>
                                                        </div>
                                                   	</div>
                                                </div>
                                            </div>)
                                })
                            }
                        </div>
                        <div className="pagination pagination-sm mt-3 hide"></div>
                    </div>
                </div>
            </div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		likes: state.user.favourites,
		user: state.auth.user,
	}
}

export default connect(mapStateToProps, { favourites })(Favourites);
