import * as actionTypes from '../actions/types';

const initialState = {
	authenticated: localStorage.getItem('__ekaeae')?true:false,
	loading: false,
	user: localStorage.getItem('user')?JSON.parse(window.atob(localStorage.getItem('user'))):{},
	token: localStorage.getItem('__ekaeae')? window.atob(localStorage.getItem('__ekaeae')) : null,
	error: false,
	countries: [],
	userCountry: {},
	uploading: false,
	following: [],
	followers: [],
	likes:[]
}

export default function(state = initialState,action){
	switch(action.type){
		case actionTypes.LOGIN:
			return {
				...state,
				authenticated: true,
				loading: false
			}
		case actionTypes.REGISTER:
			return {
				...state,
				loading: false
			}
		case actionTypes.GOOGLE_AUTH:
			return {
				...state,
				authenticated: true,
			}
		case actionTypes.AUTH_ERROR:
			return {
				...state,
				error:true,
				loading: false
			}
		case actionTypes.AUTH_LOADING:
			return {
				...state,
				loading: true
			}
		case actionTypes.FETCH_COUNTRIES:
			return {
				...state,
				countries: action.payload
			}
		case actionTypes.FETCH_USER_COUNTRY:
			return {
				...state,
				userCountry: action.payload
			}
		case actionTypes.LOGOUT:
			return {
				...state,
				authenticated: false,
				loading: false,
				user: {}
			}
		case actionTypes.IMAGE_UPLOADING:
			return {
				...state,
				uploading: true,
			}
		case actionTypes.IMAGE_UPLOADED:
			var updatedUser = Object.assign(state.user,{avatar: action.payload});
			localStorage.setItem('user',window.btoa(JSON.stringify(updatedUser)));
			return {
				...state,
				uploading: false,
				user: updatedUser
			}
		case actionTypes.AUTH_FOLLOWERS_FOLLOWING:
			return {
				...state,
				following: action.payload.Following.filter(following => following.Followed !==null),
				followers: action.payload.Followed.filter(follower => follower.Following !==null),
			}
		case actionTypes.FETCH_USER_LIKES:
			return {
				...state,
				likes: action.payload
			}
		case actionTypes.UPDATE_USER_LIKES:
			return {
				...state,
				likes: state.likes.concat([action.payload]),
			}
		case actionTypes.SENDING_RESET_CODE:
			return {
				...state,
				loading: true,
			}
		case actionTypes.RESET_CODE_SENT:
			return {
				...state,
				loading: false,
			}
		case actionTypes.SAVE_NOTIFICATION_TOKEN:
			//update localstorage add token to saved user object
			let user = state.user;
			user['web_fcm_token'] = action.payload;
			localStorage.setItem('user',window.btoa(JSON.stringify(user)));
			return {
				...state,
				user	
			}
		default:
		return state;
	}
}