import { 
    FETCH_ALBUMS, 
    FETCH_ALBUM_LOADING,
    FETCH_ALBUMS_LOADING,
    FETCH_ALBUM,
    FETCH_LATEST_ALBUMS, 
    FETCH_LATEST_SINGLES, 
    FETCH_RANDOM_AFRO,
    FETCH_RECENTLY_PLAYED,
    FETCH_LAYERS,
    FETCH_ALL_LAYERS,
    FETCH_PODCASTS,
    FETCH_SINGLE,
    FETCH_SONGS_BY_GENRE,
    LIKE_ALBUM,
    UNLIKE_ALBUM,
    UPDATE_SHARE_PROPERTIES,
    UPDATE_QUEUE
} from '../actions/types';
import axios from 'axios';
import store from '../store';
import { getCookie } from '../utils/util';

const user = store.getState().auth.user;
const subscription = store.getState().wallet.subscription;

/**
 * fetch all the album resources
 * 
 * @params null
 */
export const fetchAlbums = () => dispatch => {
    dispatch({
        type: FETCH_ALBUMS_LOADING,
    });
    axios.post('/api/albums',{
        territory_code: user?.Country?.alpha_2 ? user?.Country?.alpha_2 : store.getState().user.location,
        subscription: store.getState().wallet.subscription
    })
    .then(response => {
        // console.log(response.data);
        dispatch({
            type: FETCH_ALBUMS,
            payload: response.data.data.albums
        });
    })
    .catch(error =>{
        console.log(error);
    });
}

/**
 * fetch an album resources by upc
 * 
 * @param {string} upc 
 */
export const fetchAlbum = (albumID) => dispatch => {
    dispatch({
        type: FETCH_ALBUM_LOADING,
    });
    axios
    .post('/api/album/'+albumID,{
        territory_code: user?.Country?.alpha_2 ? user?.Country?.alpha_2: store.getState().user.location,
        subscription: Object.entries(store.getState().wallet.subscription).length === 0 && store.getState().wallet.subscription.constructor === Object ? 'free' : store.getState().wallet.subscription
    })
    .then(response => {
        dispatch({
            type: FETCH_ALBUM,
            payload: response.data.data.album
        });
        // dispatch({
        //     type: UPDATE_QUEUE,
        //     payload: response.data.data.album
        // });
    })
    .catch(error => {
        console.log(error);
    });
}

/**
 * fetch an album resources by generated ID
 * 
 * @param {integer} genID 
 */
export const fetchSingle = (id) => dispatch => {
    axios
    .post('/api/single/'+id,{
        territory_code: user?.Country?.alpha_2 ? user?.Country?.alpha_2: store.getState().user.location,
        subscription: store.getState().wallet.subscription,
        _csrf: getCookie('_csrf')
    })
    .then(response => {
        dispatch({
            type: FETCH_SINGLE,
            payload: response.data.data
        });
            // dispatch({
            //     type: UPDATE_QUEUE,
            //     payload: [response.data.data]
            // });
    })
    .catch(error => {
        console.log(error);
    });
}

/**
 * fetch latests album resources in the last 2 months
 * 
 * @param null
 */
export const fetchLatestAlbums = () => dispatch => {
    console.log('User obj: ', user)
    axios
    .post('/api/albums/latest',{
        type:'Album',
        territory_code: user?.Country?.alpha_2 ? user?.Country?.alpha_2 : store.getState().user.location,
        subscription: store.getState().wallet.subscription
    })
    .then(response => {
        dispatch({
            type: FETCH_LATEST_ALBUMS,
            payload: response.data.data.album
        });
    })
    .catch(error => {
        console.log(error);
    });
}

/**
 * fetch latests album resources in the last 2 months
 * 
 * @param null
 */
export const fetchLatestSingles = () => dispatch => {
    axios.post('/api/albums/latest',{
        type:'Single',
        territory_code: user?.Country?.alpha_2 ? user?.Country?.alpha_2: store.getState().user.location,
        subscription: store.getState().wallet.subscription
    })
        .then(response => {
            dispatch({
                type: FETCH_LATEST_SINGLES,
                payload: response.data.data.album
            });
        })
        .catch(error => {
            console.log(error);
        });
}

/**
 * fetch latests album resources from tuneafrique
 * 
 * @param null
 */
export const fetchRandomAfro = () => dispatch => {
    axios.post('/api/albums/afro',{
        territory_code: user?.Country?.alpha_2 ? user?.Country?.alpha_2: store.getState().user.location,
        subscription: store.getState().wallet.subscription
    })
        .then(response => {
            dispatch({
                type: FETCH_RANDOM_AFRO,
                payload: response.data.data.album
            });
        })
        .catch(error => {
            console.log(error);
        });
}

/**
 * fetch user's recently played based on stream data
 * 
 * @param null
 */
export const fetchRecentlyPlayed = () => dispatch => {
    axios.post('/api/albums/user/recent',{
        userID: user.id
    })
    .then(response => {
        dispatch({
            type: FETCH_RECENTLY_PLAYED,
            payload: response.data.data
        });
    })
    .catch(error => {
        console.log(error);
    });
}

/**
 * fetch user's recently played based on stream data
 * 
 * @param null
 */
export const fetchLayers = () => dispatch => {
    axios.post('/api/user/layers',{
        location: user?.Country?.alpha_2 ? user?.Country?.alpha_2: store.getState().user.location,
        subscription: store.getState().wallet.subscription
    })
    .then(response => {
        dispatch({
            type: FETCH_LAYERS,
            payload: response.data.data
        });
    })
    .catch(error => {
        console.log(error);
    });
}

/**
 * fetch user's recently played based on stream data
 * 
 * @param null
 */
export const fetchAllLayers = () => dispatch => {
    axios
    .post('/api/v1/layers')
    .then(response => {    
        dispatch({
            type: FETCH_ALL_LAYERS,
            payload: response.data.layers
        });
    })
    .catch(error => {
        console.log(error);
    });
}

/**
* fetch podcast - soundrecording
*/
export const fetchPodcasts = () => dispatch => {
    axios.post('/api/albums/podcasts',{
        genre: 'soundrecording',
        territory_code: user?.Country?.alpha_2 ? user?.Country?.alpha_2: store.getState().user.location,
        subscription: store.getState().wallet.subscription
    })
    .then(response => {
        dispatch({
            type: FETCH_PODCASTS,
            payload: response.data.data
        });
    })
    .catch(error => {
        console.log(error);
    });
}

/**
 * fetch songs by genre 
 */
export const fetchSongsByGenre = (genre) => dispatch => {
     dispatch({
         type: FETCH_ALBUMS_LOADING,
     });
    axios
    .post('/api/albums/genre/'+genre,{
        territory_code: user?.Country?.alpha_2 ? user?.Country?.alpha_2: store.getState().user.location,
        subscription: store.getState().wallet.subscription
    })
    .then(response => {
        dispatch({
            type: FETCH_SONGS_BY_GENRE,
            payload: response.data.data
        });
    })
    .catch(error => {
        console.log(error);
    });
}

/**
* save Like/Unlike for album
*
*@params id Integer
*/
export const likeAlbum = (albumID) => dispatch => {
    axios.post('/api/album/like',{
        userID: user.id,
        albumID
    })
    .then(response => {
        dispatch({
            type: LIKE_ALBUM,
            payload: response.data.data,
        });
    })
}

export const unlikeAlbum = (albumID) => dispatch => {
    axios.post('/api/v1/album/unlike',{
        userID: user.id,
        albumID
    })
    .then(response => {
        dispatch({
            type: UNLIKE_ALBUM,
            payload: response.data.data,
        });
    })
}

export const shareAlbum = (data) => dispatch => {
    dispatch({
        type: UPDATE_SHARE_PROPERTIES,
        payload: data
    });
}

export const updateSongQueue = (data) => dispatch => {
    dispatch({
        type: UPDATE_QUEUE,
        payload: data
    });
}
