import { 
    FETCH_ALBUMS,
    FETCH_ALBUM, 
    FETCH_LATEST_ALBUMS, 
    FETCH_LATEST_SINGLES,
    FETCH_RANDOM_AFRO,
    FETCH_RECENTLY_PLAYED,
    FETCH_LAYERS,
    FETCH_PODCASTS,
    FETCH_SINGLE,
    FETCH_ALBUM_LOADING,
    FETCH_ALBUMS_LOADING,
    FETCH_SONGS_BY_GENRE,
    UPDATE_SHARE_PROPERTIES,
} from '../actions/types';

const initialState = {
    albums: [],
    album: [],
    single: {},
    singles: [],
    randomAfro: [], // songs/albums by tuneafrique
    recentlyPlayed: [],
    layers: [],
    podcasts: [],
    albumLoading: true,
    albumsLoading: true,
    genreSongs: [],
    share: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALBUMS:
            return {
                ...state,
                albums: action.payload,
                albumsLoading: false
            }
        case FETCH_ALBUM:
            return {
                ...state,
                album: action.payload,
                albumLoading: false
            }
        case FETCH_ALBUM_LOADING:
            return {
                ...state,
                albumLoading: true
            }
        case FETCH_ALBUMS_LOADING:
            return {
                ...state,
                albumsLoading: true
            }
        case FETCH_SINGLE:
            return {
                ...state,
                single: action.payload,
                albumLoading: false
            }
        case FETCH_LATEST_ALBUMS:
            return {
                ...state,
                albums: action.payload
            }
        case FETCH_LATEST_SINGLES:
            return {
                ...state,
                singles: action.payload
            }
        case FETCH_RANDOM_AFRO:
            return {
                ...state,
                randomAfro: action.payload
            }
        case FETCH_RECENTLY_PLAYED:
            return {
                ...state,
                recentlyPlayed: action.payload
            }
        case FETCH_LAYERS:
            return {
                ...state,
                layers: action.payload
            }
        case FETCH_PODCASTS:
            return {
                ...state,
                podcasts: action.payload
            }
        case FETCH_SONGS_BY_GENRE:
            return {
                ...state,
                genreSongs: action.payload,
                albumsLoading: false
            }
        case UPDATE_SHARE_PROPERTIES:
            return {
                ...state,
                share: action.payload
            }
    
        default:
            return state;
    }
}