import { 
    FETCH_ARTISTS, 
    FETCH_ARTIST, 
    FOLLOW_ARTIST,
    UNFOLLOW_ARTIST,
    FETCH_TOP_ARTISTS,
    FETCHING_ARTIST,
    FETCH_SIMILAR_ARTISTS,
    FETCH_ARTIST_TOP_TRACKS,
    FETCH_ARTIST_PLAYLISTS,
    FETCH_ARTIST_FOLLOWERS,
    FETCH_ARTIST_USER_IS_FOLLOWING
} from '../actions/types';
import { getUnique } from '../utils/util';

const initialState = {
    artists: [],
    artist: {},
    albums: [],
    singles: [],
    playlists: [],
    followers: 0,
    loading: true,
    topArtists: [],
    similarArtists: [],
    topTracks: [],
    songs: [], // all the songs of the artist /album/single/trackrelease/podcast
    isFollowing: false // if user is following artist
}

export default function(state = initialState,action){
    switch (action.type) {
        case FETCH_ARTISTS:
            return {
                ...state,
                artists: action.payload.artists,
                loading: false,
            }            
        case FETCH_ARTIST:
            //filter singles from array
            let songs = action.payload.albums;
            let albums =  songs.filter((song)=> song.release_type ==='Album');
            let singles = songs.filter((album) => album.release_type === 'Single'); // || album.release_type === 'TrackRelease'
            return {
                ...state,
                artist: action.payload.artist,
                albums,
                singles,
                loading: false,
            }
        case FOLLOW_ARTIST:
            return {
                ...state,
                followers: state.followers + 1
            }
        case UNFOLLOW_ARTIST:
            return {
                ...state,
                isFollowing: false,
                followers: state.followers - 1
            }
        case FETCHING_ARTIST:
            return {
                ...state,
                loading: true
            }
        case FETCH_TOP_ARTISTS:
            return {
                ...state,
                topArtists: action.payload
            }
        case FETCH_SIMILAR_ARTISTS:
            return {
                ...state,
                similarArtists: action.payload,
                similarArtistLoading: false
            }
        case FETCH_ARTIST_TOP_TRACKS:
            return {
                ...state,
                topTracks: action.payload
            }
        case FETCH_ARTIST_PLAYLISTS:
            return {
                ...state,
                playlists: action.payload,
            }
        case FETCH_ARTIST_FOLLOWERS:
            return {
                ...state,
                followers: action.payload,
            }
        case FETCH_ARTIST_USER_IS_FOLLOWING:
            return {
                ...state,
                isFollowing: action.payload
            }
        default:
            return state;
    }
}