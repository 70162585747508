import * as types from '../actions/types';

const initialState = {
  songs: [],
  playlists:[],
  playlist: {},
  userPlaylists: [],
  isLoading: true,
  addToPlaylistSong: null,
  moods:[],
  editorPlaylists: {},
  moodsPlaylists: [],
  mood: 0
}

export default function( state = initialState, action){
    switch(action.type){
        case types.FETCH_SONGS:
          return {
              ...state,
              songs: action.payload,
          }
        case types.FETCH_PLAYLISTS:
          return {
            ...state,
            playlists: action.payload.playlists,
            isLoading: false,
          }
        case types.FETCH_PLAYLIST:
          return {
            ...state,
            playlist: action.payload.Playlist,
            songs: action.payload.Playlist.PlaylistSongs,
            isLoading: false,
          }
        case types.FETCH_TOP_PLAYLISTS:
          return {
            ...state,
            playlists: action.payload,
          }
        case types.FETCH_USER_PLAYLISTS:
          return {
            ...state,
            userPlaylists: action.payload,
          }
        case types.ADD_SONG_TO_PLAYLIST:
          return {
            ...state
          }
        case types.ADD_TO_PLAYLIST_SONG:
          return {
            ...state,
            addToPlaylistSong: action.payload
          }
        case types.CREATE_PLAYLIST:
          //update userplaylists stack       
          return {
            ...state,
            userPlaylists: state.userPlaylists.concat([action.payload])
          }
        case types.EDIT_PLAYLIST:
          return {
            ...state
          }
        case types.DELETE_PLAYLIST:
          return {
            ...state,
            playlist: {}
          }
        case types.DELETE_PLAYLIST_SONG:
          return {
            ...state,
            songs: state.songs.filter(song => song.song_id !== action.payload)
          }
        case types.FETCH_MOODS:
          return {
            ...state,
            moods: action.payload,
            mood: action.payload[0].id,
          }
        case types.FETCHING_PLAYLIST:
          return {
            ...state,
            isLoading: true
          }
        case types.FETCH_EDITOR_PLAYLISTS:
          return {
            ...state,
            editorPlaylists: action.payload
          }
        case types.FETCH_MOODS_PLAYLISTS:
          return {
            ...state,
            moodsPlaylists: action.payload
          }
        default:
          return state;
    }
}
