import React, { Component } from 'react';
import { Facebook, Twitter, Instagram } from 'react-feather';
import logo from '../assets/images/meplaylist_color_white_text.fw.png';

class NotAvailable extends Component {

    render(){
        return (
            <div className="row no-gutters vh-100 bg-dark align-items-center px-1" style={{ backgroundImage: "url('https://res.cloudinary.com/daniel0101/image/upload/q_auto/v1560167484/africa-festival-man-1518761_hwktpq.jpg')", backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <div className="col-12 text-center">
                    <span className="img-brand">
                        <img src={logo} className="logo-notavailable" alt="logo"/>
                    </span>
                    <h1 className="text-white font-weight-bolder" style={{ fontSize: '3rem'}}>Meplaylist is currently not available in your country</h1>
                    <h3 className="text-white">Follow us online to find out when we launch</h3>
                    <div className="mt-3">
                        <a className="btn btn-lg btn-raised btn-wave btn-icon btn-rounded mb-2 blue text-white mr-md-1" href="https://www.facebook.com/meplaylist" target="_blank" rel="noopener noreferrer">
                            <Facebook size={30}/>
                        </a>
                        <a className="btn btn-lg btn-raised btn-info btn-icon btn-rounded mb-2 text-white mr-md-1" href="https://www.twitter.com/meplaylist" target="_blank" rel="noopener noreferrer">
                            <Twitter size={30}/>
                        </a>
                        <a className="btn btn-lg btn-raised btn-wave btn-icon btn-rounded mb-2 indigo text-white" href="https://www.instagram.com/meplaylisthq" target="_blank" rel="noopener noreferrer">
                            <Instagram size={30}/>
                        </a>
                    </div>
                </div> 
            </div>
        )
    }
}

export default NotAvailable;