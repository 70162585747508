import React, {Component } from 'react';
import { Radio, User, Edit2, Share2 } from 'react-feather';
import TracksList from '../components/TracksList';
import EditPlaylist from '../components/TopPlaylists/EditPlaylist';
import LikeBtn from '../components/TopPlaylists/LikeBtn';
import { connect } from 'react-redux';
import { fetchPlaylist, setQueue, sharePlaylist } from '../actions/playlist';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { cipher } from '../utils/util';
import Stream from '../components/Stream';
import $ from 'jquery';
import { Link } from 'react-router-dom';
// import { ShareModal } from '../components/Player/PlayerActions';

class Playlist extends Component {
  constructor(props){
    super(props);

    this.showEditPlaylistModal = this.showEditPlaylistModal.bind(this);
    this.playAllQueue = this.playAllQueue.bind(this);
    this.sharePlaylist = this.sharePlaylist.bind(this);
  }
  componentWillMount(){
    this.props.fetchPlaylist(this.props.match.params.id);
  }

  componentDidMount(){
    $('[rel="tooltip"]').tooltip();
  }

  componentDidUpdate(prevProps){
    // if the location params change -- usually when a user search on the top bar
    if(this.props.location !== prevProps.location){
      this.props.fetchPlaylist(this.props.match.params.id);
    }
  }

  showEditPlaylistModal(){
    $('#editPlaylist').modal('toggle');
  }

  playAllQueue(){
    this.props.setQueue(this.props.songs.map(track => track.Album));
  }

  sharePlaylist(){
    this.props.sharePlaylist({
      link: `https://app.meplaylist.com/playlist/${this.props.playlist.generated_id}`,
			song: this.props.playlist,
			type: 'playlist'
    });

    $('#share').modal('toggle');
  }
  
  render(){
    const encrypt = cipher('78sji3w9-2djadw2');
    if(this.props.loading){
        return (
            <div id="content" className="flex ">
              <div className="page-container">
                <div className="bg-light">
                  <div className="page-container padding">
                    <div className="loader">
                      <div className="loading">Loading&#8230;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
    }


      return (
            <div id="content" className="flex">
              <div className="page-container">
                <div className="bg-light">
                  <div className="page-container padding">
                    <div className="d-sm-flex page-heading" data-id={this.props.songs.length >0 ? this.props.songs[0].Album.isrc: ''} data-playlist-id={this.props.playlist.generated_id} data-source={this.props.songs.length >0 ? encrypt(this.props.songs[0].Album.audio_file_path_192): ''} data-recordstream={this.props.user.id === this.props.playlist.user_id ? "false":"true"}>
                      <div className="media r w w-auto-xs mb-4">
                        <div className="media-content" style={ this.props.playlist.artwork_path !=='' && this.props.playlist.artwork_path !== null ?{ backgroundImage: "url('"+this.props.playlist.artwork_path+"')" }:this.props.songs.length >0?{ backgroundImage: "url('"+this.props.songs[0].Album.artwork_file_path+"')" }:{}}></div>
                      </div>
                      <div className="d-md-flex flex">
                        <div className="mx-sm-4 flex">
                          <h1 className="h4 font-weight-bold mb-0 text-highlight title">{ this.props.playlist.title }</h1>
                          <div className="py-4 toolbar align-items-center">
                            <button className="btn btn-play btn-icon btn-md btn-rounded btn-primary" data-toggle="tooltip" title="Play All" onClick={this.playAllQueue}></button>
                            <span className="text-muted"><b>{ this.props.songs.length }</b> Tracks</span>
                            <LikeBtn playlist={this.props.playlist}/>
                            <button className="btn no-bg text-muted btn-icon btn-rounded ">
                              <Radio size={20}/>
                            </button>
                            { 
                            Object.keys(this.props.playlist).length > 0 && this.props.playlist.constructor === Object?
                            <Stream id={ this.props.playlist.generated_id } type="playlist"/>
                            : 
                            <span className="text-muted">0 streams</span>}
                          </div>
                          <div className="mb-4">
                            <span className="text-muted">Tags: </span>
                            <span  className="text-color ajax pr-1">
                              <span className="badge badge-light">
                                { this.props.playlist.country }
                              </span>
                            </span>
                          </div>
                          {/* <ShareModal link={`https://app.meplaylist.com/playlist/${this.props.playlist.generated_id}`}  song={this.props.playlist} artwork={this.props.songs[0].Album.artwork_file_path} type={`playlist`}/> */}
                          
                          {
                            this.props.user.id === this.props.playlist.user_id ? 
                              (<div className="mb-4">
                                <button className="btn btn-white btn-sm font-weight-bold" onClick={this.showEditPlaylistModal}><Edit2 size={16}/> <span className="pl-1">Edit</span>
                                </button>
                                <button className="btn btn-raised mb-2 ml-3 btn-white font-weight-bold" onClick={() => this.sharePlaylist()}>
                                  <Share2 size={16} className="mr-2" /> Share Playlist
                                </button>
                              </div>):(
                                <button className="btn btn-raised mb-2 btn-white font-weight-bold" onClick={() => this.sharePlaylist()}>
                                  <Share2 size={16} className="mr-2" /> Share Playlist
                                </button>
                              )
                          }
                        </div>
                        {
                          this.props.playlist.isAdminCreated === false && this.props.playlist.User?
                          (<div className="py-3 py-sm-4 mx-sm-4">
                            <div className="d-flex align-items-center">
                              <div className="media w-56 circle">
                                <span className="media-content text-secondary">
                                  <User size={24}/>
                                </span>
                              </div>
                              <div className="px-3">  
                                <div className="text-sm text-muted">Created By</div>
                                <Link to={`/user/${this.props.playlist.User.generated_id}`} className="text-color">{ this.props.playlist.User.username !==''? this.props.playlist.User.username: `User-${this.props.playlist.User.generated_id}` }</Link>
                              </div>
                            </div>
                          </div>)
                          : ('')
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="page-container padding">
                  <div className="d-md-flex">
                    <div className="py-4">
                      <div className="heading py-2 d-flex ">
                        <div>
                          <div className="text-muted text-sm sr-item">Playlist - <span className="badge badge-primary">{ this.props.playlist.title}</span></div>
                          <h5 className="text-highlight sr-item">{ pluralize('Track',this.props.songs.length) }</h5>
                        </div>
                        <span className="flex"></span>
                      </div>
                      {
                        this.props.user.id === this.props.playlist.user_id ? 
                        (<TracksList tracks={this.props.songs} playlistID={this.props.playlist.id}/>)
                        : (<TracksList tracks={this.props.songs}/>)
                      }
                      
                    </div>
                  </div>
                </div>
              </div>
              {
                this.props.user.id === this.props.playlist.user_id?
                (<EditPlaylist/>):('')
              }
            </div>
      );
  }
}
Playlist.propTypes = {
    fetchPlaylist: PropTypes.func.isRequired,
    songs: PropTypes.array.isRequired
}
const mapStateToProps = state => {
    return {
      playlist: state.playlist.playlist,
      songs: state.playlist.songs,
      user: state.auth.user,
      loading: state.playlist.isLoading
    }
}

export default connect(mapStateToProps,{ fetchPlaylist, setQueue, sharePlaylist })(Playlist);
