import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Heart } from 'react-feather';
import Swal from 'sweetalert2';
import { updateLike } from '../../actions/user';

class LikeBtn extends Component {
	constructor(props){
		super(props);
		this.state = {
			liked: false,
			likeCount: 0
		}
	}

	componentWillMount(){
		//check if playlist was liked by user before
		let isLiked = this.props.likes.filter((like) => like.liked_playlist_fk === this.props.playlist.id);
		if(isLiked.length > 0){
			this.setState({
				liked: true
			})
		}

		//fetch number of likes
		axios
		.post('/api/playlist/likes',{
			playlistID: this.props.playlist.id
		})
		.then(response => {
			this.setState({
				likeCount: response.data.data
			})
		})
	}

	handleLike =()=>{
		this.setState({
			liked: true
		});
		axios.post('/api/playlist/like',{
	        userID: this.props.user.id,
	        playlistID: this.props.playlist.id,
	        count: this.props.playlist.like_count || 0,
			// TODO: Refactor this to use the new like count from entity table and remove the defaulted 0
	    })
	    .then(response => {
	    	//update UI
	       	this.setState({
	       		liked: true,
	       		likeCount: this.state.likeCount + 1
			});
			   
			//update store (state) of user's likes to add this new liked entity
	    	this.props.updateLike({
	    		liker_user_fk: this.props.user.id,
	    		liked_album_fk: null,
	    		liked_playlist_fk: this.props.playlist.id
	    	});
	    })
	    .catch(error => {
	    	this.setState({
	    		liked: false
	    	});
	    	toast.error('Network error, try again later!');
	    });
	}

	handleUnlike = () => {
		this.setState({
			liked: false
		});
		axios.post('/api/v1/playlist/unlike',{
	        userID: this.props.user.generated_id,
	        playlistID: this.props.playlist.id,
	        count: this.props.playlist.like_count || 0,
			// TODO: Refactor this to use the new like count from entity table and remove the defaulted 0,
	    })
	    .then(response => {
	    	//update UI
	       	this.setState({
	       		liked: false,
	       		likeCount: this.state.likeCount - 1
			});
			   
			//update store (state) of user's likes to add this new liked entity
	    	this.props.updateLike({
	    		liker_user_fk: this.props.user.id,
	    		liked_album_fk: null,
	    		liked_playlist_fk: this.props.playlist.id
	    	});
	    })
	    .catch(error => {
	    	this.setState({
	    		liked: true
	    	});
	    	toast.error('Network error, try again later!');
	    });
	}

	unauthenticatedUser(){
		Swal.fire({
			title: 'Oops!',
			text: "You must login to like this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Login'
		  }).then((result) => {
			if (result.value) {
			  window.location.replace('/login');
			}
		})
	}

	render() {
		if(this.props.authenticated === false){
			return (
				<div>
				   <button className="btn no-bg text-muted btn-icon btn-rounded" onClick={this.unauthenticatedUser}>
					   <Heart size={20}/>
				   </button>
				   <span className="text-muted">{ this.state.likeCount }</span>
			  	</div>	
			);
		}

		if(this.state.liked){
			return (
				<div>
					<button className="btn no-bg text-muted btn-icon btn-rounded " onClick={this.handleUnlike}>
		                <Heart size={20} className="icon--pressed"/>
		            </button>
		            <span className="text-muted">{ this.state.likeCount }</span>
	           </div>
			);
		}

		return (
			<div>
				<button className="btn no-bg text-muted btn-icon btn-rounded" onClick={this.handleLike}>
	                <Heart size={20}/>
	            </button>
	            <span className="text-muted">{ this.state.likeCount }</span>
           </div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		likes: state.auth.likes,
		authenticated: state.auth.authenticated,
	}
}

export default connect(mapStateToProps,{ updateLike })(LikeBtn);
