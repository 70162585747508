import React,{ Component, Fragment} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './displayartist.css';

class DisplayArtists extends Component {
    constructor(props){
        super(props);
        this.state = {
            main_artist: null,
            featured: null
        }
    }

    componentDidMount(){
       axios
       .post('/api/v1/artist/display-artists',{
           display_artist_name: this.props.artist
       })
       .then(response => {
           this.setState({
               main_artist: response.data.data.main_artist,
               featured: response.data.data.featured
           });
       })
       .catch(error => console.log(error));
    }

    render(){
        if(!this.state.main_artist && !this.state.featured){
            return (
                ( <Link to={``} className="list-subtitle d-block text-muted subtitle ajax h-1x">
                    { this.props.artist }
                </Link>)
            )
        }

        if(this.state.main_artist && !this.state.featured){
            return(
                <Link to={this.state.main_artist.constructor === Array? `/artist/${this.state.main_artist[0].generated_id}` : `/artist/${this.state.main_artist.generated_id}`} className="list-subtitle d-block text-muted subtitle ajax h-1x">
                    { this.state.main_artist.constructor === Array && this.state.main_artist.length > 0? this.state.main_artist[0].artist_name: this.state.main_artist.constructor === Array && !this.state.main_artist.length? this.props.artist :this.state.main_artist.artist_name}
                </Link>
            )
        }

        if(this.state.main_artist && this.state.featured){
           return ( 
            <div className="list-subtitle d-block text-muted subtitle ajax h-1x">
                <Link to={this.state.main_artist.constructor === Array && this.state.main_artist.length? `/artist/${this.state.main_artist[0].generated_id}` : `/artist/${this.state.main_artist?.generated_id}` } className="text-muted">
                    { this.state.main_artist.constructor === Array && this.state.main_artist.length > 0 ? this.state.main_artist[0].artist_name : this.state.main_artist.constructor === Object && this.state.main_artist.artist_name? this.state.main_artist.artist_name: this.props.artist }
                </Link>
                <span className="mx-2">{(this.state.featured.constructor === Array && this.state.featured.length > 0) || (this.state.featured.constructor === Object && this.state.featured.artist_name)? 'ft': ''}</span>
                <Link to={ this.state.featured.constructor === Array && this.state.featured.length > 0? `/artist/${this.state.featured[0].generated_id}`: `/artist/${this.state.featured.generated_id}`} className="text-muted">
                    { this.state.featured.constructor === Array && this.state.featured.length > 0? this.state.featured[0].artist_name: this.state.featured.constructor === Object && this.state.featured.artist_name? this.state.featured.artist_name: "" }
                </Link>
            </div>
            )
        }
    }
}
export default DisplayArtists;