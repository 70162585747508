import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../actions/auth';

class WalletHeader extends Component {
	render() {
		return (
			<header id="header" className="page-header box-shadow" style={{ color: '#000'}}>
	            <div className="navbar navbar-expand-lg">
	                <Link to="/" className="navbar-brand" style={{color: '#000 !important'}}>
	                    MePlaylist
	                </Link>
	                <div className="collapse navbar-collapse order-2 order-lg-1" id="navbarToggler">
	                    <form className="input-group m-2 my-lg-0 ">
	                        
	                    </form>
	                    <ul className="navbar-nav mt-lg-0 mx-0 mx-lg-2 ">
	                        <li className="nav-item">
	                            <Link to="/" className="nav-link btn btn-dark text-light rounded-0 btn-sm">
	                                Home
	                            </Link>
	                        </li>                        
	                    </ul>
	                </div>
	                <ul className="nav navbar-menu order-1 order-lg-2">
	                    <li className="nav-item dropdown">
	                        <Link to="#" data-toggle="dropdown" className="nav-link d-flex align-items-center px-2 text-color">
	                            <span className="dropdown-toggle  mx-2 d-none l-h-1x d-lg-block text-right">{ this.props.user.username }</span>
	                            <span className="avatar w-24" style={{margin: '-2px'}}><img src={ this.props.user.avatar !== '' && !this.props.user.avatar.includes('https://meplaylist.s3.amazonaws.com/profilepics/?')?this.props.user.avatar:'http://developer.meplaylist.com/assets/landing/images/favicon.ico' } alt="..." height="25"/></span>
	                        </Link>
	                        <div className="dropdown-menu dropdown-menu-right w mt-3 animate fadeIn" style={{backgroundColor: '#FFFFFF'}}>
	                            <span className="dropdown-item">
	                                <span>{ this.props.user.username }</span>
	                            </span>
	                            <div className="dropdown-divider" style={{borderColor: '#f3f3f3'}}></div>
	                            <Link to="/settings" className="dropdown-item">
	                                <span>Account Settings</span>
	                            </Link>
	                            <Link to="#" className="dropdown-item d-flex text-danger" onClick={this.props.logout}>
	                                <span className="flex">Sign out</span>
	                            </Link>
	                            <div className="dropdown-divider" style={{borderColor: '#f3f3f3'}}>></div>
	                            <a className="dropdown-item" href="https://meplaylist.freshdesk.com/support/solutions" target="_blank" rel="noopener noreferrer">Help</a>
                                <Link className="dropdown-item" to="/plans">Plan</Link>
                                <a className="dropdown-item" href="https://www.meplaylist.com" target="_blank" rel="noopener noreferrer">About</a> 
                                <a className="dropdown-item" href="https://www.meplaylist.com/legal" target="_blank" rel="noopener noreferrer">Legal</a> 
	                        </div>
	                    </li> 
	                    <li className="nav-item d-lg-none">
	                        <a href="" className="nav-link px-2" data-toggle="collapse" data-toggle-_blaclass data-target="#navbarToggler">
	                            <i data-feather="search"></i>
	                        </a>
	                    </li>
	                    <li className="nav-item d-lg-none">
	                        <a className="nav-link px-1" data-toggle="modal" data-target="#aside">
	                            <i data-feather="menu"></i>
	                        </a>
	                    </li>
	                </ul>
	            </div>
	        </header>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user
	}
}

export default connect(mapStateToProps,{ logout })(WalletHeader);
