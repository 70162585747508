import React from 'react'
import { Link } from 'react-router-dom';

export default function Playlist(props) {
    return (
            <div className="col-md-2" data-id={ props.playlist.Playlist !== undefined? props.playlist.Playlist.generated_id :props.playlist.generated_id } data-category="Pop" data-tag={ props.playlist.Playlist !== undefined? props.playlist.Playlist.country :props.playlist.country }>
                        <div className="list-item slick-item r mb-3">
                            <div className="media">
                                <Link to={props.playlist.Playlist !== undefined?`/playlist/${(props.playlist.Playlist.generated_id)}`:`/playlist/${(props.playlist.generated_id)}`} className="ajax media-content " style={
                                    props.playlist.Playlist !== undefined && props.playlist.Playlist.artwork_path !== ""?
                                    { backgroundImage: "url(" + props.playlist.Playlist.artwork_path + ")" }
                                    :
                                    (props.playlist.Playlist !==undefined && props.playlist.Playlist.PlaylistSongs?
                                        {backgroundImage: "url(" + props.playlist.Playlist.PlaylistSongs[0].Album.artwork_file_path + ")"}:
                                        { backgroundImage: "url(" + props.playlist.artwork_path + ")" })
                                    }>
                                </Link>
                            </div>
                            <div className="list-content text-center">
                                <div className="list-body ">
                                    {/* <a href="music.detail.html#92570808" className="list-title title ajax h-1x"> */}
                                    <Link to={props.playlist.Playlist !== undefined?`/playlist/${(props.playlist.Playlist.generated_id)}`:`/playlist/${(props.playlist.generated_id)}`} className="list-title title h-1x">
                                       <b className="text-white"> { props.playlist.Playlist !== undefined?props.playlist.Playlist.title:props.playlist.title } </b>
                                    </Link>
                                    {/* </a> */}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
    )
}
