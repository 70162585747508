import { FETCH_SONGS, FETCH_PLAYLISTS, FETCH_PLAYLIST, UPDATE_QUEUE, FETCH_TOP_PLAYLISTS , FETCH_USER_PLAYLISTS, ADD_SONG_TO_PLAYLIST, CREATE_PLAYLIST, EDIT_PLAYLIST, DELETE_PLAYLIST, FETCHING_PLAYLIST, UPDATE_SHARE_PROPERTIES } from './types';
import * as actions from './types';
import { tracks } from '../db';
import axios from 'axios';
import { toast } from 'react-toastify';
import store from '../store';
import $ from 'jquery';

const user = store.getState().auth.user;
// const config = {
//   headers: {
//     'Access-Control-Allow-Origin': 'http://localhost:8000',
//   }
// };

export const fetchSongs = () => dispatch =>{
    // make axios call here -- use db instead
    // dispatch action
    dispatch({
      type: FETCH_SONGS,
      payload: tracks
    });
}

export const fetchPlaylists = () => dispatch => {
  axios.get('/api/playlists')
  .then(response => {
    dispatch({
      type: FETCH_PLAYLISTS,
      payload: response.data.data
    });
    //dispatch songs to queue
  })
  .catch(error => {
    toast.error(`An Error Occurred ${error}`);
  });
}

export const fetchLayerPlaylists = () => dispatch => {
  axios.post('/api/playlists',{
    location: user.Country.alpha_2
  })
  .then(response => {
    dispatch({
      type: FETCH_PLAYLISTS,
      payload: response.data.data
    });
    //dispatch songs to queue
  })
  .catch(error => {
    toast.error(`An Error Occurred ${error}`);
  });
}

export const fetchPlaylist = (id) => dispatch => {
  dispatch({
    type: FETCHING_PLAYLIST
  })
  axios.get(`/api/playlist/${id}`)
  .then(response => {
    dispatch({
      type: FETCH_PLAYLIST,
      payload: response.data.data
    });
    // dispatch songs to queue
    // let songs = response.data.data.Playlist.PlaylistSongs.map(song  => {
    //   return song.Album;
    // });
    // dispatch({
    //   type: UPDATE_QUEUE,
    //   payload: songs
    // });
  })
  .catch(error => {
    toast.error(`We could not fetch your playlist songs`);
  })
}

/**
* @params Integer $id song id
*/
export const addSongToPlaylist = (songID,playlistID) => dispatch => {
  toast('Adding song to playlist...');
  //make axios call to endpoint for creating DB
  axios
  .post('/api/playlists/addsongtoplaylist',{
    songID,
    playlistID
  })
  .then((response) => {
    //close modal
    dispatch({
      type: ADD_SONG_TO_PLAYLIST
    });
    $('#addToPlaylist').modal('toggle');
    toast.success('Song added to playlist successfully');
  })
  .catch(error =>  toast.error('song could not be added, try again!'));
}

export const fetchTopPlaylists = () => dispatch => {
  axios.get('/api/top_playlists')
  .then(response => {
    dispatch({
      type: FETCH_TOP_PLAYLISTS,
      payload: response.data.data.playlists
    });
  })
  .catch(error => {
    toast.error(`An Error Occurred ${error}`);
  })
}

export const fetchUserPlaylists = () => dispatch => {
  axios.post('/api/user/playlists',{
    userID: user.id
  })
  .then(response => {
    dispatch({
      type: FETCH_USER_PLAYLISTS,
      payload: response.data.data
    })
  })
  .catch(error => {
    toast.error(`An Error Occurred ${error}`);
  })
}

/**
* @params Integer $id song id
*/
export const createPlaylist = (title,mood) => dispatch => {
  //make axios call to endpoint for creating DB
  axios
  .post('/api/playlist',{
    title: title,
    // description: description,
    moodID: mood,
    userID: user.id
  })
  .then((response) => {
    //close modal
    dispatch({
      type: CREATE_PLAYLIST,
      payload: response.data.data
    });
    $('#createPlaylist').modal('toggle');
    toast.success('Playlist created!');
  })
  .catch(error =>  toast.error('Playlist could not be created, try again!'));

}

/**
* @params Object
*/
export const editPlaylist = ({title,mood,id}) => dispatch => {
  // alert(id);
  //make axios call to endpoint for creating DB
  axios
  .put('/api/playlist/',{
    title: title,
    moodID: mood,
    playlistID: id
  })
  .then((response) => {
    //close modal
    dispatch({
      type: EDIT_PLAYLIST,
      // payload: Object.assign(playlist,{title: response.data.data.title}),
    });
    $('#editPlaylist').modal('toggle');
    toast.success('Changes Saved!');
  })
  .catch(error => {
    toast.error('Playlist Could not be updated')
  });
}

/**
* 
*/
export const deletePlaylist = (id) => dispatch => {
  // alert(id);
  //make axios call to endpoint for creating DB
  axios
  .delete('/api/playlist',{
    data:{
      playlistID: id,
      userID: user.generated_id
    }    
  })
  .then((response) => {
    //close modal
    dispatch({
      type: DELETE_PLAYLIST
    });
    $('#editPlaylist').modal('toggle');
    toast.success('Playlist Deleted!');
    // pause for 5 seconds then
    window.location.replace('/playlists');
  })
  .catch(error => {
    toast.error('Playlist Could not be updated')
  });
}

/**
* 
*/
export const deleteSongFromPlaylist = (id) => dispatch => {
  // alert(id);
  //make axios call to endpoint for creating DB
  
}

export const fetchMoods = () => dispatch =>{
    // make axios call here -- use db instead
    axios
    .get('/api/moods')
    .then(response => {
      // dispatch action
      dispatch({
        type: actions.FETCH_MOODS,
        payload: response.data.data
      });
    })
    .catch(error => {
      console.log('An Error Occurred: '+error)
    })  
}

export const editorPlaylists = () => dispatch => {
  axios
  .post('/api/playlists/editor',{
    location: user.Country.alpha_2
  })
  .then(response => {
    dispatch({
      type: actions.FETCH_EDITOR_PLAYLISTS,
      payload: response.data.data
    });
  })
  .catch(() => {
    toast.error('Network error, try again!');
  })
}

export const moodsPlaylists = () => dispatch => {
  axios
  .post('/api/moods/playlists',{
    location: user.Country.alpha_2
  })
  .then(response => {
    dispatch({
      type: actions.FETCH_MOODS_PLAYLISTS,
      payload: response.data.data
    });
  })
  .catch(() => {
    toast.error('Network error, try again!');
  })
}

export const setQueue = (data) => dispatch =>{
  // make axios call here -- use db instead
  dispatch({
    type: UPDATE_QUEUE,
    payload: data
  });
}

export const sharePlaylist = (data) => dispatch => {
  dispatch({
    type: UPDATE_SHARE_PROPERTIES,
    payload: data
  });
}
